import {CartRequest} from '../transport/models/order/cart.request';
import {OrderLineType, ProductOrderLine} from '../domain/models/order/order-line';
import {TOrderLineRequest} from '../transport/models/order/t-order-line.request';
import {Cart} from '../domain/models/order/cart';
import {Store} from '../domain/models/store/store';
import {FulfillmentOptionType} from '../domain/models/store/fulfillmentOption';

export class CartUtils {

  static toRequest(cart: Cart): CartRequest {
    const productLines = cart.orderLines
      .filter(line => line.type === OrderLineType.Product)
      .map(line => line as ProductOrderLine)
      .map(line => this.toOrderLineRequest(line));
    return new CartRequest(
      cart.orderId,
      cart.storeId,
      cart.memberId,
      cart.externalId ?? undefined,
      cart.orderReference ?? undefined,
      cart.adjustmentTypeId ?? undefined,
      productLines,
      cart.couponCodes,
      cart.discountCodes,
      cart.shippingAddress,
    );
  }

  static toOrderLineRequest(line: ProductOrderLine): TOrderLineRequest {
    const id = !line.id.startsWith('*') ? line.id : null;
    return new TOrderLineRequest(
      id,
      line.product.id,
      line.product.dimensionId,
      line.quantity,
      line.itemIds,
      line.fulfillmentOptionId,
      line.priceOverride,
    );
  }

  static requiresShipmentAddress(store: Store, cart: Cart): boolean {
    if (!store) {
      return false;
    }
    return cart.orderLines
        .filter(orderLine => orderLine.type === OrderLineType.Product)
        .map(orderLine => orderLine as ProductOrderLine)
        .find(orderLine => (store.fulfillmentOptions ?? [])
          .find(x => x.id === orderLine.fulfillmentOptionId)?.type === FulfillmentOptionType.Shipment)
      != null;
  }
}
