import {CampaignOrderLine, ProductOrderLine} from './order-line';
import {CouponCode} from './coupon-code';
import {ShippingAddress} from './shipping-address';
import {OrderLineRelation} from './order-line-relation';

export class Cart {
  memberId?: string;
  shippingAddress?: ShippingAddress;
  orderLines: Array<OrderLine> = [];
  sum = 0;
  updatedAt?: number;
  adjustmentTypeId?: string | null;
  externalId?: string | null;
  orderReference?: string | null;
  couponCodes: CouponCode[] = [];
  discountCodes: Array<string>=[];
  orderLineRelations: Array<OrderLineRelation>=[];
  orderId?: string;

  constructor(
    readonly storeId: string
  ) {
    this.updatedAt = Date.now();
  }
}

export type OrderLine = ProductOrderLine | CampaignOrderLine;
