<div *ngIf="orderLine!.type == 'product' && product">
  <app-image-fading *ngIf="product?.images && product.images.length >= 1"
                    [source]="getProductImage(product.images, 100)"
                    class="product-image">
  </app-image-fading>
  <app-image-fading *ngIf="!product.images || product.images.length == 0"
                    [source]="getFallBackImage()"
                    class="product-image">
  </app-image-fading>
</div>

<div *ngIf="orderLine!.type == 'campaign' && campaign">
  <app-image-fading *ngIf="campaign?.image else saleIcon"
                    [source]="getCampaignImage(campaign?.image, 100)"
                    [isEager]="true"
                    class="order-line_image">
  </app-image-fading>
</div>

<ng-template #saleIcon>
  <mat-icon class="order-line-sale">
    money_off
  </mat-icon>
</ng-template>
