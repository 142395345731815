import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-door-closed',
  templateUrl: './door-closed.dialog.html',
  styleUrls: ['./door-closed.dialog.sass'],
})
export class DoorClosedDialog {
  constructor(public ref: MatDialogRef<DoorClosedDialog>,
              @Inject(MAT_DIALOG_DATA) public data: { openingHours?: () => void },
  ) {
  }

  openOpeningHours() {
    this.close();
    this.data.openingHours?.();
  }

  close() {
    this.ref.close();
  }
}
