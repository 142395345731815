<ng-container *ngIf="store && order else loading">
  <ng-container *ngIf="showOuterLines">
    <ng-container *ngTemplateOutlet="spacer"></ng-container>
  </ng-container>
  <ng-container *ngTemplateOutlet="orderLinesTemplate"></ng-container>
  <ng-container *ngIf="showOuterLines">
    <ng-container *ngTemplateOutlet="spacer"></ng-container>
  </ng-container>
</ng-container>

<ng-template #orderLinesTemplate>
  <div class="receipt_product-container" *ngFor="let orderLine of order!.orderLines">
    <div class="receipt_product-count">
      <ng-container
        *ngIf="orderLine.type === OrderLineType.Product &&
        orderLine.product.isWeight && orderLine.priceOverride ==undefined, else normalQuantity">
        {{orderLine.quantity / 1000}}kg
      </ng-container>
      <ng-template #normalQuantity>
        {{orderLine.quantity}} x
      </ng-template>
    </div>
    <div class="receipt_product-name">
      {{orderLine.name}}
      <br>
    </div>
    <div class="receipt_product-price">
      {{ toPrice(orderLine.totalPrice) | currency:currencyCode:'symbol-narrow': '':cultureName}}
    </div>
  </div>

  <ng-container *ngTemplateOutlet="spacer"></ng-container>

  <div class="receipt_product_total-container">
    <div class="receipt_product_total-title">
      {{ 'CART.sum' | translate }}
    </div>
    <div class="receipt_product_total-price">
      {{ toPrice(order!.sum) | currency:currencyCode:'symbol-narrow': '':cultureName}}
    </div>
  </div>
  <div *ngIf="hasTax" class="receipt_tax_total-container">
    <div class="receipt_product_total-tax"
    >
      {{ 'CART.tax' | translate }}
    </div>
    <div class="receipt_product_total-tax-price"
    >
      {{ toPrice(order!.taxSum) | currency:currencyCode:'symbol-narrow': '':cultureName}}
    </div>
  </div>

  <ng-container *ngTemplateOutlet="spacer"></ng-container>

  <div *ngIf="isPaid" class="receipt_payed-container">
    <div class="receipt-status">
      <div class="receipt_paid-title">{{ 'RECEIPT.paid' | translate }}</div>
      <div *ngIf="hasPendingDelivery" class="receipt_delivery-pending-title">{{ 'RECEIPT.delivery' | translate }}</div>
    </div>
    <div class="receipt_payed-price">
      {{ toPrice(order!.sum) | currency:currencyCode:'symbol-narrow': '':cultureName }}
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <app-gradient-loader></app-gradient-loader>
  <app-gradient-loader></app-gradient-loader>
</ng-template>

<ng-template #spacer>
  <div class="receipt_spacer">
    <svg width="100%" height="1">
      <line x1="0" y1="0.5" x2="100%" y2="0.499972" stroke="#DFE7EB" stroke-dasharray="6 6"></line>
    </svg>
  </div>
</ng-template>
