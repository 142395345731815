<div>
  <div class="age-check-page" *ngIf="!minimized else minifiedTemplate">
    <img class="age-check-avatar-image" src="/assets/images/thumbsup.svg" alt="avatar" width="100" height="100">
    <div class="age-check-avatar-title">
      {{ 'AGELIMIT.ageLimitMessage' | translate }}
    </div>
    <div>
      <button class="age-check-button"
              [ngStyle]="{backgroundColor: profile?.primaryColor, color: profile?.primaryTextColor}"
              (click)="scanqr()">
        {{ 'AGELIMIT.scan' | translate }}
      </button>
    </div>
  </div>

  <ng-template #minifiedTemplate>
    <div class="age-check-minimized-title" (click)="stopScanForStore()">
      {{ 'AGELIMIT.ageLimitCheck' | translate }}
    </div>
  </ng-template>
</div>
