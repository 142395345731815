<div class="vipps-auth">
  <div class="vipps-auth__waiting-box">
    <div class="vipps-auth__waiting-animation">
      <ng-lottie
        (animationCreated)="$event.setSpeed(3)"
        [options]="{
          path: './assets/animations/fygi_logo_animation.json',
          renderer: 'svg',
          autoplay: true,
          loop: true
        }">
      </ng-lottie>
    </div>
    <h4 class="vipps-auth__waiting-text">{{ 'STORE.themeWaiting' | translate }}</h4>
    <button class="cancel-button" (click)="resumeUri()">
      {{ 'DIALOG.cancel' | translate }}
    </button>
  </div>
</div>
