<div class="root">
  <div class="quantity-selector">
    <button class="quantity-selector__button negative"
            [ngClass]="{'isBusy' : isBusy}"
            [disabled]="isBusy"
            [ngStyle]="{backgroundColor: profile?.primaryColor, color: profile?.primaryTextColor}"
            (click)="decrease.emit()">
      -
    </button>
    <input class="quantity-selector__button center"
           #input
           inputmode="numeric"
           [ngModel]="quantity"
           (blur)="onSubmit(input.value)"
           (keyup.enter)="onSubmit(input.value)">
    <button class="quantity-selector__button positive"
            [ngClass]="{'isBusy' : isBusy}"
            [ngStyle]="{backgroundColor: profile?.primaryColor, color: profile?.primaryTextColor}"
            [disabled]="isBusy"
            (click)="increase.emit()">
      +
    </button>
  </div>
</div>
