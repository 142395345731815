import {Gs1DataMatrix} from './gs1DataMatrix';

export class Gs1Utils {

  static gtinRegexp = new RegExp('^01(\\d{14})');
  static serialNumberRegexp = new RegExp('\\u001d21([\x21-\x22\x25-\x2F\x30-\x39\x3A-\x3F\x41-\x5A\x5F\x61-\x7A]{0,20})')

  static parse(barcodeData: string): Gs1DataMatrix{
    let gtinMatches = barcodeData.match(this.gtinRegexp);
    let serialNumberMatches = barcodeData.match(this.serialNumberRegexp);

    // Why index 1? pattern contains pattern and sub-pattern, in both cases we need the matched sub-pattern
    return new Gs1DataMatrix(gtinMatches!![1],serialNumberMatches!![1]);
  }
}
