import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StoreService} from '../../domain/store.service';
import {Store} from '../../domain/models/store/store';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-invalid-microshop',
  templateUrl: './invalid-microshop.dialog.html',
  styleUrls: ['./invalid-microshop.dialog.sass'],
})
export class InvalidMicroshopDialog {
  store?: Store;

  constructor(
    private dialogRef: MatDialogRef<InvalidMicroshopDialog>,
    private route: ActivatedRoute,
    private router: Router,
    private storeService: StoreService,
  ) {
  }

  async ngOnInit() {
    const storeHandle = this.route.firstChild?.snapshot.paramMap.get('id');
    if (storeHandle) {
      this.store = await this.storeService.getStore(storeHandle);
    }
  }

  async goBack() {
    this.dialogRef.close();
    if (this.store) {
      await this.router.navigate([`store/${this.store.handle}`], {replaceUrl: true});
    }
  }
}
