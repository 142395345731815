import {Component} from '@angular/core';

@Component({
  selector: 'app-invalid-store',
  templateUrl: './invalid-store.dialog.html',
  styleUrls: ['./invalid-store.dialog.sass'],
})

export class InvalidStoreDialog {
}
