<div class="root" [ngStyle]="isChrome ? {'height': '100vh'} : {}">
  <div class="receipt-loader" *ngIf="!isPaid && !failed">
    <ng-lottie
      height="75px"
      width="75px"
      [options]="{
         path: './assets/animations/fygiloader3-blue.json',
         renderer: 'svg',
         autoplay: true,
         loop: true
       }">
    </ng-lottie>
    <h4 class="payment-cancel-button" (click)="cancelPayment()" [style]="getOrderPaymentAttempts < 3 ? 'visibility: hidden' : null">
      {{ 'DIALOG.cancel' | translate }}
    </h4>
  </div>

  <div class="receipt-browser-error" *ngIf="redirectBrowser">
    <div class="receipt-browser-text">
      {{ 'RECEIPT.browserErrorText' | translate }}
    </div>
    <div class="receipt-browser-instruction">
      {{ 'RECEIPT.browserErrorInstruction' | translate }}
    </div>
  </div>

  <div class="receipt_header_error_container" *ngIf="failed && !redirectBrowser">
    <div class="receipt_header_title">
      {{ 'ERROR.GENERIC.title' | translate }}
      <br>
      {{ 'ERROR.GENERIC.description' | translate }}
    </div>
    <p class="receipt_header-description">
      {{ 'ERROR.GENERIC.requestRetry' | translate }}
    </p>
    <button class="payment-retry-button"
            [ngStyle]="{backgroundColor: profile?.primaryColor, color: profile?.primaryTextColor}"
            (click)="retry()">
      {{ 'ERROR.GENERIC.retry' | translate }}
    </button>
    <h4 class="payment-cancel-button" (click)="cancelPayment()">
      {{ 'DIALOG.cancel' | translate }}
    </h4>
  </div>

  <div *ngIf="fadeout" class="receipt-page-tick-animation-page">
    <div class="receipt-container">

      <img class="gift-image" src="assets/images/gift.svg" alt="avatar image" height="172" width="273">
      <div class="receipt-header">
        <div class="receipt-anim-container"></div>
        <div class="receipt-header-title">

          <ng-lottie
            class="checkout-receipt-animation"
            [options]="{
                  path: './assets/animations/whitecheck.json',
                  renderer: 'svg',
                  rendererSettings: {
                    viewBoxSize: '75 55 375 375'
                  },
                  autoplay: true,
                  loop: false
              }">
          </ng-lottie>

          <div class="receipt-auto-complete-title">
            {{ 'RECEIPT.thanks' | translate }}
          </div>
          <div class="receipt-payment-complete">
            {{ 'RECEIPT.paymentDone' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="receipt-page">
    <div *ngIf="!fadeout && isPaid" [@tabState]="state" class="receipt-page-background-before-animation">
      <div class="receipt-page-close">
        <div>
          <mat-icon (click)="closeForeground()">
            keyboard_backspace
          </mat-icon>
        </div>
      </div>
      <div class="receipt-page-after-slide">

        <div class="receipt-page-bubbles-animation">
          <ng-lottie
            [options]="{
                  path: './assets/animations/background-sparkles.json',
                  autoplay: true,
                  loop: true
              }">
          </ng-lottie>
        </div>

        <div *ngIf="!isDelivered" class="order-card">
          <div class="receipt-top">
            <div class="receipt-page-timer">{{ countdownString }}</div>

            <ng-container *ngIf="isPendingDelivery || !order?.vendingCode && !enableGateSettings">
              <div class="order-number">
                #{{ order?.number }}
              </div>
              <div class="order-number-title">
                {{ 'RECEIPT.orderTitle' | translate }}
              </div>
            </ng-container>

            <ng-container *ngIf="!isPendingDelivery && enableGateSettings">
              <ng-container *ngTemplateOutlet="gateBarcode"></ng-container>
            </ng-container>

            <div class="receipt-page-scan-vending" *ngIf="order?.vendingCode">
              <div class="receipt-page-scan-title">{{ 'RECEIPT.thanksVending' | translate }}</div>
              <ngx-barcode6
                [bc-format]="'CODE128'"
                [bc-value]="order!.vendingCode!"
                [bc-width]="order!.vendingCode!.length > 10? 1.6 : 2"
                [bc-height]="60"
                [bc-margin-left]="1"
                [bc-margin-right]="1"
                [bc-margin-bottom]="15"
                [bc-display-value]="false">
              </ngx-barcode6>
            </div>
          </div>

          <div class="scroll-container" [ngClass]="{'scroll-container-disabled': productOrderLines!.length == 4}" scrollState>
            <div *ngFor="let orderLine of productOrderLines; let i = index">
              <div class="receipt">
                <div class="receipt-product-count">
                  {{ orderLine.quantity }} x
                </div>
                <div class="receipt-product-image">
                  <app-receipt-product-image
                    [orderLine]="orderLine">
                  </app-receipt-product-image>
                </div>
                <div class="receipt-product-name">
                  {{ orderLine.name }}
                  <br>
                </div>
              </div>
              <div *ngIf="i != order!.orderLines.length -1 || i==0" class="receipt-spacer-line"></div>
            </div>
          </div>

          <ng-container *ngTemplateOutlet="viewReceiptButton"></ng-container>

          <div *ngIf="isPaid && isPendingDelivery" class="receipt-delivery-pending">
            <app-slide-to-confirm text="{{ 'RECEIPT.markAsDelivered' | translate }}"
                                  [profile]="profile"
                                  [confirmCallback]="confirmDelivered.bind(this)">
            </app-slide-to-confirm>
            <p>{{ 'RECEIPT.markAsDeliveredInfo' | translate }}</p>
          </div>

          <ng-container *ngIf="isPaid && !isPendingDelivery">
            <ng-container *ngTemplateOutlet="registerButton"></ng-container>
          </ng-container>
        </div>

        <div *ngIf="isDelivered" class="order-card">
          <h2 class="order-delivered-title">
            {{ 'RECEIPT.deliveredTitle' | translate }}
          </h2>
          <p class="order-delivered-info">
            {{ 'RECEIPT.deliveredAt' | translate }} <span [innerHtml]="deliveredAtString"></span>
          </p>

          <ng-container *ngIf="!enableGateSettings; else gateBarcode">
            <div class="order-delivered-illustration">
              <img src="/assets/images/happy-shopping.svg" width="180" height="180" alt="Happy shopping">
            </div>
          </ng-container>

          <ng-container *ngTemplateOutlet="registerButton"></ng-container>

          <ng-container *ngTemplateOutlet="viewReceiptButton"></ng-container>
        </div>

      </div>
    </div>
  </div>
</div>

<ng-template #registerButton>
  <div *ngIf="!(customerService.isSignedIn$ | async)" class="receipt-registration">
    <button class="receipt-register-button"
            [ngClass]="{'receipt-register-button-with-overflow': order!.orderLines.length >= 4}"
            [ngStyle]="{backgroundColor: profile?.primaryColor, color: profile?.primaryTextColor}"
            (click)="openSignIn()">
      {{ 'RECEIPT.registerAccount' | translate }}
    </button>
    <p *ngIf="store?.exitMessage?.active" class="receipt-register-message">
      {{ store?.exitMessage?.message }}
    </p>
    <p *ngIf="!store?.exitMessage?.active" class="receipt-register-message">
      {{ 'RECEIPT.registerAccountMessage' | translate }}
    </p>
  </div>
</ng-template>

<ng-template #viewReceiptButton>
  <div class="receipt-view-button" (click)="showReceipt()">
    <svg width="18" height="18" fill="none">
      <path d="M.6 3A2.4 2.4 0 0 1 3 .6h8.4A2.4 2.4 0 0 1 13.8 3v8.4h3.6v2.4a3.6 3.6 0 0 1-3.6 3.6H4.2a3.6 3.6 0 0 1-3.6-3.6V3zm13.2
        9.6v3.6a2.4 2.4 0 0 0 2.4-2.4v-1.2h-2.4zm-1.2 3.6V3a1.2 1.2 0 0 0-1.2-1.2H3A1.2 1.2 0 0 0 1.8 3v10.8a2.4 2.4 0 0 0 2.4
        2.4h8.4zM4.2 4.8a.6.6 0 0 1 .6-.6h4.8a.6.6 0 1 1 0 1.2H4.8a.6.6 0 0 1-.6-.6zm0 3.6a.6.6 0 0 1 .6-.6h4.8a.6.6 0 1 1 0
        1.2H4.8a.6.6 0 0 1-.6-.6zm0 3.6a.6.6 0 0 1 .6-.6h2.4a.6.6 0 1 1 0 1.2H4.8a.6.6 0 0 1-.6-.6z" fill="#0A1237"/>
    </svg>
    <h3 class="receipt-view-text">
      {{ 'RECEIPT.showReceipt' | translate }}
    </h3>
  </div>
</ng-template>

<ng-template #gateBarcode>
  <div *ngIf="storeGate" class="receipt-page-scan-barcode">
    <div class="receipt-page-scan-title">{{ 'RECEIPT.scan' | translate }}</div>
    <ngx-barcode6
      [bc-format]="'CODE128'"
      [bc-value]="storeGate.gateCode"
      [bc-height]="60"
      [bc-width]="1.5"
      [bc-margin-bottom]="20"
      [bc-display-value]="false">
    </ngx-barcode6>
  </div>
</ng-template>
