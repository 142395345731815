import {Campaign} from '../domain/models/campaign/campaign';
import {CampaignRuleScope, RuleType} from '../domain/models/campaign/rule';
import {Store} from '../domain/models/store/store';
import {ProductType} from '../domain/models/product/product-microshop';

export class CampaignUtils {
  static isValidCampaign(campaign: Campaign): boolean {
    const isAfterStart = campaign.startDate == null || new Date(campaign.startDate).getTime() < Date.now();
    const isBeforeEnd = campaign.endDate == null || new Date(campaign.endDate).getTime() > Date.now();

    return isAfterStart && isBeforeEnd;
  }

  static getCheapestPrice(product: ProductType, campaigns: Campaign[]): number {
    const campaignPrices = campaigns
      .filter(c => c.products.find(cp => cp.id === product.id))
      .map(c => c.rule)
      .filter(rule => rule.scope === CampaignRuleScope.Product)
      .map(rule => {
        switch (rule.type) {
          case RuleType.PercentDiscount:
            return product.dimensions[0].prices[0].amount - product.dimensions[0].prices[0].amount * (rule.discountPercent / 100);
          case RuleType.CashDiscount:
            return product.dimensions[0].prices[0].amount - rule.discountCash;
          case RuleType.NewPrice:
            return rule.discountPrice;
        }
        // todo check adjustmentType
        return product.dimensions[0].prices[0].amount;
      }).filter(sum => sum != null && !isNaN(sum));
    return Math.min(product.dimensions[0].prices[0].amount, ...campaignPrices);
  }

  static campaignsForProduct(store: Store, productId: string): Campaign[] {
    return store.campaigns
      .filter(c => c.products.find(p => p.id === productId));
  }
}
