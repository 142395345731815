<div class="door" [ngStyle]="{backgroundColor: door?.storeProfile?.backgroundColor}">
  <div class="door__header">
    <app-image-fading class="door__logo" [source]="storeLogo" [animateLoading]="false"></app-image-fading>
    <div class="door__help" *ngIf="door && door.supportMessage">
      <button class="door__help-btn" type="button" (click)="openHelp()">
        <img src="/assets/images/help.svg" alt="Help" height="24" width="24">
      </button>
    </div>
  </div>
  <ng-container *ngIf="!notFound, else notFoundTpl">
    <div class="door__body">
      <img class="door__illustration" src="/assets/images/digitalshopping.png" alt="Access store" width="609" height="435">
      <h1 class="door__title" [ngStyle]="{color: door?.storeProfile?.primaryColor}">
        <ng-template [ngIf]="door">
          {{ door?.welcomeTitle ?? 'DOOR.defaultTitle' | translate }}
        </ng-template>
        <app-gradient-loader *ngIf="!door"></app-gradient-loader>
      </h1>
      <p class="door__text">
        <ng-template [ngIf]="door">
          {{ door?.welcomeMessage ?? 'DOOR.defaultText' | translate }}
        </ng-template>
        <ng-template [ngIf]="!door">
          <app-gradient-loader></app-gradient-loader>
          <app-gradient-loader></app-gradient-loader>
        </ng-template>
      </p>
      <ng-template [ngIf]="door">
        <div class="door__spacer"></div>
        <ng-container *ngIf="!hasValidAuth, else openDoorBtn">
          <button class="door__login-btn"
                  type="button"
                  [ngStyle]="{background: door.storeProfile?.primaryColor, color: door.storeProfile?.primaryTextColor}"
                  (click)="openLoginDialog()">
            {{ 'DOOR.signIn' | translate }}
          </button>
        </ng-container>
      </ng-template>
      <ng-template [ngIf]="door?.openingHours?.length">
        <div class="spacer"></div>
        <button class="door__opening-hours-btn" (click)="openOpeningHours()">
          <svg width="22px" height="22px" viewBox="0 -960 960 960" fill="currentColor">
            <path
              d="m612-292 56-56-148-148v-184h-80v216l172 172ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z"/>
          </svg>
          <span>{{ 'DOOR.openingHours' | translate }}</span>
        </button>
      </ng-template>
    </div>
  </ng-container>
</div>

<ng-template #openDoorBtn>
  <button class="door__open-btn"
          type="button"
          [ngClass]="{'door__open-btn--loading': isOpeningDoor, 'door__open-btn--disabled': doorDisabled && !isOpeningDoor}"
          [ngStyle]="{background: door?.storeProfile?.primaryColor, color: door?.storeProfile?.primaryTextColor}"
          (click)="openDoor()">
    <ng-template [ngIf]="!isOpeningDoor">
      {{ 'DOOR.openDoor' | translate }}
    </ng-template>
    <ng-template [ngIf]="isOpeningDoor">
      <mat-spinner diameter="40">{{ 'LOGIN.loading' | translate }}</mat-spinner>
    </ng-template>
  </button>
</ng-template>

<ng-template #notFoundTpl>
  <div class="door__body" style="justify-content: center">
    <img class="door__illustration" src="/assets/images/store-invalid.svg" alt="Not found" width="250" height="180">
    <h1 class="door__title">{{ 'DOOR.ERROR.notFoundTitle' | translate }}</h1>
    <p class="door__text">{{ 'DOOR.ERROR.notFoundText' | translate }}</p>
  </div>
</ng-template>
