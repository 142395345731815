<div class="product-item_root">
  <div class="product-item"
       [ngClass]="{
     'drop-shadow':dropShadow}"
       [ngStyle]="{'background':backgroundColor}">
    <ng-container *ngIf="product">
      <app-image-fading *ngIf="product.images && product.images.length >= 1" [source]="getProductImage(product?.images, 100)"
                        class="product-item_image">
      </app-image-fading>
    </ng-container>

    <ng-container *ngIf="product">
      <app-image-fading *ngIf="!product.images || product.images.length == 0" [source]="getFallBackImage()"
                        class="product-item_image">
      </app-image-fading>
    </ng-container>

    <div class="product-item_details"
         (click)="openProductDetails()">
    <span *ngIf="product, else loading">
      {{product?.name}}
    </span>
      <span class="ageLimit" *ngIf="product?.ageGroup">
      {{product!.ageGroup.name}}
    </span>
      <span *ngIf="(product?.dimensions?.length ?? 0) > 1" class="subtitle">
      {{dimension?.name}}
    </span>
    </div>
  </div>
  <ng-template #loading>
    <app-gradient-loader style="width: 100%"></app-gradient-loader>
  </ng-template>
</div>
