<div class="checkout-receipt_container">
  <div *ngIf="!showProgress">
  </div>
  <div style="padding: 20px;" *ngIf="showProgress">
    <mat-spinner style="margin: 0 auto" diameter="40"></mat-spinner>
    <h1 class="checkout-receipt_progress_title">
      {{ 'RECEIPT.verify' | translate }}
    </h1>
  </div>
</div>
