<div class="vr-pay">
  <div [ngStyle]="isPaymentBusy ? {opacity: 0.2} : null">
    <form [action]="formActionRoute" class="paymentWidgets" [attr.data-brands]="formDataBrands"></form>
  </div>
  <div *ngIf="isPaymentBusy" class="vr-payment-loading" (click)="$event.preventDefault(); $event.stopPropagation()">
    <ng-lottie
      height="50px"
      width="50px"
      [options]="{
        path: './assets/animations/fygiloader3-blue.json',
        renderer: 'svg',
        autoplay: true,
        loop: true
    }">
    </ng-lottie>
  </div>
</div>
