export class Cache<T> {
  private readonly cacheAgeInSeconds: number;

  private cache = new Map<string, DateStamped<T>>();

  constructor(cacheAgeInSeconds = 3600) {
    this.cacheAgeInSeconds = cacheAgeInSeconds;
  }

  set(key: string, value: T) {
    this.cache.set(key, new DateStamped<T>(this.cacheAgeInSeconds, value));
  }

  get(key: string): T | undefined {
    if (!this.cache.has(key)) {
      return undefined;
    }
    const cached = this.cache.get(key);
    if (cached == null) {
      return undefined;
    }
    if (cached.isExpired()) {
      this.cache.delete(key);
      return undefined;
    }
    return cached.data;
  }

  remove(key: string): boolean {
    return this.cache.delete(key);
  }

  has(key: string): boolean {
    return this.cache.has(key);
  }
}

class DateStamped<T> {
  readonly expiresAt;

  constructor(expiresAfterSeconds: number, readonly data: T) {
    this.expiresAt = Date.now() + (expiresAfterSeconds * 1000);
  }

  isExpired(): boolean {
    return Date.now() > this.expiresAt;
  }
}
