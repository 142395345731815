import {Injectable} from '@angular/core';
import {StoreProvider} from '../transport/store.provider';
import {Store, StoreShallow} from './models/store/store';
import {Page, Paginated} from '../transport/models/paginated';
import {map} from 'rxjs/operators';
import {sortByIndexAscending} from '../utils/sorting.utils';
import {CampaignUtils} from '../utils/campaign.utils';
import {Cache} from '../utils/cache.utils';
import {LocalStorageService} from 'ngx-webstorage';
import {Router} from '@angular/router';
import {StoreSettingsProvider} from '../transport/store-settings.provider';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  private storeCache = new Cache<Store>();
  contextHistoryPath?: string;
  storeId?: string | null;

  constructor(private storeProvider: StoreProvider,
              private storeSettingsProvider: StoreSettingsProvider,
              private storageService: LocalStorageService,
              private router: Router,
  ) {
  }

  getStores(page?: Page): Promise<Paginated<StoreShallow>> {
    return this.storeProvider.getStores(page).toPromise();
  }

  async getStore(storeHandle: string): Promise<Store> {
    const cachedStore = this.storeCache.get(storeHandle);
    if (cachedStore) {
      return cachedStore;
    }
    const remoteStore = await this.storeProvider.getStore(storeHandle)
      .pipe(map(source => {
        source.categories = sortByIndexAscending(source.categories);
        source.campaigns = source.campaigns.filter(CampaignUtils.isValidCampaign);
        return source;
      }))
      .toPromise();

    this.storeCache.set(storeHandle, remoteStore);

    return remoteStore;
  }

  async getStoreByNumber(shortNumber: string): Promise<Store> {
    return this.storeProvider.getStoreByNumber(shortNumber).toPromise();
  }

  async getChainStores(chainHandle: string) {
    return this.storeProvider.getChainStores(chainHandle).toPromise();
  }

  async isGiftReceiptEnabled(storeHandle: string): Promise<boolean> {
    const setting = await this.storeSettingsProvider
      .getSetting(storeHandle, StoreSettingsProvider.GiftReceiptGroup, 'enabled');
    if (!setting) {
      return false;
    }
    return setting.value == 'true' || setting.value == '1';
  }

  async isContinuousScanningEnabled(storeHandle: string): Promise<boolean> {
    const setting = await this.storeSettingsProvider
      .getSetting(storeHandle, StoreSettingsProvider.ScanningGroup, 'continuous-scanning');
    if (!setting) {
      return false;
    }
    return setting.value == 'true';
  }

  async isGateEnabled(storeHandle: string): Promise<boolean> {
    const setting = await this.storeSettingsProvider
      .getSetting(storeHandle, StoreSettingsProvider.OrderGroup, 'gate-code-enabled');
    if (!setting) {
      return false;
    }
    return setting.value == 'true';
  }

  async isProductBrowseOnly(storeHandle: string): Promise<boolean> {
    const setting = await this.storeSettingsProvider
      .getSetting(storeHandle, StoreSettingsProvider.ProductGroup, 'browse-only-enabled');
    if (!setting) {
      return false;
    }
    return setting.value == 'true';
  }

  async isProductSearchEnabled(storeHandle: string): Promise<boolean> {
    const setting = await this.storeSettingsProvider
      .getSetting(storeHandle, StoreSettingsProvider.ProductGroup, 'search-enabled');
    if (!setting) {
      return false;
    }
    return setting.value == 'true';
  }

  async isDiscountCodeEnabled(storeHandle: string): Promise<boolean> {
    const setting = await this.storeSettingsProvider
      .getSetting(storeHandle, StoreSettingsProvider.CartGroup, 'discount-code');
    if (!setting) {
      return false;
    }
    return setting.value == 'true';
  }

  async isProductBundleModeEnabled(storeHandle: string): Promise<boolean> {
    const setting = await this.storeSettingsProvider
      .getSetting(storeHandle, StoreSettingsProvider.ProductGroup, 'bundle-mode-enabled');
    if (!setting) {
      return false;
    }
    return setting.value == 'true';
  }

  async isProductBehindCounterEnabled(storeHandle: string): Promise<boolean> {
    const setting = await this.storeSettingsProvider
      .getSetting(storeHandle, StoreSettingsProvider.ProductGroup, 'behind-counter-enabled');
    if (!setting) {
      return false;
    }
    return setting.value == 'true';
  }

  setExternalId(storeHandle: string, externalId: string) {
    this.storageService.store('storeExternalId-' + storeHandle, externalId);
  }

  getExternalId(storeHandle: string) {
    return this.storageService.retrieve('storeExternalId-' + storeHandle);
  }

  async preserveContextInUrl() {
    if (this.contextHistoryPath) {
      await this.router.navigate([], {
        queryParams: {
          ctx: this.contextHistoryPath,
        },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    }
  }
}
