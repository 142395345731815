import {HttpClient} from '@angular/common/http';
import {Page, Paginated} from './models/paginated';
import {Observable} from 'rxjs';
import {AppConfig} from '../app.config';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Product} from '../domain/models/product/product';
import {Category} from '../domain/models/category/category';
import {ProductItem} from '../domain/models/product/product-item';
import {ProductSummary} from '../domain/models/product/product-summary';

@Injectable({
  providedIn: 'root'
})
export class ProductProvider {

  constructor(private http: HttpClient) {
  }

  getProductByHandle(storeHandle: string, productHandle: string): Observable<Product> {
    return this.http.get<Product>(`${AppConfig.getApiUrl()}/products/${storeHandle}/handle/${productHandle}`,
      {
        headers: {'auth-key': AppConfig.authKey},
      });
  }

  getProductById(storeHandle: string, productId: string): Observable<Product> {
    return this.http.get<Product>(`${AppConfig.getApiUrl()}/products/${storeHandle}/${productId}`,
      {
        headers: {'auth-key': AppConfig.authKey},
      });
  }

  searchProducts(storeHandle: string, query: string, page?: Page, isWeight?: boolean): Observable<Paginated<Product>> {
    return this.http.post<Product[]>(`${AppConfig.getApiUrl()}/products/${storeHandle}/search`,
      {
        'query': query,
        'isWeight': isWeight
      }, {
        headers: {'auth-key': AppConfig.authKey},
        params: page?.toParams(),
        observe: 'response',
      }).pipe(map(r => Paginated.fromResponse<Product>(r)));
  }

  getCategory(storeHandle: string, categoryId: string): Observable<Category> {
    return this.http.get<Category>(`${AppConfig.getApiUrl()}/products/${storeHandle}/categories/${categoryId}`,
      {
        headers: {'auth-key': AppConfig.authKey},
      });
  }

  getByBarcode(storeHandle: string, barcode: string): Observable<Product> {
    return this.http.get<Product>(`${AppConfig.getApiUrl()}/products/${storeHandle}/barcode/${barcode}`, {
      headers: {'auth-key': AppConfig.authKey},
    });
  }

  getByArticleSerialNumber(storeHandle: string, articleNumber: string, serialNumber: string): Observable<ProductItem | undefined> {
    return this.http.get<ProductItem>(`${AppConfig.getApiUrl()}/products/${storeHandle}/article/${articleNumber}/serial/${serialNumber}`, {
      headers: {'auth-key': AppConfig.authKey},
    });
  }


  getShoppingBag(storeHandle: string, page?: Page): Observable<Paginated<Product>> {
    return this.http.get<Product[]>(`${AppConfig.getApiUrl()}/products/${storeHandle}/shopping-bags`,
      {
        headers: {'auth-key': AppConfig.authKey},
        params: page?.toParams(),
        observe: 'response',
      }).pipe(map(r => Paginated.fromResponse<Product>(r)));

  }

  async getProductSummaries(storeHandle: string) {
    return await this.getProductSummaryPage(storeHandle, null);
  }

  private async getProductSummaryPage(storeHandle: string, page: Page | null): Promise<ProductSummary[]> {
    let productSummaries: ProductSummary[] = [];
    let response = await this.http.get<ProductSummary[]>(`${AppConfig.getApiUrl()}/products/${storeHandle}/summary`,
      {
        headers: {'auth-key': AppConfig.authKey},
        params: page?.toParams(),
        observe: 'response',
      }).pipe(map(r => Paginated.fromResponse<ProductSummary>(r))).toPromise();

    productSummaries = productSummaries.concat(response.data);

    if (!response.isLast) {
      let recursiveResponse = await this.getProductSummaryPage(storeHandle, response.next()!);
      productSummaries = productSummaries.concat(recursiveResponse);
    }

    return productSummaries;
  }

  getProductsBehindCounter(storeHandle: string, page?: Page): Observable<Paginated<Product>> {
    return this.http.get<Product[]>(`${AppConfig.getApiUrl()}/products/${storeHandle}/behind-counter`,
      {
        headers: {'auth-key': AppConfig.authKey},
        params: page?.toParams(),
        observe: 'response',
      }).pipe(map(r => Paginated.fromResponse<Product>(r)));
  }
}
