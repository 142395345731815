import {ResourceProvider} from '../transport/resource.provider';
import {Injectable} from '@angular/core';
import {CountryPhoneCode} from './models/resource/country-phone-code';

@Injectable({
  providedIn: 'root',
})
export class ResourceService {

  constructor(private resourceProvider: ResourceProvider) {
  }

  getPolicy(): Promise<string> {
    return this.resourceProvider.getPolicy().toPromise();
  }

  getCountryPhoneCodes(): Promise<CountryPhoneCode[]> {
    return this.resourceProvider.getCountryPhoneCodes().toPromise();
  }
}
