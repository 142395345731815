import {StoreProfile} from '../store/store-profile';

export interface Door {
  id: string;
  name: string;
  welcomeTitle?: string;
  welcomeMessage?: string;
  supportMessage?: string;
  cultureName: string;
  storeHandle: string;
  storeProfile?: StoreProfile;
  openingHours?: OpeningHour[];
}

export interface OpeningHour {
  day: Day;
  isOpen: boolean;
  openingTime?: string;
  closingTime?: string;
}

export interface DoorOpen {
  storeHandle: string;
}

export enum Day {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday'
}
