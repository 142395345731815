import {Component, OnInit} from '@angular/core';
import {Store} from '../../../domain/models/store/store';
import {Cart} from '../../../domain/models/order/cart';
import {StoreProfile} from '../../../domain/models/store/store-profile';
import {ActivatedRoute, Router} from '@angular/router';
import {StoreService} from '../../../domain/store.service';
import {ForegroundPaths} from '../../../app-routing.module';

@Component({
  selector: 'app-payment-failed',
  templateUrl: './payment-failed.component.html',
  styleUrls: ['./payment-failed.component.sass'],
})
export class PaymentFailedComponent implements OnInit {
  store?: Store;
  cart?: Cart;
  profile?: StoreProfile;

  constructor(private router: Router,
              private storeService: StoreService,
              private route: ActivatedRoute) {
  }

  async ngOnInit(): Promise<void> {
    const storeHandle = this.route.parent!.firstChild!.snapshot.paramMap.get('id')!;
    this.store = await this.storeService.getStore(storeHandle);
    this.profile = this.store.storeProfile;
  }

  async closeForeground() {
    await this.router.navigate(ForegroundPaths.cart());
  }

}
