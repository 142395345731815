import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AdjustmentType} from '../../domain/models/product/adjustmentType';

@Component({
  selector: 'app-select-fulfilment',
  templateUrl: './adjustment-type-dialog.html',
  styleUrls: ['./adjustment-type-dialog.sass']
})
export class AdjustmentTypeDialog implements OnInit {

  constructor(public ref: MatDialogRef<AdjustmentTypeDialog>,
              @Inject(MAT_DIALOG_DATA)
              public options: AdjustmentType[]) {
  }

  ngOnInit(): void {
  }

  submit(data: AdjustmentType) {
    this.ref.close(data);
  }

  close() {
    this.ref.close();
  }

  getName(name: string) {
    if (name === 'TakeAway') {
      return 'Ta med';
    } else if (name === 'EatIn') {
      return 'Spis her';
    }

    return 'Unknown';
  }
}
