<mat-dialog-actions *ngFor="let item of options">
  <span class="product_values_dialog_options0" (click)="submit(item)">
    {{item.name}}
  </span>
</mat-dialog-actions>
<mat-dialog-actions>
  <span class="product_values_dialog_cancel" (click)="close()">
    {{'DIALOG.cancel' | translate }}
  </span>
</mat-dialog-actions>
