import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-confirog',
  templateUrl: './message.dialog.html',
  styleUrls: ['./message.dialog.sass']
})
export class MessageDialog implements OnInit {

  text: string | undefined;
  title: string | undefined;
  confirm: string | undefined;

  constructor(public ref: MatDialogRef<MessageDialog>,
              @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.text = data.text;
    this.title = data.title;
    this.confirm = data.confirm;
  }

  ngOnInit(): void {
  }

  submit(value: boolean) {
    this.ref.close(value);
  }
}
