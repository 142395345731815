import {HttpParams, HttpResponse} from "@angular/common/http";

export class Paginated<T> {
  constructor(public data: T[],
              readonly pageNumber: number,
              readonly pageSize: number,
              readonly totalCount: number,
              readonly lastPage: number,
              readonly isLast: boolean) {
  }

  static fromResponse<T>(response: HttpResponse<T[]>): Paginated<T> {
    const header = JSON.parse(response.headers.get('X-Pagination')!);
    const isLastPage = header.pageNumber >= header.lastPage;
    return new Paginated(response.body!,
      header.pageNumber,
      header.pageSize,
      header.totalCount,
      header.lastPage,
      isLastPage);
  }

  next(): Page | undefined {
    if (this.isLast)
      return undefined;
    return new Page(this.pageNumber + 1, this.pageSize);
  }
}

export class Page {
  constructor(readonly number: number,
              readonly size?: number) {
  }

  toParams(): HttpParams {
    return new HttpParams({
      fromObject: this.size != null
        ? {
          pageNumber: this.number,
          pageSize: this.size,
        }
        : {pageNumber: this.number}
    });
  }
}
