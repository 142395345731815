export class PriceUtils {
    static toFriendlyPrice(price: number, isWeight: boolean): string {
      if (isWeight){
        return this.replaceEmptyDecimalsIfPossible((price * 10).toFixed(2).toString());
      }
      return this.replaceEmptyDecimalsIfPossible((price / 100).toFixed(2).toString());
    }

    static toPrice(price: number): string {
        return this.replaceEmptyDecimalsIfPossible((price / 100).toFixed(2).toString());
    }

  static toPriceShoppingBag(price: number): string {
    return this.replaceEmptyDecimalsIfPossible((price / 100).toFixed(0).toString());
  }
    private static replaceEmptyDecimalsIfPossible(num: string): string {
        return num.endsWith('00') ? `${num.slice(0, -3)}` : num;
    }
}
