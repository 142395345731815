<google-pay-button *ngIf="session"
  buttonType="pay"
  buttonColor="default"
  buttonSizeMode="fill"
  [environment]="environment"
  [buttonLocale]="buttonLocale"
  [existingPaymentMethodRequired]="existingPaymentMethodRequired"
  [paymentRequest]="{
    apiVersion: paymentRequest.apiVersion,
    apiVersionMinor: paymentRequest.apiVersionMinor,
    allowedPaymentMethods: paymentRequest.allowedPaymentMethods,
    merchantInfo: paymentRequest.merchantInfo,
    transactionInfo: paymentRequest.transactionInfo,
    callbackIntents: ['PAYMENT_AUTHORIZATION']
   }"
  (error)="onError($event)"
  [paymentAuthorizedCallback]="onPaymentDataAuthorized"
  [readyToPayChangeCallback]="onReadyToPayChange"
  [clickCallback]="preventDoubleClick"
  [cancelCallback]="cancelGooglePayment"
></google-pay-button>
