import {ContentObserver} from '@angular/cdk/observers';
import {Subscription} from 'rxjs';
import {AfterViewInit, Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';

const minDurationMs = 200;
const maxDurationMs = 350;

@Component({
  selector: 'app-expanding-overlay-card',
  templateUrl: './expanding-overlay-card.component.html',
  styleUrls: ['./expanding-overlay-card.component.sass'],
})

export class ExpandingOverlayCardComponent implements AfterViewInit, OnDestroy {
  @ViewChild('controller') controller!: ElementRef;
  @ViewChild('content') content!: ElementRef;
  dataChanges: Subscription | undefined;
  expanded = false;

  constructor(private obs: ContentObserver) {
  }

  ngAfterViewInit() {
    this.dataChanges = this.obs.observe(this.content.nativeElement)
      .subscribe(() => this.onChanges());
  }

  onChanges() {
    const maxHeight = Math.min(700, window.innerHeight - 5);

    const from = (this.controller.nativeElement as HTMLElement).scrollHeight;
    const to = Math.min(maxHeight, (this.content.nativeElement as HTMLElement).scrollHeight);
    (this.controller.nativeElement as HTMLElement).style.setProperty('--max-height', to + 'px');

    const duration = Math.min(maxDurationMs, Math.max(minDurationMs, Math.max(from, to)));
    (this.controller.nativeElement as HTMLElement).style.setProperty('--duration', duration + 'ms');

    this.expanded = to > 50;
  }

  ngOnDestroy(): void {
    this.dataChanges?.unsubscribe();
  }
}
