import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {StoreProfile} from '../../domain/models/store/store-profile';

enum SlideState {
  Default,
  Dragging,
  Confirming,
  Confirmed,
}

@Component({
  selector: 'app-slide-to-confirm',
  templateUrl: './slide-to-confirm.component.html',
  styleUrls: ['./slide-to-confirm.component.sass'],
})
export class SlideToConfirmComponent {
  @Input() text?: string;
  @Input() profile?: StoreProfile;
  @Input() confirmCallback?: () => Promise<boolean>;

  @Output() onConfirmed = new EventEmitter<void>();
  @Output() onCanceled = new EventEmitter<void>();

  @ViewChild('endContainer') endContainer!: ElementRef;

  readonly SlideState = SlideState;
  state = SlideState.Default;

  onDragStart() {
    this.state = SlideState.Dragging;
  }

  onDragEnd() {
    if (this.state != SlideState.Confirmed && this.state != SlideState.Confirming) {
      this.state = SlideState.Default;
    }
  }

  onContainerDrop(event: CdkDragDrop<[]>) {
    if (event.container.element.nativeElement === this.endContainer.nativeElement) {
      this.state = SlideState.Confirming;
      if (this.confirmCallback) {
        this.confirmCallback()
          .then(result => result ? this.confirmed() : this.canceled())
          .catch(() => this.canceled());
      } else {
        this.confirmed();
      }
    } else {
      this.canceled();
    }
  }

  confirmed() {
    this.state = SlideState.Confirmed;
    this.onConfirmed.emit();
  }

  canceled() {
    this.state = SlideState.Default;
    this.onCanceled.emit();
  }
}
