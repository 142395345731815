import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../app.config';
import {Observable} from 'rxjs';
import {CountryPhoneCode} from '../domain/models/resource/country-phone-code';

@Injectable({
  providedIn: 'root',
})
export class ResourceProvider {

  constructor(private http: HttpClient) {
  }

  getPolicy(): Observable<string> {
    return this.http.get(`${AppConfig.getApiUrl()}/resources/privacypolicy`, {
      headers: {'auth-key': AppConfig.authKey},
      responseType: 'text',
    });
  }

  getCountryPhoneCodes(): Observable<CountryPhoneCode[]> {
    return this.http.get<CountryPhoneCode[]>(`assets/json/countries.json`);
  }
}

