<div class="full-screen scanner" id="full-screen-scanner-id">
  <app-scanner></app-scanner>
</div>

<router-outlet></router-outlet>

<div *ngIf="isForegroundOpen"
     class="full-screen background-overlay"
     (click)="closeForeground()">
</div>

<app-expanding-overlay-card *ngIf="hasForeground()">
  <div *ngIf="isForegroundOpen && canCloseForeground" class="app_close-container">
    <mat-icon (click)="closeForeground()">
      close
    </mat-icon>
  </div>
  <router-outlet name="foreground-outlet" (activate)="foregroundActivated()"></router-outlet>
</app-expanding-overlay-card>

<!--<app-privacy-warning *ngIf="requirePrivacyConsent"-->
<!--                     class="app_privacy-banner"-->
<!--                     (onAccept)="acceptPrivacyPolicy($event)">-->
<!--</app-privacy-warning>-->

<app-loading></app-loading>
