import {Injectable} from '@angular/core';
import {Dimension} from "./models/product/dimension";
import {Product} from "./models/product/product";

// tslint:disable-next-line:ban-types
declare let gtag: Function;

@Injectable({
    providedIn: 'root'
})
export class GTagService {

    constructor() {
    }

    pageView(pagePath: string, pageLocation: string) {
        const gtmTag = {
            event: 'page_view',
            page_path: pagePath,
            page_location: pageLocation
        };
        gtag(gtmTag);
    }

    addToCart(productId: string, productName: string, productBrand: string, productPrice: number) {
        const gtmTag = {
            event: 'add_to_cart',
            currency: 'NOK',
            items: [{
                id: productId,
                name: productName,
                brand: productBrand,
                price: productPrice / 100.0,
                quantity: 1
            }],
            value: productPrice / 100.0
        };
        gtag(gtmTag);
    }

    beginCheckout(sum: number) {
        const gtmTag = {
            event: 'begin_checkout',
            currency: 'NOK',
            value: sum / 100.0
        };
        gtag(gtmTag);
    }

    quickAdd(dimension: Dimension, product: Product) {
        const gtmTag = {
            event: 'add_to_cart_quick',
            product_id: dimension.articleNumber,
            product_name: product.name
        };
        gtag(gtmTag);
    }
}
