export class TOrderLineRequest {
  constructor(
    readonly id: string | null,
    readonly productId: string,
    readonly productDimensionId: string,
    public quantity: number,
    public itemIds: string[],
    public fulfillmentOptionId?: string,
    readonly priceOverride?: number
  ) {
  }
}
