<ul class="root">
  <li>
    <a routerLink="/orders" class="hover-underline-animation">
      {{ 'MENU.myPurchases' | translate }}
    </a>
    <div *ngIf="showMyOrdersBadge" class="menu-item-badge"></div>
  </li>
  <li>
    <a routerLink="/privacy-policy" class="hover-underline-animation">
      {{ 'MENU.privacyPolicy' | translate }}
    </a>
  </li>
  <li *ngIf="(customerService.isSignedIn$ | async)">
    <a href="#!" class="logout hover-underline-animation" (click)="logout($event)">
      {{ 'LOGIN.signOut' | translate }}
    </a>
  </li>
</ul>
