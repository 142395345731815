import {Component, EventEmitter, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CustomerService} from '../../domain/customer.service';

@Component({
  selector: 'app-vipps-login',
  templateUrl: './vipps-login.component.html',
  styleUrls: ['./vipps-login.component.sass'],
})
export class VippsLoginComponent {
  @Output() loggedIn = new EventEmitter();
  @Output() isLoading = new EventEmitter<boolean>();
  language?: string;

  constructor(private customerService: CustomerService,
              translateService: TranslateService,
  ) {
    this.language = translateService.getBrowserLang().toLocaleLowerCase();
  }

  async login() {
    this.isLoading.emit(true);
    const returnPath = window.location.pathname + window.location.search;
    const response = await this.customerService
      .customerVippsInitialize(returnPath)
      .finally(() => this.isLoading.emit(false));
    window.location.href = response.startLoginAddress;
  }
}
