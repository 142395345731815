<div class="email-page-close">
  <mat-icon (click)="close()">
    close
  </mat-icon>
</div>
<div>
  <div class="email-dialog-title">{{ 'DIALOG.EMAIL.title' | translate }}</div>
  <div class="email-dialog-product">{{ 'DIALOG.EMAIL.email' | translate }}</div>
</div>
<mat-form-field appearance="fill">
  <input [(ngModel)]="email" matInput
         placeholder="{{ 'DIALOG.EMAIL.placeholder' | translate }}">
</mat-form-field>
<div class="email-send-button" (click)="close(email)">
  {{ 'DIALOG.EMAIL.submit' | translate }}
</div>
<h4 class="email-dialog_action email-dialog_cancel" (click)="close()">
  {{ 'DIALOG.cancel' | translate }}
</h4>


