import {Component, OnInit} from '@angular/core';
import {Store} from '../../../domain/models/store/store';
import {StoreService} from '../../../domain/store.service';
import {Router} from '@angular/router';
import {ProductService} from '../../../domain/product.service';
import {Product} from '../../../domain/models/product/product';
import {Paginated} from '../../../transport/models/paginated';
import {ForegroundPaths} from '../../../app-routing.module';

@Component({
  selector: 'app-behind-counter-menu',
  templateUrl: './behind-counter-menu.component.html',
  styleUrls: ['./behind-counter-menu.component.sass'],
})
export class BehindCounterMenuComponent implements OnInit {
  store?: Store;
  products?: Paginated<Product>;

  constructor(private storeService: StoreService,
              private productService: ProductService,
              private router: Router,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.store = await this.storeService.getStore(this.storeService.storeId!);
    this.products = await this.productService.getProductsBehindCounter(this.storeService.storeId!);
  }

  async openProduct(productHandle: string) {
    await this.router.navigate(ForegroundPaths.product(productHandle));
  }
}
