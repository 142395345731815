<div class="root">
  <div class="adjustment-type-header">{{ 'STORE.eatOptions' |  translate }}</div>
  <div class="adjustment-type-buttons">
    <button *ngFor="let adjustment of adjustmentTypes let i = index"
            class="adjustment-type-button"
            [ngStyle]="i == adjustmentTypes!.length-2 ?
             {backgroundColor: profile?.primaryColor, color: profile?.primaryTextColor} :
             {backgroundColor: profile?.primaryTextColor, color: profile?.primaryColor, border: profile?.primaryColor + ' solid 1px'}"
            (click)="typeChosen(adjustment)">
      {{ getTypeName(adjustment.name) }}
    </button>
  </div>
</div>
