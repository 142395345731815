import {Image} from "../domain/models/product/image";

export class ImageUtils {

  static fallbackImage: string = '/assets/images/noimage.png';

  static getImageUrl(image: Image | undefined, size: number, getFallback: boolean = true): string | undefined {
    if (image == undefined) {
      return this.fallbackImage;
    }

    let keys: number[] = Object.keys(image.sizes).map(x => +x);

    if (keys.length === 0) {
      return image.url;
    }
    keys = keys.sort((n1, n2) => n1 - n2);

    for (const key of keys) {
      if (key > size) {
        return image.sizes[key];
      }
    }

    return image.url;
  }
}
