import {Component} from '@angular/core';

@Component({
  selector: 'app-kebab-friday',
  templateUrl: './kebab-friday.component.html',
  styleUrls: ['./kebab-friday.component.sass'],
})
export class KebabFridayComponent {
  isFriday = false;

  constructor() {
    const today = new Date();
    this.isFriday = (today.getDay() === 5);
  }
}
