<div class="product-details_image-container">
  <swiper effect="fade" *ngIf="product"
          class="swiper-container"
          [config]="{
            pagination: {
              el: '.swiper-pagination',
              clickable: true
            },
            spaceBetween: 30
          } //noinspection InvalidExpressionResultType">

    <ng-template swiperSlide *ngIf="!product.images || product.images.length == 0">
      <img class="product-details_image" src="{{getFallBackImage()}}" alt="missing-product-image">
    </ng-template>

    <ng-template swiperSlide *ngFor="let image of product.images; index as i">
      <div *ngIf="product.images.length != 0">
        <app-image-fading class="product-details_image"
                          [animateLoading]="false"
                          source="{{getImage(image, 600)}}">
        </app-image-fading>
      </div>
    </ng-template>
  </swiper>


  <div class="swiper-pagination" *ngIf="product != null && product.images != null && product.images.length > 1"></div>
</div>

<div class="product-details_description-container">
  <div *ngIf="product != null, else loading">
    <h5 class="product-details_vendor">{{product.vendor}}</h5>
    <h2 class="product-details_title">{{product.name}}</h2>
    <h5 *ngIf="selectedDimension != null" class="product-details_barcode">{{selectedDimension.articleNumber}}</h5>
    <h5 *ngIf="isStock" class="product-details_stock">{{ 'PRODUCTDETAILS.stock' | translate}}
      : {{availabilityCount}}</h5>
    <ng-container *ngIf="product.description">
      <div class="product-details_description">
        <p #details
           class="product-details_description-text"
           [ngClass]="{'minimized': !showFullDescription}"
           [innerHTML]="product.description | safeHtml">
        </p>
        <div *ngIf="!showFullDescription && isOverflown(details)"
             class="overflow"
             (click)="showFullDescription=true">
        </div>
      </div>

      <div class="product-details_expand">
        <mat-icon *ngIf="isOverflown(details) || showFullDescription"
                  (click)="showFullDescription = !showFullDescription">
          {{showFullDescription ? 'expand_less' : 'expand_more'}}
        </mat-icon>
      </div>
    </ng-container>

    <div class="product-details_option" *ngIf="selectedDimension != null && product.options != null && product.options.length > 0">
      <div *ngFor="let option of product.options; let i = index">
        <div *ngIf="option.values.length > 1">
          <span class="product-details_option-name">
            {{option.name}}:
          </span>
          <div class="product-details_option-select"
               [ngStyle]="{backgroundColor: profile?.primaryColor, color: profile?.primaryTextColor}"
               (click)="selectDimension(option, i)">
            <span>{{getOptionValue(selectedDimension, i)}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="product-details_price">
      <span *ngIf="hasProductCampaign(campaigns)" class="product-details__campaign-price">
        {{ toFriendlyPrice(getCheapestPrice(product, campaigns)) | currency:currencyCode:'symbol-narrow': '':cultureName }}
      </span>
      <span *ngIf="hasProductCampaign(campaigns); else originalPrice" [ngClass]="{'original-price': hasProductCampaign(campaigns)}">
        {{ this.price | currency:currencyCode:'': '':cultureName }}
      </span>
      <ng-template #originalPrice>
        <span>{{ this.price | currency:currencyCode:'symbol-narrow': '':cultureName }}</span>
      </ng-template>

      <span class="ageLimit" *ngIf="product.ageGroup">
        {{ product.ageGroup.name }}+
      </span>

      <ng-template *ngIf="isUnitPricing">
        <span style="font-size: 15px">
          ({{ this.unitPrice | currency:currencyCode:'symbol-narrow': '':cultureName }} / {{ this.unitAbbreviation }})
        </span>
      </ng-template>
      <span style="padding-left: 10px" *ngIf="product.isWeight">/ kg</span>

      <div class="product-details_campaigns">
        <div *ngFor="let campaign of campaigns" class="product-details_campaign">
          {{ campaign.name }}
        </div>
      </div>
    </div>

    <div class="product-details-deposit-fee" *ngIf="this.selectedDimension != null && this.selectedDimension.requiredDimension">
      <div *ngIf="this.selectedDimension.requiredDimension.prices[0].amount != 0">
        + {{this.selectedDimension.requiredDimension.name}}
        {{this.selectedDimension.requiredDimension.prices[0].amount / 100 | currency:currencyCode:'symbol-narrow': '':cultureName}}
      </div>
    </div>

    <div class="product-details_purchase" *ngIf="product != null">
      <div class="product-details_purchase-button"
           [ngStyle]="{backgroundColor: profile?.primaryColor, color: profile?.primaryTextColor}"
           [ngClass]="{'isBusy': isBusy}"
           (click)="addToCart( store?.handle!, product, selectedDimension)">
        {{ 'PRODUCTDETAILS.addToCart' | translate }}
      </div>
    </div>

    <div *ngIf="relatedProducts.length > 0" class="product-details_related">
      <h3 class="product-details_related-title">
        {{store?.relatedProductsTitle}}
      </h3>
      <div class="product-details_related-container">
        <app-product-card *ngFor="let relatedProduct of relatedProducts"
                          [product]="relatedProduct"
                          (onClick)="openProduct(relatedProduct.handle)"
                          [store]="store"
                          [hasShadow]="true"
                          [cultureName]="cultureName"
                          [currencyCode]="currencyCode"
                          class="product-details_related-card">
        </app-product-card>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <app-gradient-loader></app-gradient-loader>
  <app-gradient-loader style="margin-top: 10px"></app-gradient-loader>
</ng-template>
