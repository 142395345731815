import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppConfig} from '../app.config';
import {InitializePaymentRequest} from './models/payment/initialize-payment.request';
import {InitializePaymentResponse} from './models/payment/initialize-payment.response';
import {CompleteOrderRequest} from './models/order/complete-order.request';
import {AgeVerficationRequest} from './models/ageverfication/ageverfication.request';
import {AgeVerficationResponse} from './models/ageverfication/ageverfication.response';
import {TransactionPayment} from '../domain/models/order/transaction';
import {ApplePaySessionRespond} from './models/payment/apple-pay-session.respond';
import {CompleteApplePaymentRequest} from './models/payment/complete-apple-payment-request';
import {ApplePayValidateMerchantRequest} from './models/payment/ApplePayValidateMerchantRequest';
import {GooglePaySessionRespond} from './models/payment/google-pay-session.respond';
import {CompleteGooglePaymentRequest} from './models/payment/complete-google-payment-request';
import {PaymentMethod} from '../domain/models/payment/payment-method';

@Injectable({
  providedIn: 'root',
})
export class PaymentProvider {

  constructor(private http: HttpClient) {
  }

  initialize(customerId: string, request: InitializePaymentRequest): Observable<InitializePaymentResponse> {
    return this.http.post<InitializePaymentResponse>(
      `${AppConfig.getApiUrl()}/payment/initialize`,
      request, {
        headers: {
          'auth-key': AppConfig.authKey,
          'customer-id': customerId,
        },
      },
    );
  }

  complete(request: CompleteOrderRequest) {
    return this.http.post(
      `${AppConfig.getApiUrl()}/payment/complete`,
      request,
      {
        headers: {
          'auth-key': AppConfig.authKey,
        },
      },
    );
  }

  applePayInitialize(storeHandle: string, customerId: string) {
    return this.http.get<ApplePaySessionRespond>(
      `${AppConfig.getApiUrl()}/payment/apple-pay/session-information/${storeHandle}`,
      {
        headers: {
          'auth-key': AppConfig.authKey,
          'storeHandle': storeHandle,
          'customer-id': customerId,
        },
      },
    );
  }

  applePayPay(customerId: string, request: CompleteApplePaymentRequest) {
    return this.http.post(
      `${AppConfig.getApiUrl()}/payment/apple-pay/pay`,
      request, {
        headers: {
          'auth-key': AppConfig.authKey,
          'customer-id': customerId,
        },
      },
    );
  }

  applePayValidateMerchant(customerId: string, request: ApplePayValidateMerchantRequest) {
    return this.http.post<string>(
      `${AppConfig.getApiUrl()}/payment/apple-pay/validate-merchant`,
      request, {
        headers: {
          'auth-key': AppConfig.authKey,
          'customer-id': customerId,
        },
      },
    );
  }

  ageVerification(customerId: string, request: AgeVerficationRequest) {
    return this.http.put<AgeVerficationResponse>(
      `${AppConfig.getApiUrl()}/age-group-code/validate`,
      request, {
        headers: {
          'auth-key': AppConfig.authKey,
          'customer-id': customerId,
        },
      },
    );
  }

  refreshPaymentStatus(orderId: string) {
    return this.http.get<TransactionPayment>(
      `${AppConfig.getApiUrl()}/payment/${orderId}/refresh`,
      {
        headers: {
          'auth-key': AppConfig.authKey,
        },
      },
    );
  }

  googlePayInitialize(storeHandle: string, customerId: string) {
    return this.http.get<GooglePaySessionRespond>(
      `${AppConfig.getApiUrl()}/payment/google-pay/session-information/${storeHandle}`,
      {
        headers: {
          'auth-key': AppConfig.authKey,
          'storeHandle': storeHandle,
          'customer-id': customerId,
        },
      },
    );
  }

  googlePayPay(customerId: string, request: CompleteGooglePaymentRequest) {
    return this.http.post(
      `${AppConfig.getApiUrl()}/payment/google-pay/pay`,
      request, {
        headers: {
          'auth-key': AppConfig.authKey,
          'customer-id': customerId,
        },
      },
    );
  }

  getPaymentMethods(storePublicId: string, chainPublicId: string):Observable<Array<PaymentMethod>> {
    return this.http.get<Array<PaymentMethod>>(
      `${AppConfig.getApiUrl()}/payment-methods`,
      {
        headers: {
          'auth-key': AppConfig.authKey,
        },
        params: {chainPublicId,storePublicId},
      },
    );
  }

  getDirectPayment(storePublicId: string) {
    return this.http.get<PaymentMethod>(
      `${AppConfig.getApiUrl()}/stores/${storePublicId}/payment-methods/direct-payment`,
      {
        headers: {
          'auth-key': AppConfig.authKey,
        },
      },
    );
  }
}
