import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {StoreService} from '../../../domain/store.service';
import {Store} from '../../../domain/models/store/store';

@Component({
  selector: 'app-fygi-page',
  templateUrl: './fygi-page.component.html',
  styleUrls: ['./fygi-page.component.sass'],
})
export class FygiPageComponent implements OnInit {


  store?: Store;
  codeDigitOne?: string;
  codeDigitTwo?: string;
  codeDigitThree?: string;
  codeDigitFour?: string;
  storeNumber?: string;
  wrongCodeMessage?: boolean;
  wrongDigitMessage?: boolean;
  digit?: number;
  selectText!: HTMLInputElement;
  numberNotEntered = false;

  constructor(private router: Router,
              private storeService: StoreService) {
  }

  ngOnInit(): void {
    document.getElementById(String('1'))?.focus();
  }

  storeCode(storeCode: string, $event: Event) {
    if (!/^(10|\d)$/.test(storeCode)) {
      this.wrongDigitMessage = true;
      return;
    }

    const elementId = ($event.target as Element).id;
    this.digit = Number(elementId);
    this.wrongDigitMessage = false;

    if (storeCode) {
      document.getElementById(String(this.digit + 1))?.focus();
    }

    if (!storeCode) {
      document.getElementById(String(this.digit - 1))?.focus();
    }
  }

  async storeLogin() {
    this.numberNotEntered = false;
    if (!this.codeDigitOne && !this.codeDigitTwo && !this.codeDigitThree && !this.codeDigitFour) {
      this.numberNotEntered = true;
      return;
    }

    if (this.codeDigitOne && this.codeDigitTwo && this.codeDigitThree && this.codeDigitFour) {
      this.storeNumber = this.codeDigitOne + this.codeDigitTwo + this.codeDigitThree + this.codeDigitFour;
    } else {
      this.wrongCodeMessage = true;
      return;
    }

    this.validateStoreCode(this.storeNumber);
    if (this.wrongDigitMessage) {
      return;
    }

    try {
      this.store = await this.storeService.getStoreByNumber(this.storeNumber!);
    } catch (error) {
      if (error.status == 404 || error.status == 400) {
        this.wrongCodeMessage = true;
        return;
      } else {
        throw error;
      }
    }
    await this.router.navigate([`store/${this.store?.handle}`]);
  }

  onFocus($event: Event) {
    const digit = ($event.target as Element).id;
    this.selectText = <HTMLInputElement>document.getElementById(digit);
    setTimeout(() => this.selectText.setSelectionRange(0, 1), 0);
    this.selectText.focus();
    oncontextmenu = function () {
      return false;
    };
  }

  validateStoreCode(storeNumber: string) {
    for (const digitChar of storeNumber) {
      const digit = Number(digitChar);
      if (!/^(10|\d)$/.test(String(digit))) {
        this.wrongDigitMessage = true;
        return;
      }
    }
  }
}
