<mat-dialog-content>
  <p class="confirm-dialog_title">
    {{ text }}
  </p>
</mat-dialog-content>
<mat-dialog-actions class="confirm-dialog_actions">
  <h4 class="confirm-dialog_action cancel" (click)="submit(false)">
    {{ cancel ?? 'DIALOG.cancel' | translate }}
  </h4>
  <h4 class="confirm-dialog_action submit" (click)="submit(true)">
    {{ confirm ?? 'DIALOG.confirm' | translate }}
  </h4>
</mat-dialog-actions>
