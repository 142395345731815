<div class="confirm-delivery">
  <mat-dialog-content>
    <div class="confirm-delivery__avatar">
      <img class="confirm-delivery__avatar-image" src="/assets/images/avatar.svg" alt="avatar image" width="104" height="127">
    </div>
    <h4 class="confirm-delivery__title">
      {{ 'DIALOG.CONFIRMDELIVERY.title' | translate }}
    </h4>
    <p class="confirm-delivery__text">
      {{ 'DIALOG.CONFIRMDELIVERY.text' | translate }}
    </p>
  </mat-dialog-content>
  <mat-dialog-actions class="confirm-delivery__actions">
    <button class="confirm-delivery__btn accept"
            [ngStyle]="{backgroundColor: profile?.primaryColor, color: profile?.primaryTextColor}"
            (click)="submit(true)">
      {{ 'DIALOG.yes' | translate }}
    </button>
    <button class="confirm-delivery__btn reject"
            [ngStyle]="{backgroundColor: profile?.primaryTextColor, color: profile?.primaryColor, borderColor: profile?.primaryColor}"
            (click)="submit(false)">
      {{ 'DIALOG.no' | translate }}
    </button>
  </mat-dialog-actions>
</div>
