import { Injectable } from '@angular/core';
import {AdjustmentType} from './models/product/adjustmentType';
import {LocalStorageService} from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class AdjustmentTypeService {

  keyAdjustmentType = 'AdjustmentType_';

  constructor(
    private storageService: LocalStorageService
  ) { }

  noStoredType(storeHandle: string) {
    return this.getStoredTypeId(storeHandle) == null;
  }

  setStoredTypeId(storeHandle: string, adjustmentType: AdjustmentType) {
    this.storageService.store(this.getStoreKey(storeHandle), adjustmentType.id);
  }

  private getStoreKey(storeHandle: string) {
    return this.keyAdjustmentType + storeHandle;
  }

  getStoredTypeId(storeHandle: string) : string | null {
    return this.storageService.retrieve(this.getStoreKey(storeHandle));
  }
}
