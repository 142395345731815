import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Product} from '../../domain/models/product/product';
import {Dimension} from '../../domain/models/product/dimension';

@Component({
  selector: 'app-select-product-dimension',
  templateUrl: './select-product-dimension.dialog.html',
  styleUrls: ['./select-product-dimension.dialog.sass']
})
export class SelectProductDimension implements OnInit {
  constructor(
    public ref: MatDialogRef<SelectProductDimension>,
    @Inject(MAT_DIALOG_DATA)
    public dimensions: Dimension[],
  ) {

  }

  async ngOnInit() {
  }

  submit(data: string) {
    this.ref.close(data);
  }

  close() {
    this.ref.close();
  }
}
