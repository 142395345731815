import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FulfillmentOption} from '../../domain/models/store/fulfillmentOption';

@Component({
  selector: 'app-offline-dialog',
  templateUrl: './offline-dialog.component.html',
  styleUrls: ['./offline-dialog.component.sass']
})
export class OfflineDialogComponent implements OnInit {

  constructor(public ref: MatDialogRef<OfflineDialogComponent>,
              @Inject(MAT_DIALOG_DATA)
              public options: FulfillmentOption[]) {
  }

  ngOnInit(): void {
  }

  submit(data: FulfillmentOption) {
    this.ref.close(data);
  }

  close() {
    this.ref.close();
  }
}
