import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {DimensionAvailability} from "../domain/models/product/dimension-availability";
import {AppConfig} from "../app.config";

@Injectable({
  providedIn: 'root'
})
export class DimensionAvailabilityProvider {

  constructor(private http: HttpClient) {
  }

  getStock(storeHandle: string, productId: string): Observable<DimensionAvailability[]> {
    return this.http.get<DimensionAvailability[]>(
      `${AppConfig.getApiUrl()}/dimension-availability/${storeHandle}/${productId}`,
      {
        headers: {
          'auth-key': AppConfig.authKey,
        }
      }
    )
  }
}
