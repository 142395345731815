import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FulfillmentOption} from "../../domain/models/store/fulfillmentOption";

@Component({
  selector: 'app-select-fulfilment',
  templateUrl: './select-fulfillment-dialog.html',
  styleUrls: ['./select-fulfillment-dialog.sass']
})
export class SelectFulfilmentDialog implements OnInit {

  constructor(public ref: MatDialogRef<SelectFulfilmentDialog>,
              @Inject(MAT_DIALOG_DATA)
              public options: FulfillmentOption[]) {
  }

  ngOnInit(): void {
  }

  submit(data: FulfillmentOption) {
    this.ref.close(data);
  }

  close() {
    this.ref.close();
  }
}
