import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  templateUrl: './email.dialog.html',
  styleUrls: ['./email.dialog.sass']
})
export class EmailDialog {
  email?: string;

  constructor(public dialogRef: MatDialogRef<EmailDialog>) {
  }

  close(email?: string) {
    this.dialogRef.close(email);
  }
}
