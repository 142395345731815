<div class="inspection">
  <ng-container *ngIf="!minimized else minimizedTpl">
    <div class="inspection__expanded">
      <h2 class="inspection__title">{{ 'INSPECTION.title' | translate }}</h2>
      <ng-container *ngIf="!isValid else successTpl">
        <p class="inspection__text">{{ 'INSPECTION.description' | translate }}</p>
        <img src="/assets/images/cashier-inspection.svg" alt="cashier inspection illustration" width="252" height="325">
        <button class="inspection__button"
                type="button"
                [ngStyle]="{background: profile?.primaryColor, color: profile?.primaryTextColor}"
                (click)="minimizeAndStartScanner()">
          {{ 'INSPECTION.button' | translate }}
        </button>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #minimizedTpl>
    <div class="inspection__minimized" (click)="expand()">
      <h2 class="inspection__title">{{ 'INSPECTION.title' | translate }}</h2>
    </div>
  </ng-template>

  <ng-template #successTpl>
    <ng-lottie
      class="inspection__success"
      [options]="{
        path: '/assets/animations/whitecheck.json',
        renderer: 'svg',
        rendererSettings: {
          viewBoxSize: '75 55 375 375'
        },
        autoplay: true,
        loop: false
      }"
    ></ng-lottie>
  </ng-template>
</div>
