<mat-dialog-content>
  <p class="message-dialog_title">
    {{ title }}
  </p>
  <p class="message-dialog_text" [innerHtml]="text | safeHtml"></p>
</mat-dialog-content>
<mat-dialog-actions class="message-dialog_actions">
  <h4 class="message-dialog_action submit" (click)="submit(true)">
    {{ confirm ?? 'DIALOG.confirm' | translate }}
  </h4>
</mat-dialog-actions>
