import {Component, OnInit} from '@angular/core';
import {CustomerService} from '../../../domain/customer.service';
import {ResourceService} from '../../../domain/resource.service';
import {ActivatedRoute, Router} from '@angular/router';
import {StoreService} from '../../../domain/store.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.sass'],
})
export class PrivacyPolicyComponent implements OnInit {
  customerId?: string;
  body?: string;

  constructor(
    private customerService: CustomerService,
    private resourceService: ResourceService,
    private router: Router,
    private storeService: StoreService,
    private route: ActivatedRoute,
  ) {
  }

  async ngOnInit() {
    await this.storeService.preserveContextInUrl();

    this.customerId = await this.customerService.getCustomerId();
    this.body = await this.resourceService.getPolicy();
  }

  async onBackClick() {
    const backCtx = this.route.snapshot.queryParams['ctx'];

    if (backCtx && backCtx.length > 0) {
      await this.router.navigate([backCtx]);
      return;
    }

    await this.router.navigate(['/']);
  }
}
