import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Option} from "../../domain/models/product/option";

@Component({
  selector: 'app-select-option',
  templateUrl: './select-option.dialog.html',
  styleUrls: ['./select-option.dialog.sass']
})
export class SelectOptionDialog implements OnInit {

  constructor(public ref: MatDialogRef<SelectOptionDialog>,
              @Inject(MAT_DIALOG_DATA)
              public option: Option) { }

  ngOnInit(): void {
  }

  submit(data: string) {
    this.ref.close(data);
  }

  close() {
    this.ref.close();
  }
}
