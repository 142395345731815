export class StrongPointScale {

  static isValidInput(scanResult: string): boolean {
    return scanResult.length >= 2 && scanResult[0] === 'w' && !isNaN(this.parseResult(scanResult));
  }

  static parseWeightInGrams(scanResult: string): number {
    if (!this.isValidInput(scanResult)) {
      throw new Error('Invalid input ' + scanResult);
    }
    return this.parseResult(scanResult) * 1000;
  }

  private static parseResult(result: string) : number {
    return Number(result.substring(1).replace(',', '.'));
  }
}
