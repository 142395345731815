
export interface OrderLineRelation {
  orderLineId: string;
  relatedOrderLineId: string;
  type: string;
  sortIndex: number;
}

export enum OrderLineRelationType {
  Product = 'product',
  Campaign = 'campaign',
}
