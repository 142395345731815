<div class="door-closed">
  <div class="login-dialog-close">
    <mat-icon (click)="close()">
      close
    </mat-icon>
  </div>
  <img class="door-closed__image" src="assets/images/store-invalid.svg" alt="door closed" height="180px" width="180px">
  <div class="door-closed__content">
    <div class="door-closed__text">
      {{ 'DOOR.closedDialogTitle' | translate }}
    </div>
    <div class="door-closed__instructions">
      {{ 'DOOR.closedDialogText' | translate }}
    </div>
    <div *ngIf="data.openingHours" class="door-closed__instructions door-closed__instructions--link" (click)="openOpeningHours()">
      {{ 'DOOR.closedDialogOpeningHours' | translate }}
    </div>
  </div>
</div>
