import {environment} from '../environments/environment';

export class AppConfig {

  static authKey = environment.authKey;
  static scannerLicense = environment.cortexLicense;

  static getApiUrl(version?: string) {
    if (version == 'v1') {
      return environment.apiUrl2;
    }
    return environment.apiUrl;
  }
}
