<div class="page-not-found">
  <div class="content">
    <div>
      <img class="image" src="assets/images/store-invalid.svg" alt="avatar" height="100px" width="100px">
    </div>
    <div class="text">{{ 'ERROR.pageNotFoundTitle' | translate }}</div>
    <div class="instructions"><a href="/">{{ 'ERROR.pageNotFoundInstructions' | translate }}</a></div>
  </div>

</div>


