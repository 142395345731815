interface Transaction {
    id: string;
    orderId: string;
    type: TransactionType;
    status: TransactionStatus;
    createdAt: Date;
}

export interface TransactionPayment extends Transaction {
  type: TransactionType.Payment;
  paymentId: string;
  paymentMethod: string;
}

export interface TransactionDelivery extends Transaction {
  type: TransactionType.Delivery;
  employeeId: string;
}

export interface TransactionRefund extends Transaction {
  type: TransactionType.Refund;
  orderLineId: string;
  quantity?: number;
  paymentMethod: string;
}

export enum TransactionType {
  Payment = 'payment',
  Delivery = 'delivery',
  Refund = 'refund',
}

export enum TransactionStatus {
  Success = 'success',
  Failed = 'failed',
  Pending = 'pending',
}

