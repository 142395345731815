import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {CustomerService} from '../../../domain/customer.service';
import {MatDialog} from '@angular/material/dialog';
import {MessageDialog} from '../../../dialogs/message/message.dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {DoorService} from '../../../domain/door.service';
import {Day, Door} from '../../../domain/models/door/door';
import {Title} from '@angular/platform-browser';
import {ImageUtils} from '../../../utils/image.utils';
import {delay} from '../../../utils/promise.utils';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {StoreService} from '../../../domain/store.service';
import {DatePipe} from '@angular/common';
import {httpUtils} from '../../../utils/http.utils';
import {HttpStatusCode} from '@angular/common/http';
import {DoorClosedDialog} from '../../../dialogs/door-closed/door-closed.dialog';

@Component({
  selector: 'app-door',
  templateUrl: './door.component.html',
  styleUrls: ['./door.component.sass'],
})
export class DoorComponent implements OnInit, OnDestroy {
  hasValidAuth = false;
  isOpeningDoor = false;
  notFound = false;
  doorDisabled = false;

  door?: Door;
  storeLogo?: string;

  authSubscription?: Subscription;

  constructor(private el: ElementRef<HTMLDivElement>,
              private dialog: MatDialog,
              private route: ActivatedRoute,
              private router: Router,
              private titleService: Title,
              private doorService: DoorService,
              private customerService: CustomerService,
              private translationService: TranslateService,
              private storeService: StoreService,
  ) {
  }

  async ngOnInit() {
    const doorId = this.route.snapshot.paramMap.get('id');
    if (doorId) {
      try {
        this.door = await this.doorService.getDoor(doorId);
      } catch (error) {
        if (error.status == 404) {
          this.handleDoorNotFound();
        }
        throw error;
      }
    } else {
      this.handleDoorNotFound();
      throw Error('Missing door id route param');
    }

    if (this.door) {
      this.storeService.storeId = this.door.storeHandle;
      this.authSubscription = this.customerService.isSignedIn$.subscribe((isSignedIn) => {
        if (isSignedIn) {
          this.hasValidAuth = this.validateToken();
        } else {
          this.hasValidAuth = false;
        }
      });

      if (this.door?.storeProfile?.logoImage) {
        this.storeLogo = ImageUtils.getImageUrl(this.door.storeProfile.logoImage, 200, false) ?? '/assets/images/LogoFygi.svg';
      } else {
        this.storeLogo = '/assets/images/LogoFygi.svg';
      }

      this.titleService.setTitle(`${this.door?.welcomeTitle ?? this.translationService.instant('DOOR.defaultTitle')} | FYGI`);
    }
  }

  ngOnDestroy() {
    this.authSubscription?.unsubscribe();
  }

  openLoginDialog() {
    this.customerService.openLoginDialog().afterClosed().subscribe(() => {
      this.hasValidAuth = this.validateToken();
      if (this.hasValidAuth) {
        delay(200).then(() => {
          const doorEl = this.el.nativeElement.firstElementChild;
          doorEl?.scrollTo({top: doorEl.scrollHeight, behavior: 'smooth'});
        });
      }
    });
  }

  async openDoor() {
    if (this.isOpeningDoor) {
      return;
    } else if (this.door == null) {
      this.handleDoorNotFound();
      throw Error('Missing door object');
    }

    this.isOpeningDoor = true;
    this.doorDisabled = false;
    try {
      const result = await this.doorService.openDoor(this.door.id);
      await this.router.navigate([`store/${result.storeHandle}`]);
    } catch (error) {
      const http = httpUtils(error);
      if (http.isStatus(HttpStatusCode.Unauthorized)) {
        this.hasValidAuth = false;
        this.openLoginDialog();
      } else if (http.isStatus(HttpStatusCode.Locked)) {
        this.doorDisabled = true;
        this.openDoorClosedDialog();
      } else if (http.isStatus(HttpStatusCode.NotFound, HttpStatusCode.Gone)) {
        this.handleDoorNotFound();
      }
      throw error;
    } finally {
      this.isOpeningDoor = false;
    }
  }

  openHelp() {
    this.dialog.open(MessageDialog, {
      data: {
        title: this.translationService.instant('DOOR.helpTitle'),
        text: this.door?.supportMessage,
        confirm: this.translationService.instant('DOOR.dialogClose'),
      },
    });
  }

  validateToken() {
    return Boolean(this.customerService.getAndValidateToken(true));
  }

  handleDoorNotFound() {
    this.notFound = true;
    this.doorDisabled = true;
    this.isOpeningDoor = false;
    this.hasValidAuth = false;
  }

  openOpeningHours() {
    this.dialog.open(MessageDialog, {
      data: {
        title: this.translationService.instant('DOOR.openingHours'),
        text: this.formatOpeningHours(),
        confirm: this.translationService.instant('DOOR.dialogClose'),
      },
    });
  }

  private formatOpeningHours() {
    if (this.door?.openingHours?.length) {
      const datePipe = new DatePipe(this.translationService.getBrowserCultureLang());
      return Object.values(Day).map(day => {
        const oH = this.door?.openingHours?.find(h => h.day === day);
        let timeString = '';
        if (oH?.openingTime && oH.closingTime) {
          const openingDate = new Date(`1970-01-01T${oH.openingTime}Z`);
          const closingDate = new Date(`1970-01-01T${oH.closingTime}Z`);
          timeString = `${datePipe.transform(openingDate, 'shortTime', 'GMT')} - ${datePipe.transform(closingDate, 'shortTime', 'GMT')}`;
        } else if (oH?.isOpen) {
          timeString = this.translationService.instant('DOOR.openingHourOpen');
        } else if (oH && !oH.isOpen) {
          timeString = this.translationService.instant('DOOR.openingHourClosed');
        } else {
          timeString = this.translationService.instant('DOOR.openingHourOpen');
        }
        return `<span style="display: inline-block; min-width: 100px; opacity: 0.8">
                  ${this.translationService.instant(`DATE.${day}`)}:
                </span>
                <strong style="white-space: nowrap">${timeString}</strong>`;
      }).join('<br>');
    }
    return 'N/A';
  }

  openDoorClosedDialog() {
    this.dialog.open(DoorClosedDialog, {
      width: '400px',
      data: {
        openingHours: this.door?.openingHours ? () => this.openOpeningHours() : undefined,
      },
    });
  }
}

