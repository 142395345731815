import {Page, Paginated} from "../../transport/models/paginated";

interface Handler<T> {
  (page?: Page): Promise<Paginated<T>>;
}

export class PaginationHandler<T> {
  data: T[] = [];
  currentPage?: Paginated<T>;
  isBusy: boolean = false;
  error?: any;

  constructor(private handler: Handler<T>) {
  }

  hasNext(): boolean {
    if (!this.currentPage)
      return true;
    return !this.currentPage.isLast;
  }

  async getNext() {
    if (!this.isBusy) {
      this.isBusy = true;
      const next = this.currentPage?.next();
      const nextPage = await this.handler(next).catch(reason => {
        this.error = reason;
        return undefined;
      });
      if (nextPage != undefined){
        this.currentPage = nextPage;
        this.data.push(...nextPage.data);
      }
      this.isBusy = false;
    }
  }

  clear() {
    this.currentPage = undefined;
    this.data = [];
    this.isBusy = false;
  }
}
