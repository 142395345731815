import {Injectable} from "@angular/core";
import {EmailReceiptRequest} from "../transport/models/email/email-receipt.request";
import {EmailReceiptResponse} from "../transport/models/email/email-receipt.response";
import {EmailProvider} from "../transport/email.provider";
import {CustomerService} from "./customer.service";

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  constructor(private customerService: CustomerService,
              private emailProvider: EmailProvider) {
  }

  async sendReceipt(orderId: string, recipient: string): Promise<EmailReceiptResponse> {
    const customerId = await this.customerService.getCustomerId();
    const req = new EmailReceiptRequest(orderId, customerId, recipient);
    return await this.emailProvider.sendReceipt(customerId, req).toPromise();
  }

  async sendGiftReceipt(orderId: string, recipient: string): Promise<EmailReceiptResponse> {
    const customerId = await  this.customerService.getCustomerId();
    const req = new EmailReceiptRequest(orderId, customerId, recipient);
    return await  this.emailProvider.sendGiftReceipt(customerId, req).toPromise();
  }
}
