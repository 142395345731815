import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {StoreProfile} from '../../domain/models/store/store-profile';

@Component({
  templateUrl: './confirm-delivery.dialog.html',
  styleUrls: ['./confirm-delivery.dialog.sass'],
})
export class ConfirmDeliveryDialog {
  profile?: StoreProfile;

  constructor(public ref: MatDialogRef<ConfirmDeliveryDialog>,
              @Inject(MAT_DIALOG_DATA) data: { profile?: StoreProfile },
  ) {
    this.profile = data.profile;
  }

  submit(value: boolean) {
    this.ref.close(value);
  }
}
