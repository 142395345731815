import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoadingService} from '../../domain/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.sass'],
})
export class LoadingComponent implements OnInit, OnDestroy {
  isLoading = false;

  constructor(private queueService: LoadingService) {
  }

  ngOnInit(): void {
    this.queueService.active.subscribe(active => this.isLoading = active);
    this.queueService.numInQueue.subscribe(numInQueue => this.isLoading = numInQueue > 1);
  }

  ngOnDestroy() {
    this.queueService.active.unsubscribe();
    this.queueService.numInQueue.unsubscribe();
  }
}
