import {Injectable} from '@angular/core';
import {Cache} from '../utils/cache.utils';
import {ProductSummary} from './models/product/product-summary';
import {ProductDimensionSummary} from './models/product/product-dimension-summary';

@Injectable({
  providedIn: 'root',
})
export class ProductOfflineCacheService {

  private productCache = new Cache<ProductSummary[]>(3600);


  setCache(storeHandle: string, products: ProductSummary[]) {
    this.productCache.set(storeHandle, products);
  }

  haveCache(storeHandle: string): boolean {
    return this.productCache.get(storeHandle) != undefined;
  }

  getProduct(storeHandle: string, barcode: string): [ProductSummary, ProductDimensionSummary] {
    let products = this.productCache.get(storeHandle);
    let product = products?.find(p => p.productDimensionSummaries.some(pd => pd.barcode == barcode));
    return [product!, product!.productDimensionSummaries.find(d => d.barcode == barcode)!];
  }

}
