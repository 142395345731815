import {HttpResponseBase} from '@angular/common/http';

class HttpUtils {
  private readonly response?: HttpResponseBase;

  get status() {
    return this.response?.status ?? null;
  }

  constructor(obj: unknown) {
    if (obj instanceof HttpResponseBase) {
      this.response = obj;
    }
    return this;
  }

  isStatus(...args: number[]) {
    if (this.response == null) {
      return false;
    }
    return args.includes(this.response.status);
  }

  isNotStatus(...args: number[]) {
    return !this.isStatus(...args);
  }

  isAbortError() {
    return this.response?.status == 0;
  }
}

export function httpUtils(obj: unknown) {
  return new HttpUtils(obj);
}
