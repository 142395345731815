import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ReadyToPayChangeResponse} from '@google-pay/button-angular/lib/button-manager';
import {GooglePaySessionRespond} from '../../transport/models/payment/google-pay-session.respond';
import {PaymentService} from '../../domain/payment.service';
import {InitializePaymentResponse} from '../../transport/models/payment/initialize-payment.response';
import {Cart} from '../../domain/models/order/cart';
import {PaymentMethod} from '../../domain/models/payment/payment-method';
import {ForegroundPaths} from '../../app-routing.module';
import {CartService} from '../../domain/cart.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {GooglePaymentDataRequest} from '@adyen/adyen-web/dist/types/components/GooglePay/types';
import {environment} from '../../../environments/environment';
import Bugsnag from '@bugsnag/js';
import {delay} from '../../utils/promise.utils';

@Component({
  selector: 'app-google-pay',
  templateUrl: './google-pay.component.html',
  styleUrls: ['./google-pay.component.sass'],
})
export class GooglePayComponent {
  @Input() totalSum: number | undefined;
  @Input() storeHandle: string | undefined;
  @Input() orderId: string | undefined;
  @Input() cart?: Cart;
  @Input() paymentMethod: PaymentMethod | undefined;
  @Output() isReadyToPay = new EventEmitter<boolean>();
  session?: GooglePaySessionRespond;
  amount: string | undefined;
  initializeResponse?: InitializePaymentResponse;
  buttonLocale: string | undefined;
  isPaymentBusy = false;
  existingPaymentMethodRequired = false;
  environment: google.payments.api.Environment = 'TEST';
  paymentRequest: GooglePaymentDataRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [],
    merchantInfo: {
      merchantId: '',
      merchantName: '',
    },
    transactionInfo: {
      totalPriceStatus: 'FINAL',
      totalPriceLabel: 'Total',
      totalPrice: '',
      currencyCode: 'NOK',
    },
  };

  constructor(
    private paymentService: PaymentService,
    private cartService: CartService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
  ) {
  }

  async ngOnInit() {
    if (environment.environmentName == 'prod') {
      this.environment = 'PRODUCTION' as google.payments.api.Environment;
    }
    this.buttonLocale = this.translateService.store.currentLang;
    this.session = await this.paymentService.googlePayInitialize(this.storeHandle!);
    const calculatedPrice = this.totalSum! / 100;
    this.amount = calculatedPrice?.toString();
    this.paymentRequest.allowedPaymentMethods = [{
      type: 'CARD',
      parameters: {
        allowedAuthMethods: this.session?.allowedAuthMethods,
        allowedCardNetworks: this.session?.allowedCardNetworks,
      },
      tokenizationSpecification: {
        type: 'PAYMENT_GATEWAY',
        parameters: {
          gatewayMerchantId: this.session?.gatewayMerchantId,
          gateway: this.session?.gateway,
        },
      },
    }];
    this.paymentRequest.merchantInfo = {
      merchantId: this.session?.merchantId,
      merchantName: 'Google Pay',
    };
    this.paymentRequest.transactionInfo = {
      totalPriceStatus: 'FINAL',
      totalPriceLabel: 'Total',
      totalPrice: this.amount!,
      currencyCode: this.session?.currency,
    };
  }

  onLoadPaymentData = (event: CustomEvent<google.payments.api.PaymentData>): void => {
    event.preventDefault();
  };

  onError = async (event: ErrorEvent) => {
    this.isPaymentBusy = false;
    const message = await this.translateService.get('DIALOG.paymentfailed').toPromise();
    alert(message);

    if (event.error.statusCode != 'BUYER_ACCOUNT_ERROR') {
      Bugsnag.notify(new Error(message), notifyEvent => {
        notifyEvent.addMetadata('GooglePay', event.error);
      });
    }

    this.isReadyToPay.emit(false);
  };

  onPaymentDataAuthorized: google.payments.api.PaymentAuthorizedHandler = async paymentData => {
    try {
      this.initializeResponse = await this.paymentService.initialize(this.paymentMethod!, this.cart!);
    } catch (errorResponse) {
      // Must be run async
      PaymentService.errorHandler(errorResponse, {
        orderIsPaid: async () => {
          // Order has already been paid; do a full page reload to recover state
          await this.router.navigate(ForegroundPaths.empty());
          await delay(3000);
          window.location.reload();
        },
      });

      return {
        transactionState: 'ERROR',
        error: {
          reason: 'OFFER_INVALID',
          message: errorResponse.error?.detail ?? 'An error occurred',
          intent: 'OFFER',
        },
      };
    }

    await this.paymentService.googlePayPay(
      this.orderId!,
      this.initializeResponse?.data.transactionId,
      paymentData.paymentMethodData.tokenizationData.token,
      paymentData.paymentMethodData.info!.cardNetwork,
    );
    await this.onPaymentSuccessRoute();
    return {
      transactionState: 'SUCCESS',
    };
  };

  onReadyToPayChange = (event: ReadyToPayChangeResponse): void => {
    this.isReadyToPay.emit(event.isReadyToPay && event.isButtonVisible);
  };

  private async onPaymentSuccessRoute() {
    this.isPaymentBusy = false;
    this.cart = undefined;
    const storeHandle = this.route.parent!.firstChild!.snapshot.paramMap.get('id')!;

    if (this.orderId != undefined) {
      await this.router.navigate(ForegroundPaths.empty());
      await this.router.navigate(
        [`/store/${storeHandle}/receipt/${this.orderId}/transactionid/${this.initializeResponse?.data.transactionId}`],
      );
    }

  }

  preventDoubleClick($event: Event): void {
    if (this.isPaymentBusy) {
      $event.preventDefault();
    }
    this.isPaymentBusy = true;
  }

  cancelGooglePayment(): void {
    this.isPaymentBusy = false;
  }
}
