<div class="root">
  <div class="fygi-logo-page">
    <img class="fygi-logo-image" src="/assets/images/LogoFygi.svg" alt="avatar image" height="36" width="105">
    <div class="fygi-logo-title">
      Technologies
    </div>
    <div class="fygi-shadow-template">
      <img class="fygi-image-glasses" src="/assets/images/avatar.svg" alt="avatar image" height="130" width="125">
      <div class="fygi-text-template">
        <div class="fygi-welcome-text">
          {{ 'FYGIPAGE.welcome' | translate }}
        </div>
        <div class="fygi-message-text">
          {{ 'FYGIPAGE.enterStoreCodeMessage' | translate }}
        </div>
        <div class="fygi-enter-text">
          {{ 'FYGIPAGE.enterStoreCode' | translate }}
        </div>
        <div class="login__input__container">
          <mat-form-field class="login__input" appearance="fill" [style.width.%]=16>
            <input matInput
                   id="1" aria-label="number"
                   type="tel" [(ngModel)]="codeDigitOne"
                   maxlength="1"
                   (input)="storeCode(codeDigitOne!, $event)" (focus)="onFocus($event)">
          </mat-form-field>
          <mat-form-field class="login__input" appearance="fill" [style.width.%]=16>
            <input matInput
                   id="2" aria-label="number"
                   type="tel" [(ngModel)]="codeDigitTwo"
                   maxlength="1"
                   (input)="storeCode(codeDigitTwo!, $event)" (focus)="onFocus($event)">
          </mat-form-field>
          <mat-form-field class="login__input" appearance="fill" [style.width.%]=16>
            <input matInput
                   id="3" aria-label="number"
                   type="tel" [(ngModel)]="codeDigitThree"
                   maxlength="1"
                   (input)="storeCode(codeDigitThree!, $event)" (focus)="onFocus($event)">
          </mat-form-field>
          <mat-form-field class="login__input" appearance="fill" [style.width.%]=16>
            <input matInput
                   id="4" aria-label="number"
                   type="tel" [(ngModel)]="codeDigitFour"
                   maxlength="1"
                   (input)="storeCode(codeDigitFour!, $event)" (focus)="onFocus($event)">
          </mat-form-field>
        </div>
        <button class="fygi-login-button" (click)="storeLogin()">
          {{ 'FYGIPAGE.openStore' | translate }}
        </button>
        <h3 *ngIf="wrongCodeMessage" class="login-title-error">{{ 'LOGIN.wrongCode' | translate }}</h3>
        <h3 *ngIf="wrongDigitMessage" class="login-title-error">{{ 'LOGIN.wrongDigit' | translate }}</h3>
        <div *ngIf="numberNotEntered" class="login__validator">{{ 'LOGIN.codeNotEntered' | translate }}</div>
      </div>
    </div>
  </div>
</div>
