import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AppConfig} from '../app.config';
import {MicroshopShallow} from '../domain/models/microshop/microshop';
import {ProductMicroshop} from '../domain/models/product/product-microshop';
import {CategoryMicroshop} from '../domain/models/category/category';

@Injectable({
  providedIn: 'root',
})
export class MicroshopProvider {
  constructor(private http: HttpClient) {
  }

  getMicroshop(storeHandle: string, microshopHandle: string): Observable<MicroshopShallow> {
    return this.http.get<MicroshopShallow>(
      `${AppConfig.getApiUrl()}/stores/${storeHandle}/microshops/${microshopHandle}`, {
        headers: {'auth-key': AppConfig.authKey},
      });
  }

  getMicroshopCategories(storeHandle: string, microshopHandle: string): Observable<CategoryMicroshop[]> {
    return this.http.get<CategoryMicroshop[]>(
      `${AppConfig.getApiUrl()}/stores/${storeHandle}/microshops/${microshopHandle}/categories`, {
        headers: {'auth-key': AppConfig.authKey},
      });
  }

  getProductsByCategory(storeHandle: string, microshopHandle: string, categoryId: string): Observable<ProductMicroshop[]> {
    return this.http.get<ProductMicroshop[]>(
      `${AppConfig.getApiUrl()}/stores/${storeHandle}/microshops/${microshopHandle}/products?categoryId=${categoryId}`, {
        headers: {'auth-key': AppConfig.authKey},
      });
  }
}
