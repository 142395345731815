import {Component, OnInit} from '@angular/core';
import {CustomerService} from '../../../domain/customer.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {delay} from '../../../utils/promise.utils';

@Component({
  selector: 'app-vipps-auth',
  templateUrl: './vipps-auth.component.html',
  styleUrls: ['./vipps-auth.component.sass'],
})
export class VippsAuthComponent implements OnInit {
  returnPath?: string | null;
  errorMessage = 'Access denied';

  constructor(private customerService: CustomerService,
              private route: ActivatedRoute,
              private router: Router,
              private toastr: ToastrService,
              private translate: TranslateService,
  ) {
  }

  async ngOnInit() {
    this.returnPath = this.route.snapshot.queryParamMap.get('state');
    const code = this.route.snapshot.queryParamMap.get('code');
    const error = this.route.snapshot.queryParamMap.get('error');
    const errorDescription = this.route.snapshot.queryParamMap.get('error_description');

    if (errorDescription) {
      this.errorMessage = decodeURI(errorDescription);
    }

    if (error == 'access_denied') {
      this.toastr.warning(this.errorMessage, undefined, {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'});
      await this.resumeUri();
      return;
    }

    if (code == null) {
      this.toastr.error('Vipps login failed', undefined, {disableTimeOut: true, easeTime: 100, positionClass: 'toast-bottom-center'});
      await this.resumeUri();
      throw new Error('Missing query parameter "code" in Vipps auth URL');
    }

    await this.customerService.customerVippsAuthenticate(code);
    await this.resumeUri();
    await delay(500);
    this.toastr.success(
      await this.translate.get('LOGIN.loggedIn').toPromise(),
      undefined,
      {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'},
    );
  }

  async resumeUri() {
    if (this.returnPath) {
      await this.router.navigateByUrl(this.returnPath, {replaceUrl: true});
    } else {
      await this.router.navigateByUrl('/', {replaceUrl: true});
    }
  }
}
