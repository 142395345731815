import { Injectable } from '@angular/core';
import {LocalStorageService} from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class AgeLimitService {

  constructor(private storageService: LocalStorageService) { }

  addOrderLine(orderLineId: string) {
    let ageLimitLines = this.storageService.retrieve('AGE_LIMIT_ORDER_LINES') as String[] | undefined;
    if(ageLimitLines == undefined)
      ageLimitLines = [];

    ageLimitLines.push(orderLineId);
    this.storageService.store('AGE_LIMIT_ORDER_LINES', ageLimitLines);
  }

  requiresCheck(orderLineId: string) {
    let ageLimitLines = this.storageService.retrieve('AGE_LIMIT_ORDER_LINES') as String[] | undefined;

    if(ageLimitLines == undefined) {
      return false;
    }

    return ageLimitLines.find(t => t == orderLineId) !== undefined
  }

  setCartChecked(orderId: string) {
    let carts = this.storageService.retrieve('AGE_CART_CHECKED') as String[] | undefined;
    if(carts == undefined)
      carts = [];

    carts.push(orderId);
    this.storageService.store('AGE_CART_CHECKED', carts);
  }

  isCartChecked(orderId: string) {
    let carts = this.storageService.retrieve('AGE_CART_CHECKED') as String[] | undefined;

    if(carts == undefined) {
      return false;
    }

    return carts.find(t => t == orderId) !== undefined
  }
}
