import {Injectable} from "@angular/core";
import {DimensionAvailabilityProvider} from "../transport/dimension-availability.provider";
import {DimensionAvailability} from "./models/product/dimension-availability";

@Injectable({
  providedIn: 'root'
})
export class DimensionAvailabilityService {
  constructor(private dimensionAvailabilityProvider: DimensionAvailabilityProvider) {
  }

  async getStock(storeHandle: string, productId: string): Promise<DimensionAvailability[]> {
    return this.dimensionAvailabilityProvider.getStock(storeHandle, productId).toPromise();

  }
}
