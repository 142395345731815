import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppConfig} from '../app.config';
import {CustomerSmsLoginResponse} from './models/customer/customer-sms-login.response';
import {CustomerSmsAuthenticateResponse} from './models/customer/customer-sms-authenticate.response';
import {CustomerAnonymousAuthenticateResponse} from './models/customer/customer-anonymous-authenicate.response';
import {CustomerGoogleAuthenticateResponse} from './models/customer/customer-google-authenticate.response';
import {CustomerVippsInitializeResponse} from './models/customer/customer-vipps-initialize.response';
import {CustomerVippsAuthenticateResponse} from './models/customer/customer-vipps-authenticate.response';
import {LoginMethod} from '../domain/models/login/login-method';

@Injectable({
  providedIn: 'root',
})
export class CustomerProvider {

  constructor(private http: HttpClient) {
  }

  createAnonymousCustomer(): Observable<string> {
    return this.http.post<string>(`${AppConfig.getApiUrl('v1')}/customers/anonymous`,
      {},
      {
        headers: {'accept': 'application/vnd.fygi.v1.id+json'},
      },
    );
  }

  authenticateAnonymousCustomer(accountId: string) {
    return this.http.post<CustomerAnonymousAuthenticateResponse>(`${AppConfig.getApiUrl('v1')}/customers/anonymous/authenticate`,
      {
        accountId,
      },
      {
        headers: {'accept': 'application/vnd.fygi.v1.id+json'},
      },
    );
  }

  customerSmsLogin(phoneNumber: string) {
    return this.http.post<CustomerSmsLoginResponse>(`${AppConfig.getApiUrl('v1')}/customers/sms`,
      {
        phoneNumber,
      },
      {
        headers: {'accept': 'application/vnd.fygi.v1.id+json'},
      },
    );
  }

  customerSmsAuthenticate(phoneNumber: string, smsConfirmationCode: string) {
    return this.http.post<CustomerSmsAuthenticateResponse>(`${AppConfig.getApiUrl('v1')}/customers/sms/authenticate`,
      {
        phoneNumber,
        smsConfirmationCode,
      },
      {
        headers: {'accept': 'application/vnd.fygi.v1.id+json'},
      },
    );
  }

  customerGoogleAuthenticate(googleJwt: string) {
    return this.http.post<CustomerGoogleAuthenticateResponse>(`${AppConfig.getApiUrl('v1')}/customers/google/authenticate`,
      {
        googleJwt,
      },
      {
        headers: {'accept': 'application/vnd.fygi.v1.id+json'},
      },
    );
  }

  customerVippsInitialize(state: string) {
    return this.http.post<CustomerVippsInitializeResponse>(`${AppConfig.getApiUrl('v1')}/customers/vipps/initialize`,
      {
        state,
      },
      {
        headers: {'accept': 'application/vnd.fygi.v1.id+json'},
      },
    );
  }

  customerVippsAuthenticate(code: string) {
    return this.http.post<CustomerVippsAuthenticateResponse>(`${AppConfig.getApiUrl('v1')}/customers/vipps/authenticate`,
      {
        code,
      },
      {
        headers: {'accept': 'application/vnd.fygi.v1.id+json'},
      },
    );
  }

  getLoginMethods(storeHandle: string) {
    return this.http.get<LoginMethod[]>(
      `${AppConfig.getApiUrl()}/stores/${storeHandle}/login-methods`,
      {
        headers: {
          'auth-key': AppConfig.authKey,
        },
      },
    );
  }

}
