<div class="root">
  <ng-container *ngIf="orderLine != null, else loading">
    <div class="order-line product"
         [ngClass]="{'invalid': product?.isWeight && orderLine?.quantity === 0}">
      <ng-container *ngTemplateOutlet="productTemplate"></ng-container>

    </div>
  </ng-container>

</div>

<ng-template #productTemplate>
  <div class="product-line">
    <div class="order-line_product-line" backgroundColor="transparent">
      {{orderLine!.product.name}}
    </div>
  </div>

  <div class="order-line_summary">
    <ng-container>
      <button class="order-line_summary-button" mat-raised-button
              (click)="onQuantityClicked(orderLine!)">{{orderLine!.quantity}}</button>
    </ng-container>
  </div>

</ng-template>

<ng-template #loading>
  <app-gradient-loader></app-gradient-loader>
</ng-template>
