<div *ngFor="let dimension of dimensions">
  <div class="select_product-div">
    <p class="select_product-option">{{dimension.name }}</p>
    <p class="select_product-price">{{dimension.prices[0].amount / 100 + ',-'}}</p>
    <button class="select_product-button" (click)="submit(dimension.id)">{{ 'SELECTPRODUCT.select' | translate}}</button>
  </div>
</div>

<mat-dialog-actions>
  <span class="product_values_dialog_cancel" (click)="close()">
    {{'DIALOG.cancel' | translate }}
  </span>
</mat-dialog-actions>



