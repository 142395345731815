<div class="cash-payment">
  <h2 class="cash-payment__title">
    {{ 'PAYMENT.payWithCash' | translate }}
  </h2>
  <h3 class="cash-payment__order-number">
    <ng-container *ngIf="order else orderShimmer">
      #{{ order?.number }}
    </ng-container>
  </h3>
  <p class="cash-payment__order-number-title">
    {{ 'PAYMENT.yourOrderNumber' | translate }}
  </p>
  <p class="cash-payment__text">
    {{ 'PAYMENT.cashInstructions' | translate }}
  </p>
  <button class="cash-payment__button"
          type="button"
          [disabled]="isBusy"
          [ngStyle]="{background: profile?.primaryColor, color: profile?.primaryTextColor}"
          (click)="commit()">
    {{ 'PAYMENT.cashActionButton' | translate }}
  </button>
  <button class="cash-payment__cancel-button" (click)="cancel()">
    {{ 'PAYMENT.back' | translate }}
  </button>
</div>

<ng-template #orderShimmer>
  <app-gradient-loader></app-gradient-loader>
</ng-template>
