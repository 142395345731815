<div *ngIf="isLoading" class="loading" (click)="$event.preventDefault(); $event.stopPropagation()">
  <div class="loading__box">
    <div class="loading__animation">
      <ng-lottie
        (animationCreated)="$event.setSpeed(3)"
        [options]="{
          path: './assets/animations/fygi_logo_animation.json',
          renderer: 'svg',
          autoplay: true,
          loop: true
        }">
      </ng-lottie>
    </div>
    <h4 class="loading__text">{{ 'STORE.themeWaiting' | translate}}</h4>
  </div>
</div>
