import {Component, OnDestroy, OnInit} from '@angular/core';
import {CodeType, ScannerService} from '../../../domain/scanner.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {StrongPointScale} from '../../../domain/scales/strongpoint.scale';
import {AppComponent} from '../../../app.component';
import {Subscription} from 'rxjs';
import {CartService} from '../../../domain/cart.service';
import {Product} from '../../../domain/models/product/product';
import {OrderLineType} from '../../../domain/models/order/order-line';
import {ProductService} from '../../../domain/product.service';
import {ForegroundPaths} from '../../../app-routing.module';
import {ToastrService} from 'ngx-toastr';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-scan-weight-for-orderline',
  templateUrl: './scan-weight-for-orderline.component.html',
  styleUrls: ['./scan-weight-for-orderline.component.sass']
})
export class ScanWeightForOrderLineComponent implements OnInit, OnDestroy {

  private readonly orderLineId: string;
  private readonly storeHandle: string;

  private scannerSubscription?: Subscription;

  product?: Product;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private scannerService: ScannerService,
              private cartService: CartService,
              private toastr: ToastrService,
              private productService: ProductService,
              private translateService: TranslateService) {
    this.orderLineId = this.route.snapshot.paramMap.get('orderLineId')!;
    this.storeHandle = this.route.parent?.firstChild?.snapshot.paramMap.get('id')!;
  }

  async ngOnInit() {
    this.scannerService.scanner?.decoderInitialized
      .pipe(first(state => state, 1))
      .subscribe(state => state && this.startScan());

    const cart = await this.cartService.getOrCreateLocalCart(this.storeHandle);
    const orderLine = cart.orderLines.find(o => o.id === this.orderLineId);
    if (orderLine && orderLine.type === OrderLineType.Product) {
      this.product = await this.productService.getProductById(this.storeHandle, orderLine.product.id);
    }
  }

  async startScan() {
    const tutorialText = await this.translateService.get('SCANNER.SCANPRODUCTS.weight').toPromise();
    this.scannerSubscription?.unsubscribe();
    this.scannerSubscription = this.scannerService
      .onScanResult([CodeType.Code128, CodeType.EAN8, CodeType.EAN13, CodeType.QR], tutorialText)
      .subscribe(async result => {
        this.scannerSubscription?.unsubscribe();
        if (result.symbologyName === CodeType.QR && StrongPointScale.isValidInput(result.barcodeData)) {
          const weight = StrongPointScale.parseWeightInGrams(result.barcodeData);
          await this.cartService.setWeight(this.orderLineId, weight, this.storeHandle);
          await this.router.navigate(ForegroundPaths.cart());
          return;
        } else if (result.symbologyName === CodeType.EAN13 && result.barcodeData.includes('2200012')) {
          const price = this.parsePluWeightPrice(result.barcodeData);
          const newPrice = Number(price);
          await this.cartService.setPLUWeight(this.orderLineId, this.storeHandle, newPrice)
          await this.router.navigate(ForegroundPaths.cart());
          return;
        }
        if (!AppComponent.isForegroundOpen) {
          const message = await this.translateService.get('SCANNER.ERROR.weight').toPromise();
          this.toastr.warning(message, undefined, {
            timeOut: 3000,
            easeTime: 100,
            positionClass: 'toast-bottom-center'
          });
          await this.startScan();
        }
      });
  }

  ngOnDestroy(): void {
    this.scannerSubscription?.unsubscribe();
  }

  private parsePluWeightPrice(barcodeData: string) {
    return barcodeData.slice(7, 12);
  }

}
