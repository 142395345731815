<div class="invalid-microshop-dialog">
  <div>
    <img class="invalid-microshop-dialog-image" src="/assets/images/store-invalid.svg" alt="Not found" height="100" width="100">
  </div>
  <div class="invalid-microshop-dialog-text">{{ 'STORE.MICROSHOP.invalid' | translate }}</div>
  <button class="invalid-microshop-button"
          [ngStyle]="{backgroundColor: store?.storeProfile?.primaryColor, color: store?.storeProfile?.primaryTextColor}"
          (click)="goBack()">
    {{ 'STORE.MICROSHOP.goBack' | translate }}
  </button>
</div>
