import { Directive, Input, ViewContainerRef, OnDestroy, AfterContentInit } from '@angular/core';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil, auditTime } from 'rxjs/operators';

@Directive({
  selector: '[scrollState]',
})
export class ScrollShadowDirective implements OnDestroy, AfterContentInit {
  @Input() canScrollUpClass = 'can-scroll-up';
  @Input() canScrollDownClass = 'can-scroll-down';
  @Input() auditTimeMs = 125;

  private readonly destroy$: Subject<void> = new Subject();
  private readonly hostElement: HTMLElement;

  constructor(readonly vcRef: ViewContainerRef) {
    this.hostElement = vcRef.element.nativeElement;

    fromEvent(this.hostElement, 'scroll').pipe(
      auditTime(this.auditTimeMs),
      takeUntil(this.destroy$),
    ).subscribe(() => {
      this.setClasses();
    });
  }

  public ngAfterContentInit(): void {
    this.setClasses();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setClasses() {
    const canScrollUp = this.hostElement.scrollTop > 0;
    const canScrollDown = this.hostElement.scrollTop + this.hostElement.clientHeight < this.hostElement.scrollHeight;
    this.hostElement.classList.remove(this.canScrollDownClass);
    this.hostElement.classList.remove(this.canScrollUpClass);

    if (this.canScrollUpClass && canScrollUp) {
      this.hostElement.classList.add(this.canScrollUpClass);
    }

    if (this.canScrollDownClass && canScrollDown) {
      this.hostElement.classList.add(this.canScrollDownClass);
    }
  }
}
