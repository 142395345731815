<div class="root">
  <div class="payment-failed-message">
    {{ 'DIALOG.paymentfailed' | translate }}
  </div>
  <button class="payment-failed-button"
          [ngStyle]="{backgroundColor: profile?.primaryColor, color: profile?.primaryTextColor}"
          (click)="closeForeground()">
    {{ 'DIALOG.paymenttryagain' | translate }}
  </button>
</div>
