import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {AppConfig} from "../app.config";
import {Store, StoreShallow} from "../domain/models/store/store";
import {map} from "rxjs/operators";
import {Page, Paginated} from "./models/paginated";

@Injectable({
  providedIn: 'root'
})
export class StoreProvider {

  constructor(private http: HttpClient) {
  }

  getStores(page?: Page): Observable<Paginated<StoreShallow>> {
    return this.http.get<StoreShallow[]>(`${AppConfig.getApiUrl()}/stores`, {
      headers: {'auth-key': AppConfig.authKey},
      params: page?.toParams(),
      observe: 'response',
    }).pipe(map(r => Paginated.fromResponse<StoreShallow>(r)));
  }

  getStore(handle: string): Observable<Store> {
    return this.http.get<Store>(`${AppConfig.getApiUrl()}/stores/${handle}`, {
      headers: {'auth-key': AppConfig.authKey}
    }).pipe(map(r => {
      r.fulfillmentOptions.push(...r.storeChain.fulfillmentOptions);
      return r;
    }));
  }

  getChainStores(chainHandle: string) {
    return this.http.get<StoreShallow[]>(`${AppConfig.getApiUrl()}/stores/chain/${chainHandle}`, {
      headers: {'auth-key': AppConfig.authKey},
    });
  }

  getStoreByNumber(shortNumber: string): Observable<Store> {
    return this.http.get<Store>(
      `${AppConfig.getApiUrl()}/stores/short`,
      {
        headers: {
          'auth-key': AppConfig.authKey,
        },
        params: {shortNumber},
      },
    );
  }
}

