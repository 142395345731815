import {Component, OnDestroy, OnInit} from '@angular/core';
import {StoreProfile} from '../../../domain/models/store/store-profile';
import {ActivatedRoute, Event, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {StoreService} from '../../../domain/store.service';
import {Subscription} from 'rxjs';
import {Cart, OrderLine} from '../../../domain/models/order/cart';
import {CartService} from '../../../domain/cart.service';
import {PriceUtils} from '../../../utils/price.utils';
import {ForegroundPaths} from '../../../app-routing.module';
import {ProductOrderLine} from '../../../domain/models/order/order-line';
import {Store} from '../../../domain/models/store/store';
import {AmountsheetDialog} from '../../../dialogs/actionsheet/amountsheet/amountsheet.dialog';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {OfflineProductOrderLine} from '../../../domain/models/order/offline-product-order-line';
import {OfflineCartService} from '../../../domain/offline-cart.service';
import {OfflineCart} from '../../../domain/models/order/offline-cart';
import {PaymentService} from '../../../domain/payment.service';
import {PaymentMethod} from '../../../domain/models/payment/payment-method';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.sass']
})
export class CartComponent implements OnInit, OnDestroy {
  cart?: Cart;
  offlineCart: OfflineCart | null = null;
  store?: Store;
  profile?: StoreProfile;
  addedOrderLine?: OrderLine;
  addedOfflineOrderLine?: OfflineProductOrderLine;
  currencyCode?: string;
  cultureName?: string;
  cartCount?: string;
  cartSum?: string | null;
  directPayment?: PaymentMethod;

  isBrowse = false;
  isCartBusy = false;
  isPaymentEnabled = false;

  private readonly SHOW_PRODUCT_TIMEOUT_MILLISECONDS = 4000;

  private pageChangeSubscription?: Subscription;
  private cartChangeSubscription?: Subscription;
  private offlineCartChangeSubscription?: Subscription;
  private cartProductAddedSubscription?: Subscription;
  private offlineCartProductAddedSubscription?: Subscription;
  private addedOrderLineTimeoutId?: number;
  private addedOfflineOrderLineTimeoutId?: number;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private storeService: StoreService,
              private cartService: CartService,
              private offlineCartService: OfflineCartService,
              private bottomSheet: MatBottomSheet,
              private paymentService: PaymentService,
              private toastr: ToastrService,
              private translateService: TranslateService,
  ) {
  }

  async ngOnInit() {
    this.pageChangeSubscription = this.router.events
      .pipe(filter((event: Event) => event instanceof NavigationEnd))
      .subscribe(async (_) => {
        await this.init();
      });
    await this.init();
  }

  ngOnDestroy(): void {
    this.pageChangeSubscription?.unsubscribe();
    this.cartChangeSubscription?.unsubscribe();
    this.cartProductAddedSubscription?.unsubscribe();
  }

  async init() {
    if (!this.router.url.includes('/store/')) {
      return;
    }

    const storeHandle = this.route.parent!.firstChild!.snapshot.paramMap.get('id')!;
    this.isBrowse = this.router.url.includes('/browse');

    if (storeHandle != null) {
      this.store = await this.storeService.getStore(storeHandle);
      this.profile = this.store.storeProfile;

      const directPaymentOption = await this.paymentService.getDirectPaymentOption(this.store.id);
      if (directPaymentOption) {
        this.directPayment = directPaymentOption;
      }

      this.currencyCode = this.store?.currencyCode;
      this.cultureName = this.store?.cultureName;
    }

    this.cartChangeSubscription?.unsubscribe();
    this.cartChangeSubscription = this.cartService
      .onCartChanges(storeHandle)
      .subscribe(cart => {
        this.cart = cart;
        this.updateCartInfo();
      });

    this.cartProductAddedSubscription?.unsubscribe();
    this.cartProductAddedSubscription = this.cartService
      .onProductAdded(storeHandle)
      .subscribe(orderLine => {
        this.addedOrderLine = undefined;
        this.addedOrderLine = orderLine;
        this.startTimeout(this.addedOrderLine.name);
      });

    this.offlineCartProductAddedSubscription?.unsubscribe();

    this.offlineCartProductAddedSubscription = this.offlineCartService
      .onProductAdded(storeHandle)
      .subscribe(orderLine => {
        this.addedOfflineOrderLine = undefined;
        this.addedOfflineOrderLine = orderLine;
        this.startOfflineTimeout(orderLine.product.name);
      });

    this.offlineCartChangeSubscription?.unsubscribe();
    this.offlineCartChangeSubscription = this.offlineCartService
      .onCartChanges(storeHandle)
      .subscribe(cart => {
        this.offlineCart = cart;
        this.updateCartInfo();
      });

    if (storeHandle != null) {
      this.offlineCart = await this.offlineCartService.getCart(storeHandle);
      this.cart = await this.cartService.getOrCreateLocalCart(storeHandle);
      this.updateCartInfo();
    }
  }

  private startOfflineTimeout(orderLineName: string) {
    if (this.addedOfflineOrderLineTimeoutId != null) {
      clearTimeout(this.addedOfflineOrderLineTimeoutId);
    }
    this.addedOfflineOrderLineTimeoutId = setTimeout(() => {
      this.addedOfflineOrderLineTimeoutId = undefined;
      if (this.addedOfflineOrderLine?.product.name == orderLineName) {
        this.addedOfflineOrderLine = undefined;
      }
    }, this.SHOW_PRODUCT_TIMEOUT_MILLISECONDS);
  }

  private startTimeout(orderLineName: string) {
    if (this.addedOrderLineTimeoutId != null) {
      clearTimeout(this.addedOrderLineTimeoutId);
    }
    this.addedOrderLineTimeoutId = setTimeout(() => {
      this.addedOrderLineTimeoutId = undefined;
      if (this.addedOrderLine?.name == orderLineName) {
        this.addedOrderLine = undefined;
      }
    }, this.SHOW_PRODUCT_TIMEOUT_MILLISECONDS);
  }


  toPrice(price: number): string {
    return PriceUtils.toPrice(price);
  }

  async openPayment() {
    if (this.cart && await this.cartService.getCartCount(this.cart) !== '0') {
      await this.router.navigate(ForegroundPaths.cartPayment());
    } else {
      this.toastr.warning(
        await this.translateService.get('CART.empty').toPromise(),
        undefined,
        {timeOut: 3000, easeTime: 100, positionClass: 'toast-top-center'},
      );
    }
  }

  hasOrderLines(): boolean {
    if (this.cart && this.cart.orderLines.length > 0) {
      return true;
    }

    if (this.offlineCart && this.offlineCart.orderLines.length > 0) {
      return true;
    }

    return false;
  }

  async openCart() {
    await this.router.navigate(ForegroundPaths.cart());
  }


  async openOfflineBottomSheet(orderLine: OfflineProductOrderLine) {
    const result = await this.bottomSheet.open(AmountsheetDialog, {
      data: orderLine.quantity
    }).afterDismissed().toPromise();
    if (result == null || result ! instanceof Number) {
      return;
    }
    const quantity = result as Number;
    if (this.store?.handle != null) {
      if (quantity === 0) {
        await this.offlineCartService.remove(this.store.handle, orderLine);
      }
      if (quantity > 0) {
        await this.offlineCartService.setCount(this.store.handle, orderLine, result);
      }
    }
    return;
  }

  async openBottomSheet(orderLine: ProductOrderLine) {
    const result = await this.bottomSheet.open(AmountsheetDialog, {
      data: orderLine.quantity
    }).afterDismissed().toPromise();
    if (result == null || result ! instanceof Number) {
      return;
    }
    const quantity = result as Number;
    if (this.store?.handle != null) {
      if (quantity === 0) {
        await this.cartService.remove(this.store.handle, orderLine);
      }
      if (quantity > 0) {
        await this.cartService.setCount(this.store.handle, orderLine, result);
      }
    }
    return;
  }

  private async updateCartInfo() {
    if (this.cart) {
      this.isPaymentEnabled = !!(this.cart.orderId && this.cart.orderLines.length > 0);
      this.cartCount = await this.cartService.getCartCount(this.cart);
      this.cartSum = this.offlineCart != null || this.cart == null ? '-' : this.toPrice(this.cart.sum);
    } else {
      this.isPaymentEnabled = false;
    }
  }
}
