<div class="ean-input-page-close">
  <mat-icon (click)="close()">
    close
  </mat-icon>
</div>
<div class="ean-input-dialog-title">{{ 'DIALOG.EAN.add' | translate }}</div>
<div class="ean-input-dialog-product">{{ 'DIALOG.EAN.product' | translate }}</div>
<mat-form-field appearance="fill">
  <input [(ngModel)]="inputText" matInput placeholder="{{ 'DIALOG.EAN.title' | translate }}">
</mat-form-field>
<div class="ean-input-add-button"
     [ngStyle]="{backgroundColor: profile?.primaryColor, color: profile?.primaryTextColor}"
     (click)="close(inputText)">
  {{ 'PRODUCTDETAILS.addToCart' | translate }}
</div>
<h4 class="ean-input-dialog_action ean-input-dialog_cancel" (click)="close()">
  {{ 'DIALOG.cancel' | translate }}
</h4>
