import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {ShippingAddress} from '../../../domain/models/order/shipping-address';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-shipmentsheet',
  templateUrl: './shipmentsheet.dialog.html',
  styleUrls: ['./shipmentsheet.dialog.sass']
})
export class ShipmentsheetDialog {

  @Output() shipmentAddress = new EventEmitter<ShippingAddress>();

  constructor(private bottomSheetRef: MatBottomSheetRef<ShipmentsheetDialog>,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data?: any) {

  }

  onAddressSubmitted(address: ShippingAddress) {
    this.bottomSheetRef.dismiss(address);
  }

  close() {
    this.bottomSheetRef.dismiss();
  }
}
