<div class="root">
  <video #video class="video" autoplay playsinline (loadstart)="onVideoLoadStart($event)"></video>
  <div class="scanner_overlay">
    <h4 class="scanner_tutorial" *ngIf="tutorialText">
      {{ tutorialText }}
    </h4>
    <div class="anchor_overlay" *ngIf="defaultRect" [ngClass]="{'inActive': !isActive}">
      <div
        class="tag topLeft"
        [style]="{
           top: (target?.TopLeft?.Y ?? defaultRect.TopLeft.Y) + 'px',
           left: (target?.TopLeft?.X ?? defaultRect.TopLeft.X) + 'px'
         }">
        <div class="line horizontal" [style]="(targetIntent && targetIntent.percent > 0.3) ? {
            width: 0.75 * ((targetIntent.percent - 0.3) / 0.7) * getIntentWidth(targetIntent) + 'px'
           } : {}">
        </div>
        <div class="line vertical" [style]="(targetIntent && targetIntent.percent > 0.3) ? {
            height: 0.75 * ((targetIntent.percent - 0.3) / 0.7) * getIntentHeight(targetIntent) + 'px'
           } : {}">
        </div>
        <svg xmlns="http://www.w3.org/2000/svg"
             width="128" height="128"
             viewBox="0 0 64 64">
          <g id="Group_630" data-name="Group 630" transform="translate(-238 -110)">
            <path id="Path_150" data-name="Path 150" d="M242,169.632V147.106A33.107,33.107,0,0,1,275.106,114h22.525"
                  transform="translate(0 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10"
                  stroke-width="8"/>
          </g>
          <g id="Group_630" data-name="Group 630" transform="translate(-238 -110)">
            <path id="Path_150" data-name="Path 150" d="M242,169.632V147.106A33.107,33.107,0,0,1,275.106,114h22.525"
                  transform="translate(0 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10"
                  stroke-width="8"/>
          </g>
        </svg>
      </div>
      <div
        class="tag topRight"
        [style]="defaultRect && {
           top: (target?.TopRight?.Y ?? defaultRect.TopRight.Y) + 'px',
           left: (target?.TopRight?.X ?? defaultRect.TopRight.X) + 'px'
         }">
        <div class="line horizontal" [style]="(targetIntent && targetIntent.percent > 0.3) ? {
            width: 0.75 * ((targetIntent.percent - 0.3) / 0.7) * getIntentWidth(targetIntent) + 'px'
           } : {}">
        </div>
        <div class="line vertical" [style]="(targetIntent && targetIntent.percent > 0.3) ? {
            height: 0.75 * ((targetIntent.percent - 0.3) / 0.7) * getIntentHeight(targetIntent) + 'px'
           } : {}">
        </div>
        <svg xmlns="http://www.w3.org/2000/svg"
             width="64" height="64"
             viewBox="0 0 64 64">
          <g id="Group_630" data-name="Group 630" transform="translate(-238 -110)">
            <path id="Path_150" data-name="Path 150" d="M242,169.632V147.106A33.107,33.107,0,0,1,275.106,114h22.525"
                  transform="translate(0 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10"
                  stroke-width="8"/>
          </g>
        </svg>

      </div>

      <div
        class="tag bottomLeft"
        [style]="defaultRect && {
           top: (target?.BottomLeft?.Y ?? defaultRect.BottomLeft.Y) + 'px',
           left: (target?.BottomLeft?.X ?? defaultRect.BottomLeft.X) + 'px'
         }">
        <div class="line horizontal" [style]="(targetIntent && targetIntent.percent > 0.3) ? {
            width: 0.75 * ((targetIntent.percent - 0.3) / 0.7) * getIntentWidth(targetIntent) + 'px'
           } : {}">
        </div>
        <div class="line vertical" [style]="(targetIntent && targetIntent.percent > 0.3) ? {
            height: 0.75 * ((targetIntent.percent - 0.3) / 0.7) * getIntentHeight(targetIntent) + 'px'
           } : {}">
        </div>
        <svg xmlns="http://www.w3.org/2000/svg"
             width="64" height="64"
             viewBox="0 0 64 64">
          <g id="Group_630" data-name="Group 630" transform="translate(-238 -110)">
            <path id="Path_150" data-name="Path 150" d="M242,169.632V147.106A33.107,33.107,0,0,1,275.106,114h22.525"
                  transform="translate(0 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10"
                  stroke-width="8"/>
          </g>
        </svg>

      </div>

      <div
        class="tag bottomRight"
        [style]="defaultRect && {
           top: (target?.BottomRight?.Y ?? defaultRect.BottomRight.Y) + 'px',
           left: (target?.BottomRight?.X ?? defaultRect.BottomRight.X) + 'px'
         }">
        <div class="line horizontal" [style]="(targetIntent && targetIntent.percent > 0.3) ? {
            width: 0.75 * ((targetIntent.percent - 0.3) / 0.7) * getIntentWidth(targetIntent) + 'px'
           } : {}">
        </div>
        <div class="line vertical" [style]="(targetIntent && targetIntent.percent > 0.3) ? {
            height: 0.75 * ((targetIntent.percent - 0.3) / 0.7) * getIntentHeight(targetIntent) + 'px'
           } : {}">
        </div>
        <svg xmlns="http://www.w3.org/2000/svg"
             width="64" height="64"
             viewBox="0 0 64 64">
          <g id="Group_630" data-name="Group 630" transform="translate(-238 -110)">
            <path id="Path_150" data-name="Path 150" d="M242,169.632V147.106A33.107,33.107,0,0,1,275.106,114h22.525"
                  transform="translate(0 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10"
                  stroke-width="8"/>
          </g>
        </svg>
      </div>
    </div>
  </div>
  <div class="anchor_overlay">
    <ng-container *ngIf="target && showAnchors">
      <div class="anchor topLeft" [style]="{top: target.TopLeft.Y+'px', left: target.TopLeft.X+'px'}"></div>
      <div class="anchor topRight" [style]="{top: target.TopRight.Y+'px', left: target.TopRight.X+'px'}"></div>
      <div class="anchor bottomLeft" [style]="{top: target.BottomLeft.Y+'px', left: target.BottomLeft.X+'px'}"></div>
      <div class="anchor bottomRight" [style]="{top: target.BottomRight.Y+'px', left: target.BottomRight.X+'px'}"></div>
      <div class="anchor" [style]="{top: center?.Y+'px', left: center?.X+'px'}"></div>
    </ng-container>
  </div>
  <div class="camera-permission" *ngIf="!initialized && !initializing && isActive">
    <h3>{{ 'CAMERA.title' |  translate}}</h3>
    <h4>{{ 'CAMERA.message' |  translate}}</h4>
    <h5>{{ 'CAMERA.todo' |  translate}}</h5>
  </div>
  <div class="scanner-error" *ngIf="!initialized && !initializing && !decoderInitialized.value">
    <h3>Scanner failed</h3>
    <h4>Please try to refresh the page</h4>
  </div>
</div>


