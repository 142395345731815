import {Injectable} from '@angular/core';
import {StoreService} from './store.service';
import {Product} from './models/product/product';
import {Observable, Subject} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {LocalStorageService} from 'ngx-webstorage';
import {Dimension} from './models/product/dimension';
import {environment} from '../../environments/environment';
import {ProductSummary} from './models/product/product-summary';
import {ProductDimensionSummary} from './models/product/product-dimension-summary';
import {OfflineCart} from './models/order/offline-cart';
import {OfflineProductOrderLine} from './models/order/offline-product-order-line';

@Injectable({
  providedIn: 'root'
})
export class OfflineCartService {

  constructor(private storeService: StoreService,
              private storageService: LocalStorageService,
  ) {
  }

  private carts: Map<string, OfflineCart> = new Map<string, OfflineCart>();
  private cartChange: Subject<{ key: string, cart: OfflineCart | null }> = new Subject();
  private productAdded: Subject<{ key: string, orderLine: OfflineProductOrderLine }> = new Subject();

  onCartChanges(storeHandle: string): Observable<OfflineCart | null> {
    return this.cartChange
      .pipe(
        filter(value => value.key === storeHandle),
        map(value => value.cart)
      );
  }

  onProductAdded(storeHandle: string): Observable<OfflineProductOrderLine> {
    return this.productAdded
      .pipe(
        filter(value => value.key === storeHandle),
        map(value => value.orderLine),
      );
  }

  getCart(storeHandle: string): OfflineCart | null {
    const memoryCart = this.carts.get(storeHandle);
    if (memoryCart != null) {
      return memoryCart;
    }

    const storageCart = this.storageService.retrieve(`cart_offline_${storeHandle}_${environment.apiUrl}`) as OfflineCart | undefined;
    if (storageCart != null && (!storageCart.updatedAt || Date.now() - storageCart.updatedAt < 86400000)) {
      this.carts.set(storeHandle, storageCart);
      return storageCart;
    }
    return null;
  }

  async clearCart(storeHandle: string) {
    this.carts.delete(storeHandle);
    this.storageService.clear(`cart_offline_${storeHandle}_${environment.apiUrl}`);
    this.cartChange.next({key: storeHandle, cart: null});
  }

  async remove(storeHandle: string, orderLine: OfflineProductOrderLine) {
    const cart = await this.getCart(storeHandle);
    let existing = this.getOrderLineInCart(cart!, orderLine.dimension.id);
    const index = cart!.orderLines.indexOf(existing!);
    if (index >= 0) {
      cart!.orderLines.splice(index, 1);
      await this.updateCart(storeHandle, cart!);
    }
  }

  private getProductOrderLine(cart: OfflineCart, dimensionId: string): OfflineProductOrderLine | undefined {
    return cart.orderLines
      .map(line => line as OfflineProductOrderLine)
      .find(o => o.dimension.id === dimensionId);
  }

  private getOrderLineInCart(cart: OfflineCart, dimensionId: string): OfflineProductOrderLine | undefined {
    return cart.orderLines.find(o => o.dimension.id === dimensionId);
  }

  async getItemCount(storeHandle: string, product: Product, dimension: Dimension) {
    const cart = await this.getCart(storeHandle);
    let orderLine = await this.getProductOrderLine(cart!, dimension.id);
    if (orderLine !== undefined) {
      return orderLine!.quantity;
    }
    return 0;
  }

  async setCount(storeHandle: string, orderLine: OfflineProductOrderLine, quantity: number): Promise<OfflineProductOrderLine | undefined> {
    const cart = await this.getCart(storeHandle);
    let existing = this.getOrderLineInCart(cart!, orderLine!.dimension.id);

    if (existing == null) {
      console.error(`Order line ${orderLine.dimension.id} does not exist in cart`);
      return undefined;
    }
    existing.quantity = quantity;
    await this.updateCart(storeHandle, cart!);
    return existing;
  }

  async addOfflineProduct(storeHandle: string, product: ProductSummary, dimension: ProductDimensionSummary) {
    let cart = this.getCart(storeHandle);

    if (cart == undefined) {
      const store = await this.storeService.getStore(storeHandle);
      cart = new OfflineCart(store.id);
      this.carts.set(storeHandle, cart);
    }

    let currentOrderLine = cart.orderLines.find(o => o.dimension.id == dimension.id);

    if (currentOrderLine != undefined) {
      currentOrderLine.quantity++;
    } else {
      currentOrderLine = new class implements OfflineProductOrderLine {
        product = product;
        dimension = dimension;
        isWeight = product.isWeight;
        quantity = 1;
      };
      cart.orderLines.push(currentOrderLine!);
    }

    await this.updateCart(storeHandle, cart);
    this.cartChange.next({key: storeHandle, cart: cart});
    this.productAdded.next({key: storeHandle, orderLine: currentOrderLine});
  }

  private async updateCart(storeHandle: string, cart: OfflineCart) {
    cart.updatedAt = Date.now();
    await this.storageService.store(`cart_offline_${storeHandle}_${environment.apiUrl}`, cart);
    this.carts.set(storeHandle, cart);
  }
}
