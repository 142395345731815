import {Component, EventEmitter, Input, Output, Renderer2} from '@angular/core';
import {ApplePaySessionRespond} from '../../transport/models/payment/apple-pay-session.respond';
import {PaymentService} from '../../domain/payment.service';
import {PaymentIdentifier, PaymentMethod} from '../../domain/models/payment/payment-method';
import {Cart} from '../../domain/models/order/cart';
import {InitializePaymentResponse} from '../../transport/models/payment/initialize-payment.response';
import {ForegroundPaths} from '../../app-routing.module';
import {CartService} from '../../domain/cart.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {LoadJsFilesService} from '../../domain/load-js-files.service';
import {TokenData} from '../../domain/models/payment/payment-apple-pay-token-data';
import {delay} from '../../utils/promise.utils';
import {OrderService} from '../../domain/order.service';

@Component({
  selector: 'app-apple-pay',
  templateUrl: './apple-pay.component.html',
  styleUrls: ['./apple-pay.component.sass'],
})
export class ApplePayComponent {
  @Input() storeHandle: string | undefined;
  @Input() totalSum: number | undefined;
  @Input() orderId: string | undefined;
  @Input() cart?: Cart;
  @Input() paymentMethod: PaymentMethod | undefined;
  @Output() paymentIdentifier?: PaymentIdentifier;
  @Output() isReadyToPay = new EventEmitter<boolean>();
  session?: ApplePaySessionRespond;
  sessionInstance?: ApplePaySession;
  price?: number;
  initializeResponse?: InitializePaymentResponse;
  cartText?: string;
  cancelText?: string;
  readonly applePayJsFiles = 'https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js';
  browserLanguage?: string;
  isPaymentBusy = false;

  constructor(
    private renderer: Renderer2,
    private paymentService: PaymentService,
    private router: Router,
    private cartService: CartService,
    private orderService: OrderService,
    private loadJsFilesService: LoadJsFilesService,
    private translateService: TranslateService,
    private toastr: ToastrService,
  ) {
  }

  async ngOnInit() {
    this.browserLanguage = navigator.language;
    this.session = await this.paymentService.applePayInitialize(this.storeHandle!);
    this.cartText = await this.translateService.get('CART.payNow').toPromise();
    this.cancelText = await this.translateService.get('DIALOG.paymentfailed').toPromise();
    this.isReadyToPay.emit((window as any).ApplePaySession?.canMakePayments());
    this.loadJsFilesService.loadJsScript(this.renderer, this.applePayJsFiles).catch(() => {
      throw new Error('Apple Pay library not loaded');
    });
  }

  async initializePayment() {
    try {
      this.initializeResponse = await this.paymentService.initialize(this.paymentMethod!, this.cart!);
    } catch(errorResponse) {
      this.sessionInstance?.abort();
      // Must be run async
      PaymentService.errorHandler(errorResponse, {
        orderIsPaid: async () => {
          // Order has already been paid; do a full page reload to recover state
          await this.router.navigate(ForegroundPaths.empty());
          await delay(3000);
          window.location.reload();
        },
      });

      throw errorResponse;
    }
  }

  async validateMerchant(validationURL: string) {
    await this.initializePayment();
    return await this.paymentService.applePayValidateMerchant(this.orderId!, validationURL);
  }

  async onApplePayClick() {
    if (this.isPaymentBusy) {
      return;
    }
    this.isPaymentBusy = true;
    const request = {
      'countryCode': this.session?.countryCode,
      'currencyCode': this.session?.currency,
      'merchantCapabilities': this.session?.merchantCapabilities,
      'supportedNetworks': this.session?.supportedNetworks,
      'total': {
        'label': this.cartText,
        'type': 'final',
        'amount': (this.totalSum! / 100).toString(),
      },
    } as ApplePayJS.ApplePayPaymentRequest;

     this.sessionInstance = new (window as any).ApplePaySession(3, request) as ApplePaySession;

    this.sessionInstance.onvalidatemerchant = async (event: any) => {
      const merchantSession = await this.validateMerchant(event.validationURL);
      this.sessionInstance?.completeMerchantValidation(merchantSession);
    };

    this.sessionInstance.onpaymentmethodselected = (event: any) => {
      const update = {
        'newTotal': {
          'label': this.cartText,
          'amount': (this.totalSum! / 100).toString(),
          'type': event.amount,
          'paymentTiming': event.paymentTiming,
          'recurringPaymentStartDate': event.recurringPaymentStartDate,
          'recurringPaymentIntervalUnit': event.recurringPaymentIntervalUnit,
          'recurringPaymentIntervalCount': event.recurringPaymentIntervalCount,
          'recurringPaymentEndDate': event.recurringPaymentEndDate,
          'deferredPaymentDate': event.deferredPaymentDate,
        },
      } as ApplePayJS.ApplePayPaymentMethodUpdate;

      this.sessionInstance?.completePaymentMethodSelection(update);
    };

    this.sessionInstance.onpaymentauthorized = async (event: any) => {
      const tokenData: TokenData = {
        'version': event.payment.token.paymentData.version,
        'data': event.payment.token.paymentData.data,
        'signature': event.payment.token.paymentData.signature,
        'transactionIdentifier': event.payment.token.transactionIdentifier,
        'header': {
          'ephemeralPublicKey': event.payment.token.paymentData.header.ephemeralPublicKey,
          'publicKeyHash': event.payment.token.paymentData.header.publicKeyHash,
          'transactionId': event.payment.token.paymentData.header.transactionId,
        },
      };

      await this.paymentService.applePayPay(
        this.orderId!,
        this.initializeResponse?.data.transactionId,
        tokenData,
        event.payment.token.paymentMethod.network,
      );

      const result = {
        'status': (window as any).ApplePaySession.STATUS_SUCCESS,
      } as ApplePayJS.ApplePayPaymentAuthorizationResult;

      this.sessionInstance?.completePayment(result);
      return this.onPaymentSuccessRoute();
    };

    this.sessionInstance.oncancel = (event: any) => {
      this.isPaymentBusy = false;
      this.toastr.error(this.cancelText, undefined, {
        timeOut: 3000,
        easeTime: 100,
        positionClass: 'toast-bottom-center',
      });
    };

    this.sessionInstance.begin();
  }

  private async onPaymentSuccessRoute() {
    this.isPaymentBusy = false;
    this.cart = undefined;
    this.cartService.clearCart(this.storeHandle!);
    this.orderService.clearOrdersInspected();

    if (this.orderId != undefined) {
      await this.router.navigate(ForegroundPaths.empty());
      await this.router.navigate(
        [`/store/${this.storeHandle}/receipt/${this.orderId}/transactionid/${this.initializeResponse?.data.transactionId}`],
      );
    }

  }
}
