import {OfflineProductOrderLine} from './offline-product-order-line';

export class OfflineCart {
  orderLines: Array<OfflineProductOrderLine> = [];
  updatedAt?: number;

  constructor(
    readonly storeId: string,
  ) {
    this.updatedAt = Date.now();
  }
}
