import {Injectable} from '@angular/core';
import {MicroshopProvider} from '../transport/microshop.provider';
import {Microshop} from './models/microshop/microshop';
import {map} from 'rxjs/operators';
import {Cache} from '../utils/cache.utils';
import {sortByIndexAscending} from '../utils/sorting.utils';
import {ProductMicroshop} from './models/product/product-microshop';
import {environment} from '../../environments/environment';
import {LocalStorageService} from 'ngx-webstorage';

@Injectable({
  providedIn: 'root',
})
export class MicroshopService {
  private microshopCache = new Cache<Microshop>();
  readonly microshopStorageKey = (storeHandle: string) => `microshop_${storeHandle}_${environment.apiUrl}`;

  constructor(private microshopProvider: MicroshopProvider,
              private localStorageService: LocalStorageService,
  ) {
  }

  async getMicroshop(storeHandle: string, microshopHandle: string): Promise<Microshop> {
    const cachedMicroshop = this.microshopCache.get(storeHandle + microshopHandle);
    if (cachedMicroshop) {
      return cachedMicroshop;
    }

    const remoteMicroshop = await this.microshopProvider.getMicroshop(storeHandle, microshopHandle)
      .pipe(
        map(source => source as Microshop),
        map(async source => {
          source.categories = await this.microshopProvider.getMicroshopCategories(storeHandle, microshopHandle).toPromise();
          source.categories = sortByIndexAscending(source.categories);
          return source;
        }),
      )
      .toPromise();

    this.microshopCache.set(storeHandle + microshopHandle, remoteMicroshop);

    return remoteMicroshop;
  }

  async getProductsByCategory(storeHandle: string, microshopHandle: string, categoryId: string): Promise<ProductMicroshop[]> {
    return this.microshopProvider.getProductsByCategory(storeHandle, microshopHandle, categoryId).toPromise();
  }

  persistMicroshopHandle(storeHandle: string, microshopHandle: string) {
    this.localStorageService.store(this.microshopStorageKey(storeHandle), microshopHandle);
  }

  getPersistedMicroshopHandle(storeHandle: string) {
    return this.localStorageService.retrieve(this.microshopStorageKey(storeHandle));
  }

  clearPersistedMicroshopHandle(storeHandle: string) {
    this.localStorageService.clear(this.microshopStorageKey(storeHandle));
  }
}
