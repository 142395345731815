import {ProductProvider} from '../transport/product.provider';
import {Page, Paginated} from '../transport/models/paginated';
import {Product} from './models/product/product';
import {Category} from './models/category/category';
import {Injectable} from '@angular/core';
import {Cache} from '../utils/cache.utils';
import {tap} from 'rxjs/operators';
import {ProductItem} from './models/product/product-item';
import {ProductOfflineCacheService} from './product-offline-cache.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private productCache = new Cache<Product[]>();

  constructor(private productProvider: ProductProvider, private offlineCache: ProductOfflineCacheService) {
  }

  async getProductByHandle(storeHandle: string, productHandle: string): Promise<Product> {
    const cachedProduct = this.productCache.get(storeHandle)
      ?.find(p => p.handle === productHandle || p.id === productHandle);
    if (cachedProduct) {
      return cachedProduct;
    }
    return this.productProvider.getProductByHandle(storeHandle, productHandle)
      .pipe(tap(x => this.addToCache(storeHandle, [x])))
      .toPromise();
  }

  async getProductById(storeHandle: string, productId: string): Promise<Product> {
    const cachedProduct = this.productCache.get(storeHandle)
      ?.find(p => p.handle === productId || p.id === productId);
    if (cachedProduct) {
      return cachedProduct;
    }
    return this.productProvider.getProductById(storeHandle, productId)
      .pipe(tap(x => this.addToCache(storeHandle, [x])))
      .toPromise();
  }

  searchProducts(storeHandle: string, query: string, page?: Page): Promise<Paginated<Product>> {
    return this.productProvider
      .searchProducts(storeHandle, query, page)
      .pipe(tap(x => this.addToCache(storeHandle, x.data)))
      .toPromise();
  }

  searchWeightedProducts(storeHandle: string, query: string, page?: Page): Promise<Paginated<Product>> {
    return this.productProvider
      .searchProducts(storeHandle, query, page, true)
      .pipe(tap(x => this.addToCache(storeHandle, x.data)))
      .toPromise();
  }

  getCategory(storeHandle: string, categoryId: string): Promise<Category> {
    return this.productProvider.getCategory(storeHandle, categoryId)
      .pipe(tap(x => this.addToCache(storeHandle, x.products)))
      .toPromise();
  }

  async findByBarcode(storeHandle: string, barcode: string): Promise<Product> {
    const cachedProduct = this.productCache.get(storeHandle)
      ?.find(p => p.dimensions?.find(d => d.barcode === barcode));
    if (cachedProduct) {
      return cachedProduct;
    }
    return this.productProvider.getByBarcode(storeHandle, barcode)
      .pipe(tap(x => {
        if (x) {
          this.addToCache(storeHandle, [x]);
        }
      }))
      .toPromise();
  }

  async findByArticleSerialNumber(storeHandle: string, articleNumber: string, serialNumber: string): Promise<ProductItem | undefined> {
    return this.productProvider.getByArticleSerialNumber(storeHandle, articleNumber, serialNumber).toPromise();
  }

  private addToCache(storeHandle: string, products: Product[]) {
    const cachedProducts = this.productCache.get(storeHandle) ?? [];
    const nonCached = products.filter(rp => !cachedProducts.find(cp => cp.id === rp.id));
    if (nonCached.length > 0) {
      nonCached.forEach(product => {
        cachedProducts.push(product);
      });
      this.productCache.set(storeHandle, cachedProducts);
    }
  }


  async getShoppingBagProducts(storeHandle: string, page?: Page): Promise<Paginated<Product>> {
    return this.productProvider.getShoppingBag(storeHandle, page)
      .pipe(tap(x => this.addToCache(storeHandle, x.data)))
      .toPromise();
  }

  async syncOfflineProducts(storeHandle: string) {
/*    if (!this.offlineCache.haveCache(storeHandle)) {
      let products = await this.productProvider.getProductSummaries(storeHandle);
      this.offlineCache.setCache(storeHandle, products);
    }*/
  }

  getOffLineProduct(storeHandle: string, barcodeData: string) {
    return this.offlineCache.getProduct(storeHandle, barcodeData);
  }

  async getProductsBehindCounter(storeHandle: string, page?: Page): Promise<Paginated<Product>> {
    return this.productProvider.getProductsBehindCounter(storeHandle, page)
      .pipe(tap(x => this.addToCache(storeHandle, x.data)))
      .toPromise();
  }
}
