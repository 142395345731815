import {Component, OnDestroy, OnInit} from '@angular/core';
import {StoreService} from '../../../domain/store.service';
import {Store} from '../../../domain/models/store/store';
import {CodeType, ScannerService} from '../../../domain/scanner.service';
import {Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {OrderService} from '../../../domain/order.service';
import {httpUtils} from '../../../utils/http.utils';
import {TranslateService} from '@ngx-translate/core';
import {ForegroundPaths} from '../../../app-routing.module';
import {delay} from '../../../utils/promise.utils';
import {StoreProfile} from '../../../domain/models/store/store-profile';

@Component({
  selector: 'app-inspection',
  templateUrl: './inspection.component.html',
  styleUrls: ['./inspection.component.sass'],
})
export class InspectionComponent implements OnInit, OnDestroy {
  orderId!: string;
  store?: Store;
  profile?: StoreProfile;
  scannerSubscription?: Subscription;
  minimized = false;
  isValid = false;

  constructor(private storeService: StoreService,
              private scannerService: ScannerService,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute,
              private orderService: OrderService,
              private translationService: TranslateService,
  ) {
  }

  async ngOnInit() {
    const orderId = this.route.snapshot.paramMap.get('orderId');
    if (!orderId) {
      throw Error('Missing order ID route param');
    }
    this.orderId = orderId;

    if (this.orderService.isOrderInspected(this.orderId)) {
      await this.success();
    }

    if (this.storeService.storeId) {
      this.store = await this.storeService.getStore(this.storeService.storeId);
      this.profile = this.store.storeProfile;
    }
  }

  minimizeAndStartScanner() {
    this.minimized = true;
    this.startScan();
  }

  async startScan() {
    this.scannerSubscription = this.scannerService
      .onScanResult([CodeType.QR], this.translationService.instant('INSPECTION.scannerHelp'))
      .subscribe(async result => {
        this.scannerSubscription?.unsubscribe();
        try {
          await this.orderService.validateInspectionCode(this.orderId, result.barcodeData);
          this.orderService.setOrderInspected(this.orderId);
          await this.success();
        } catch (error) {
          if (httpUtils(error).isStatus(404, 400)) {
            this.toastr.warning(this.translationService.instant('INSPECTION.invalidCode'), undefined, {
              timeOut: 3000,
              easeTime: 100,
              positionClass: 'toast-bottom-center',
            });
            await this.startScan();
          } else {
            this.expand();
            throw error;
          }
        }
      });
  }

  async success() {
    this.isValid = true;
    this.expand();
    await delay(2000);
    await this.router.navigate(ForegroundPaths.cartPayment());
  }

  expand() {
    this.scannerSubscription?.unsubscribe();
    this.minimized = false;
  }

  ngOnDestroy() {
    this.scannerSubscription?.unsubscribe();
  }
}
