<div class="root" [ngStyle]="!isChrome ? {'min-height': '100%'} : {}">
  <app-toolbar title="{{ 'MENU.myPurchases' | translate }}"
               [hasMenu]="false">
  </app-toolbar>

  <ng-container *ngIf="handler.isBusy && handler.data.length === 0">
    <div class="order-list">
      <ol>
        <div class="order-list-heading" style="width: 40%">
          <app-gradient-loader></app-gradient-loader>
        </div>
        <li>
          <ol>
            <li *ngFor="let empty of [null, null, null]">
              <app-order-item></app-order-item>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  </ng-container>

  <ng-container *ngIf="handler.data.length > 0 else emptyResultTemplate">
    <div class="order-list" (scroll)="onScrolled($event)">
      <ol>
        <li *ngFor="let groupKey of Object.keys(groupedOrders)">
          <h4 class="order-list-heading">
            {{ groupKey == '_today_' ? ('ORDERS.today' | translate) : groupKey }}
          </h4>

          <ol>
            <li class="order-list_item" *ngFor="let order of groupedOrders[groupKey]" (click)="openReceipt(order.id)">
              <app-order-item [order]="order">
              </app-order-item>
              <div *ngIf="recentOrderIds.includes(order.id)" class="order-item-badge"></div>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  </ng-container>
</div>

<ng-template #emptyResultTemplate>
  <div *ngIf="!handler.isBusy" class="order-list_empty-container">
    <mat-icon>
      shopping_basket
    </mat-icon>
    <div class="search-results_empty">{{ 'ORDERS.empty' | translate }}</div>
  </div>
</ng-template>
