interface Rule {
  type: RuleType;
  scope: CampaignRuleScope;
  requiresMembership: boolean;
}

export interface CartCashDiscount extends Rule {
  type: RuleType.CartCashDiscount;
  scope: CampaignRuleScope.Cart;
  discountThreshold: number;
  discountCash: number;
}

export interface CartPercentDiscount extends Rule {
  type: RuleType.CartPercentDiscount;
  scope: CampaignRuleScope.Cart;
  discountThreshold: number;
  discountPercent: number;
}

export interface CashDiscount extends Rule {
  type: RuleType.CashDiscount;
  scope: CampaignRuleScope.Product;
  discountCash: number;
}

export interface NewPrice extends Rule {
  type: RuleType.NewPrice;
  scope: CampaignRuleScope.Product;
  discountPrice: number;
}

export interface PercentDiscount extends Rule {
  type: RuleType.PercentDiscount;
  scope: CampaignRuleScope.Product;
  discountPercent: number;
}

export interface QuantityCashRebate extends Rule {
  type: RuleType.QuantityCashRebate;
  scope: CampaignRuleScope.Cart;
  quantityRequirement: number;
  discountPrice: number;
}

export interface QuantityRebate extends Rule {
  type: RuleType.QuantityRebate;
  scope: CampaignRuleScope.Cart;
  quantityRequirement: number;
  quantityRebate: number;
}

export enum RuleType {
  CashDiscount = 'cashDiscount',
  PercentDiscount = 'percentDiscount',
  NewPrice = 'newPrice',
  CartPercentDiscount = 'cartPercentDiscount',
  CartCashDiscount = 'cartCashDiscount',
  QuantityRebate = 'quantityRebate',
  QuantityCashRebate = 'quantityCashRebate',
}

export enum CampaignRuleScope {
  Product = 'product',
  Cart = 'cart',
}

