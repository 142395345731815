import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.sass']
})
export class SearchBarComponent implements OnInit, AfterViewInit {

  @Input() hint: string = '';
  @Input() autofocus = false;
  @Input() initialValue?: string;

  @Output() doSearch = new EventEmitter<string>();
  @Output() doClear = new EventEmitter();
  @Output() focusChanged = new EventEmitter<boolean>();

  @ViewChild('input') inputView?: ElementRef;

  private search = new Subject<string>();

  isFocused = false;
  inputText?: string;

  constructor(private route: ActivatedRoute) {
    this.search
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(value => {
        this.doSearch.emit(value);
      });

    this.inputText = this.route.snapshot.queryParams['search'];
  }

  ngOnInit(): void {
    this.isFocused = this.autofocus;
  }

  ngAfterViewInit(): void {
    if (this.autofocus) {
      this.inputView?.nativeElement.focus();
    }
  }

  onInput(query: string) {
    this.inputText = query;
    if (query != null && query.length >= 3) {
      this.search.next(query);
    } else {
      this.search.next('');
    }
  }

  clear() {
    this.inputText = undefined;
    this.doClear.emit();
  }

  onFocus(hasFocus: boolean) {
    this.isFocused = hasFocus;
    this.focusChanged.emit(this.isFocused);
  }
}
