<div class="root">
  <app-gradient-loader *ngIf="!isImageLoaded && animateLoading"></app-gradient-loader>
  <img *ngIf="source != null && !isEager"
       (load)="onLoad($event)"
       [ngClass]="{'loaded': isImageLoaded, 'contain': unacceptableAspectRatio}"
       loading="lazy"
       src='{{source}}'>
  <img *ngIf="source != null && isEager"
       (load)="onLoad($event)"
       [ngClass]="{'loaded': isImageLoaded, 'contain': unacceptableAspectRatio}"
       loading="eager"
       src='{{source}}'>
</div>
