<div class="slide-to-confirm"
     [ngClass]="{
       'slide-to-confirm--dragging': state == SlideState.Dragging,
       'slide-to-confirm--confirming': state == SlideState.Confirming,
       'slide-to-confirm--confirmed': state == SlideState.Confirmed
      }"
     [ngStyle]="{backgroundColor: profile?.primaryColor, color: profile?.primaryTextColor}">
  <div #boundaryRef class="slide-to-confirm__boundary">
    <ng-container cdkDropListGroup>
      <div class="slide-to-confirm__start-container" cdkDropList>
        <div *ngIf="state == SlideState.Default || state == SlideState.Dragging"
             class="slide-to-confirm__handle"
             cdkDrag
             cdkDragLockAxis="x"
             [cdkDragBoundary]="boundaryRef"
             (cdkDragStarted)="onDragStart()"
             (cdkDragReleased)="onDragEnd()">
          <svg class="slide-to-confirm__handle-icon" width="13" height="14" viewBox="0 0 13 14" fill="none">
            <path
              d="M4.39616 6.99998L0.415384 1.43073C0.239751 1.18074 0.218601 0.92146 0.351934 0.652876C0.485268 0.384293 0.706384 0.25 1.01528 0.25C1.13883 0.25 1.25621 0.278167 1.36741 0.3345C1.47863 0.390833 1.56512 0.469076 1.62688 0.569226L6.23073 6.99998L1.62688 13.4307C1.56493 13.5309 1.47818 13.6091 1.36663 13.6655C1.2551 13.7218 1.13738 13.75 1.01346 13.75C0.716026 13.75 0.498076 13.6157 0.359609 13.3471C0.221159 13.0785 0.239751 12.8192 0.415384 12.5692L4.39616 6.99998ZM10.2692 6.99998L6.28846 1.43073C6.11283 1.18074 6.09168 0.92146 6.22501 0.652876C6.35833 0.384293 6.57943 0.25 6.88833 0.25C7.0119 0.25 7.12928 0.278167 7.24048 0.3345C7.35168 0.390833 7.43818 0.469076 7.49996 0.569226L12.1038 6.99998L7.49996 13.4307C7.43799 13.5309 7.35124 13.6091 7.23971 13.6655C7.12818 13.7218 7.01044 13.75 6.88651 13.75C6.58909 13.75 6.37115 13.6157 6.23268 13.3471C6.09423 13.0785 6.11283 12.8192 6.28846 12.5692L10.2692 6.99998Z"
              fill="currentColor"/>
          </svg>
        </div>
      </div>

      <div *ngIf="state != SlideState.Confirming && state != SlideState.Confirmed" class="slide-to-confirm__text">{{ text ?? 'SLIDETOCONFIRM.text' | translate }}</div>
      <div *ngIf="state == SlideState.Confirming" class="slide-to-confirm__text-waiting">{{ 'SLIDETOCONFIRM.waiting' | translate }}</div>

      <div #endContainer class="slide-to-confirm__end-container" cdkDropList (cdkDropListDropped)="onContainerDrop($event)">
        <div *ngIf="state == SlideState.Confirmed" class="slide-to-confirm__handle">
          <ng-lottie
            height="35px"
            width="35px"
            [options]="{
              path: '/assets/animations/whitecheck.json',
              renderer: 'svg',
              rendererSettings: {
                viewBoxSize: '125 125 275 275'
              },
              autoplay: true,
              loop: false
            }">
          </ng-lottie>
        </div>
        <div *ngIf="state == SlideState.Confirming" class="slide-to-confirm__handle">
          <ng-lottie
            height="25px"
            width="25px"
            [options]="{
              path: '/assets/animations/fygiloader3-blue.json',
              renderer: 'svg',
              autoplay: true,
              loop: true
          }">
          </ng-lottie>
        </div>
      </div>
    </ng-container>
  </div>
</div>
