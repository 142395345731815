import {Component, Input, OnInit} from '@angular/core';
import {Store} from '../../../../domain/models/store/store';
import {Category, CategoryMicroshop, CategoryType} from '../../../../domain/models/category/category';
import {ForegroundPaths} from '../../../../app-routing.module';
import {Router} from '@angular/router';
import {ProductService} from '../../../../domain/product.service';
import {Microshop} from '../../../../domain/models/microshop/microshop';
import {MicroshopService} from '../../../../domain/microshop.service';

@Component({
  selector: 'app-category-item',
  templateUrl: './category-item.component.html',
  styleUrls: ['./category-item.component.sass']
})
export class CategoryItemComponent implements OnInit {
  currentStore: Store | undefined;
  currentMicroshop: Microshop | undefined;
  currentCategory: CategoryType | undefined;
  categoryDetails: Category | CategoryMicroshop | undefined;
  cultureName: any;
  currencyCode: any;

  constructor(private router: Router,
              private productService: ProductService,
              private microshopService: MicroshopService,
  ) {
  }

  @Input() set store(value: Store | undefined) {
    this.setStore(value);
  }

  @Input() set microshop(value: Microshop | undefined) {
    this.setMicroshop(value);
  }

  @Input() set category(value: CategoryType | undefined) {
    this.setCategory(value);
  }

  ngOnInit() {
    if (this.currentStore != undefined && this.categoryDetails == undefined &&
      (this.currentMicroshop && this.currentMicroshop.categories.length < 15 || this.currentStore.categories.length < 15)) {
      this.getCategoryDetails().catch(error => {
        throw error;
      });
    }
  }

  private setStore(value: Store | undefined) {
    this.currentStore = value;
  }

  private setMicroshop(value: Microshop | undefined) {
    this.currentMicroshop = value;
  }

  private setCategory(value: CategoryType | undefined) {
    this.currentCategory = value;
  }

  async openProduct(productHandle: string) {
    await this.router.navigate(ForegroundPaths.product(productHandle));
  }

  async getCategoryDetails() {
    if (this.currentMicroshop) {
      const category = this.currentCategory as CategoryMicroshop;
      category.products = await this.microshopService.getProductsByCategory(
        this.currentStore!.handle,
        this.currentMicroshop.handle,
        this.currentCategory!.id,
      );
      this.categoryDetails = category;
    } else {
      this.categoryDetails = await this.productService.getCategory(
        this.currentStore!.handle,
        this.currentCategory!.id,
      );
    }
  }
}
