export function sortByIndexAscending<T extends Sorted>(list: T[]): T[] {
  return list.sort((a, b) => (a.sortIndex < b.sortIndex) ? -1 : (a.sortIndex > b.sortIndex) ? 1 : 0);
}
export function sortByIndexDescending<T extends Sorted>(list: T[]): T[] {
  return list.sort((a, b) => (a.sortIndex < b.sortIndex) ? 1 : (a.sortIndex > b.sortIndex) ? -1 : 0);
}

interface Sorted {
  sortIndex: number;
}
