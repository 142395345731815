export class EmailReceiptRequest {
  orderId: string;
  customerId: string;
  email: string;

  constructor(orderId: string,
              customerId: string,
              email: string,
  ) {
    this.orderId = orderId;
    this.customerId = customerId;
    this.email = email;
  }
}
