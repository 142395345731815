import {Injectable} from '@angular/core';
import {Cart, OrderLine} from './models/order/cart';
import {OrderService} from './order.service';
import {StoreService} from './store.service';
import {Product} from './models/product/product';
import {Observable, Subject} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {LocalStorageService} from 'ngx-webstorage';
import {CampaignOrderLine, OrderLineType, ProductOrderLine, SelectedProduct} from './models/order/order-line';
import {Dimension} from './models/product/dimension';
import {CampaignUtils} from '../utils/campaign.utils';
import {Campaign} from './models/campaign/campaign';
import {CampaignRuleScope, RuleType} from './models/campaign/rule';
import {GTagService} from './gtag.service';
import {environment} from '../../environments/environment';
import {FulfillmentOption} from './models/store/fulfillmentOption';
import {ShippingAddress} from './models/order/shipping-address';
import {AdjustmentType} from './models/product/adjustmentType';
import {DimensionAvailabilityService} from './dimension-availability.service';
import {DimensionAvailability} from './models/product/dimension-availability';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {OfflineProductOrderLine} from './models/order/offline-product-order-line';
import {Store} from './models/store/store';
import {AdjustmentTypeService} from './adjustment-type.service';
import {AgeLimitService} from './age-limit.service';
import {OfflineCart} from './models/order/offline-cart';
import {CustomerService} from './customer.service';
import {Order} from './models/order/order';
import {TProductOrderLine} from '../transport/models/order/t-order-line';
import {CartResponse} from '../transport/models/order/cart.response';
import {LoadingService} from './loading.service';
import {bugsnagStartSpan} from '../bugsnag';
import {MicroshopService} from './microshop.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  dimensionAvailability: DimensionAvailability[] = [];
  private updateCartAbortController: AbortController | null = null;

  constructor(private orderService: OrderService,
              private storeService: StoreService,
              private storageService: LocalStorageService,
              private gTagService: GTagService,
              private ageLimitService: AgeLimitService,
              private dimensionAvailabilityService: DimensionAvailabilityService,
              private toastr: ToastrService,
              private translateService: TranslateService,
              private adjustmentService: AdjustmentTypeService,
              private customerService: CustomerService,
              private loadingService: LoadingService,
              private microshopService: MicroshopService,
  ) {
  }

  private carts: Map<string, Cart> = new Map<string, Cart>();
  private cartChange: Subject<{ key: string, cart: Cart }> = new Subject();
  private productAdded: Subject<{ key: string, orderLine: OrderLine }> = new Subject();

  onCartChanges(storeHandle: string): Observable<Cart> {
    return this.cartChange
      .pipe(
        filter(value => value.key === storeHandle),
        map(value => value.cart)
      );
  }

  onProductAdded(storeHandle: string): Observable<OrderLine> {
    return this.productAdded
      .pipe(
        filter(value => value.key === storeHandle),
        map(value => value.orderLine),
      );
  }

  async getOrCreateLocalCart(storeHandle: string): Promise<Cart> {
    const memoryCart = this.carts.get(storeHandle);
    if (memoryCart != null) {
      return Promise.resolve(memoryCart);
    }

    const storageCart = this.storageService.retrieve(`cart_${storeHandle}_${environment.apiUrl}`) as Cart | undefined;
    if (storageCart != null && (!storageCart.updatedAt || Date.now() - storageCart.updatedAt < 86400000)) {
      this.carts.set(storeHandle, storageCart);
      return Promise.resolve(storageCart);
    }

    const store = await this.storeService.getStore(storeHandle);
    const cart = new Cart(store.id);
    cart.adjustmentTypeId = this.adjustmentService.getStoredTypeId(storeHandle);
    this.carts.set(storeHandle, cart);
    return cart;
  }

  clearCart(storeHandle: string) {
    this.carts.delete(storeHandle);
    this.storageService.clear(`cart_${storeHandle}_${environment.apiUrl}`);
  }

  async add(storeHandle: string, product: Product, dimensionId?: string, quantity?: number, itemId?: string, priceOverride?: number) {
    const span = bugsnagStartSpan('cart.service:add', {isFirstClass: false});

    const cart = await this.getOrCreateLocalCart(storeHandle);

    const dimension: Dimension = dimensionId != null
      ? product.dimensions.find(d => d.id === dimensionId)!
      : product.dimensions[0];

    let orderLine = await this.getProductOrderLine(cart, dimension.id);
    const store = await this.storeService.getStore(storeHandle);

    if (orderLine != null && !product.isWeight) {
      this.updateOrderLine(orderLine, quantity, itemId!, product);
      this.productAdded.next({key: storeHandle, orderLine: orderLine});
    } else {
      orderLine = CartService.createNewOrderLine(product, store, quantity, orderLine, dimension, itemId, priceOverride);
      cart.orderLines.push(orderLine);
    }
    await this.updateCart(storeHandle, cart, true, product.ageGroup !== undefined);

    this.gTagService.addToCart(dimension.articleNumber, product.name,
      product.vendor, orderLine.unitPrice);

    span?.end();
  }

  private static createNewOrderLine(product: Product, store: Store, quantity: number | undefined, orderLine: ProductOrderLine | undefined, dimension: Dimension, itemId: string | undefined, priceOverride?: number) {
    const unitPrice = CampaignUtils.getCheapestPrice(product, store.campaigns);
    if (!quantity) {
      quantity = product.isWeight ? 0 : 1;
    }
    orderLine = CartService.CreateProductOrderLine(product, dimension, quantity, unitPrice, itemId, priceOverride);

    if (product.fulfillmentOptions && product.fulfillmentOptions.length > 0) {
      orderLine.fulfillmentOptionId = product.fulfillmentOptions.find(x => x.default)?.id;
    }
    return orderLine;
  }

  private updateOrderLine(orderLine: ProductOrderLine, quantity: number | undefined, itemId: string | null, product: Product) {
    orderLine.quantity += quantity ?? 1;

    if (itemId != null) {
      if (orderLine.itemIds === null) {
        orderLine.itemIds = [];
      }
      if (!orderLine.itemIds.find(value => value == itemId)) {
        orderLine.itemIds.push(itemId);
      }
    }

    if (product.fulfillmentOptions && product.fulfillmentOptions.length > 0) {
      orderLine.fulfillmentOptionId = product.fulfillmentOptions.find(x => x.default)?.id;
    }
  }

  async remove(storeHandle: string, orderLine: ProductOrderLine) {
    const cart = await this.getOrCreateLocalCart(storeHandle);
    const existing = this.getOrderLineInCart(cart, orderLine.id);
    const index = cart.orderLines.indexOf(existing!);
    if (index >= 0) {
      cart.orderLines.splice(index, 1);
      await this.updateCart(storeHandle, cart);
    }
  }

  async increaseCount(storeHandle: string, orderLine: ProductOrderLine): Promise<OrderLine | undefined> {
    const cart = await this.getOrCreateLocalCart(storeHandle);
    const existing = this.getOrderLineInCart(cart, orderLine.id);
    if (existing == null) {
      return undefined;
    }
    existing.quantity += 1;
    await this.updateCart(storeHandle, cart);
    return existing;
  }


  async decreaseCount(storeHandle: string, orderLine: ProductOrderLine): Promise<OrderLine | undefined> {
    const cart = await this.getOrCreateLocalCart(storeHandle);
    const existing = this.getOrderLineInCart(cart, orderLine.id);
    if (existing == null) {
      return undefined;
    }
    existing.quantity -= 1;
    await this.updateCart(storeHandle, cart);
    return existing;
  }

  async setCount(storeHandle: string, orderLine: ProductOrderLine, quantity: number): Promise<OrderLine | undefined> {
    const cart = await this.getOrCreateLocalCart(storeHandle);
    const existing = this.getOrderLineInCart(cart, orderLine.id);

    if (existing == null) {
      return undefined;
    }
    this.dimensionAvailability = await this.dimensionAvailabilityService.getStock(storeHandle, orderLine.product.id);
    if (this.dimensionAvailability[0].trackStock && this.dimensionAvailability[0].count >= quantity || !this.dimensionAvailability[0].trackStock) {
      existing.quantity = quantity;
      await this.updateCart(storeHandle, cart);
    } else {
      const message = await this.translateService.get('PRODUCTDETAILS.noStock').toPromise();
      this.toastr.error(message, undefined, {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'});
    }
    return existing;
  }

  async getCartCount(cart: Cart, offlineCart?: OfflineCart) {
    const cartCount = cart?.orderLines
      .filter(line => line.type === OrderLineType.Product)
      .map(line => line as ProductOrderLine)
      .map(line => line.product.isWeight ? 1 : line.quantity)
      .reduce((sum, q) => sum + q, 0) ?? 0 ?? 0;

    const offlineCount = offlineCart?.orderLines
      .map(line => line.product.isWeight ? 1 : line.quantity)
      .reduce((sum, q) => sum + q, 0) ?? 0 ?? 0;

    return (cartCount + offlineCount).toString();
  }

  async setFulfillmentOptions(storeHandle: string, orderLine: ProductOrderLine, fulfillmentOption: FulfillmentOption) {
    const cart = await this.getOrCreateLocalCart(storeHandle);
    const existing = cart.orderLines
      .filter(line => line.type === OrderLineType.Product)
      .map(line => line as ProductOrderLine)
      .find(o => o.id === orderLine.id);
    if (existing == null) {
      return undefined;
    }
    existing.fulfillmentOptionId = fulfillmentOption.id;
    await this.updateCart(storeHandle, cart);
    return existing;
  }

  async setShipmentAddress(storeHandle: string, shippingAddress: ShippingAddress) {
    this.setMissingData(shippingAddress);
    const cart = await this.getOrCreateLocalCart(storeHandle);
    cart.shippingAddress = shippingAddress;
    await this.updateCart(storeHandle, cart);
  }

  async setWeight(orderLineId: string, weight: number, storeHandle: string): Promise<OrderLine | undefined> {
    const cart = await this.getOrCreateLocalCart(storeHandle);
    const existing = cart.orderLines
      .filter(line => line.type === OrderLineType.Product)
      .map(line => line as ProductOrderLine)
      .find(o => o.id === orderLineId);
    if (existing == null) {
      return;
    }
    existing.quantity = weight;
    await this.updateCart(storeHandle, cart);
    return existing;
  }

  async setPLUWeight(orderLineId: string, storeHandle: string, newPrice: number) {
    const cart = await this.getOrCreateLocalCart(storeHandle);
    const existing = cart.orderLines
      .filter(line => line.type === OrderLineType.Product)
      .map(line => line as ProductOrderLine)
      .find(o => o.id === orderLineId);
    if (existing == null) {
      return;
    }
    existing.priceOverride = newPrice;
    existing.quantity = 1;
    await this.updateCart(storeHandle, cart);
    return existing;
  }

  private async updateCart(storeHandle: string, cart: Cart, updateSubscriptions = true, requiresAgeCheck = false) {
    if (this.updateCartAbortController) {
      this.updateCartAbortController.abort();
      this.updateCartAbortController = null;
    }
    this.updateCartAbortController = new AbortController();
    const {signal} = this.updateCartAbortController;

    await this.updateCartAbortable(storeHandle, cart, updateSubscriptions, requiresAgeCheck, signal);
  }

  private async updateCartAbortable(storeHandle: string, cart: Cart, updateSubscriptions: boolean, requiresAgeCheck: boolean, abortSignal: AbortSignal) {
    const store = await this.storeService.getStore(storeHandle);
    cart.externalId = this.storeService.getExternalId(storeHandle);
    cart.orderLines = cart.orderLines
      .filter(line => line.type === OrderLineType.Product);
    cart.orderLines.push(...this.getCartCampaignsFor(cart.orderLines, store.campaigns));
    cart.sum = cart.orderLines
      .map(line => CartService.getLineTotal(line))
      .reduce((sum, price) => sum + price, 0);

    cart.orderReference = null;
    const persistedMicroshopHandle = this.microshopService.getPersistedMicroshopHandle(storeHandle);
    if (persistedMicroshopHandle) {
      try {
        cart.orderReference = (await this.microshopService.getMicroshop(storeHandle, persistedMicroshopHandle)).id;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(`Could not set persisted microshop's ID as order reference`, e);
      }
    }

    if (updateSubscriptions) {
      this.cartChange.next({key: storeHandle, cart: cart});
    }

    const customerId = await this.customerService.getCustomerId();
    let response: CartResponse;

    try {
      this.loadingService.incrementQueue();
      response = await this.orderService.validateCart(cart, customerId).finally(() => this.loadingService.decrementQueue());
    } catch (error) {
      throw new ValidateCartException(error);
    }

    if (abortSignal.aborted) {
      return;
    }

    cart.orderId = response.orderId;
    const oldIds = cart.orderLines.map(l => l.id);
    const newProductOrderLines = response.orderLines
      .filter(line => line.type === OrderLineType.Product && this.lineIsNotRelatedOrderLine(response, line))
      .filter(line => !oldIds.includes(line.id));

    // newProductOrderLines should only return one item in most instances, UNLESS their IDs needed to be reset in validateCart()
    const newestProductOrderLine = newProductOrderLines[newProductOrderLines.length - 1];

    if (requiresAgeCheck && newestProductOrderLine != null) {
      this.ageLimitService.addOrderLine(newestProductOrderLine.id);
    }

    cart.orderLines = response.orderLines;
    cart.orderLineRelations = response.orderLineRelations;
    cart.sum = response.sum;
    cart.updatedAt = Date.now();
    await this.storageService.store(`cart_${storeHandle}_${environment.apiUrl}`, cart);
    this.carts.set(storeHandle, cart);
    this.cartChange.next({key: storeHandle, cart: cart});
    if (newestProductOrderLine && updateSubscriptions) {
      this.productAdded.next({key: storeHandle, orderLine: newestProductOrderLine});
    }
    this.updateCartAbortController = null;
  }

  private lineIsNotRelatedOrderLine(cart: CartResponse, line: TProductOrderLine) {
    return cart.orderLineRelations.findIndex(ol => ol.relatedOrderLineId == line.id) == -1;
  }

  private static getLineTotal(line: ProductOrderLine | CampaignOrderLine) {
    if (line.priceOverride != undefined) {
      return line.priceOverride;
    }
    return line.unitPrice * line.quantity;
  }

  private getProductOrderLine(cart: Cart, dimensionId: string): ProductOrderLine | undefined {
    return cart.orderLines
      .filter(line => line.type === OrderLineType.Product)
      .map(line => line as ProductOrderLine)
      .find(o => o.product.dimensionId === dimensionId);
  }

  private getOrderLineInCart(cart: Cart, id: string): OrderLine | undefined {
    return cart.orderLines.find(o => o.id === id);
  }

  private getCartCampaignsFor(orderLines: OrderLine[], campaigns: Campaign[]): CampaignOrderLine[] {

    const productLines = orderLines.filter(p => p.type === OrderLineType.Product) as ProductOrderLine[];
    campaigns = campaigns.filter(c => c.rule.scope === CampaignRuleScope.Cart);

    const campaignOrderLines: CampaignOrderLine[] = [];

    campaigns.forEach(campaign => {
      const rule = campaign.rule;
      const products = productLines
        .filter(p => campaign.products.find(cp => cp.id == p.product.id))
        .map(p => Array<ProductOrderLine>(p.quantity).fill(p))
        .reduce((a, b) => a.concat(b), [])
        .sort((a, b) => a.unitPrice - b.unitPrice);

      if (products.length > 0) {
        switch (rule.type) {
          case RuleType.CartPercentDiscount:
            // eslint-disable-next-line no-case-declarations
            const cartSum = productLines.reduce((sum, p) => sum + p.totalPrice, 0);
            if (cartSum >= rule.discountThreshold) {
              const unitPrice = -(cartSum * (rule.discountPercent / 100));
              orderLines.push(CartService.createCampaignOrderLine(campaign, unitPrice));
            }
            break;
          case RuleType.CartCashDiscount:
            // eslint-disable-next-line no-case-declarations
            const sum = productLines.reduce((sum, p) => sum + p.totalPrice, 0);
            if (sum >= rule.discountThreshold) {
              const unitPrice = -rule.discountCash;
              orderLines.push(CartService.createCampaignOrderLine(campaign, unitPrice));
            }
            break;
          case RuleType.QuantityRebate:
            // eslint-disable-next-line no-case-declarations
            const quantityReachedCount = Math.floor(products.length / rule.quantityRequirement);
            for (let i = 0; i < quantityReachedCount; i++) {
              const unitPrice = -rule.quantityRebate * products[i].unitPrice;
              orderLines.push(CartService.createCampaignOrderLine(campaign, unitPrice));
            }
            break;
          case RuleType.QuantityCashRebate:
            // eslint-disable-next-line no-case-declarations
            const quantityCashReachedCount = Math.floor(products.length / rule.quantityRequirement);
            for (let i = 0; i < quantityCashReachedCount; i++) {
              const unitPrice = rule.discountPrice - products
                .slice(i, i + rule.quantityRequirement)
                .reduce((sum, p) => sum + p.unitPrice, 0); //TODO
              orderLines.push(CartService.createCampaignOrderLine(campaign, unitPrice));
            }
            break;
        }
      }
    });

    return campaignOrderLines;
  }

  private static CreateProductOrderLine(product: Product, dimension: Dimension, quantity: number, unitPrice: number, itemId: string | undefined, priceOverride?: number) {
    const fakeId = ('*' + product.id + dimension.id) + (product.isWeight ? quantity.toString() : '');
    return new class implements ProductOrderLine {
      type: OrderLineType.Product = OrderLineType.Product;
      id = fakeId;
      name = product.name;
      product = new class implements SelectedProduct {
        id = product.id;
        dimensionId = dimension.id;
        isWeight = product.isWeight;
      };
      quantity = quantity;
      priceOverride = priceOverride;
      itemIds = itemId != null ? [itemId] : [];
      unitPrice = unitPrice;
      totalPrice = unitPrice * quantity;
      taxPercent = 0;
      totalTaxPaid = 0;
      unitTaxPaid = 0;
    };
  }

  private static createCampaignOrderLine(campaign: Campaign, unitPrice: number, quantity = 1): CampaignOrderLine {
    return new class implements CampaignOrderLine {
      type: OrderLineType.Campaign = OrderLineType.Campaign;
      id = campaign.id;
      name = campaign.name;
      campaign = campaign;
      quantity = quantity;
      unitPrice = unitPrice;
      totalPrice = unitPrice * quantity;
      taxPercent = 0;
      totalTaxPaid = 0;
      unitTaxPaid = 0;
    };
  }

  async setAdjustmentType(storeHandle: string, adjustmentType: AdjustmentType) {
    this.adjustmentService.setStoredTypeId(storeHandle, adjustmentType);

    const cart = this.carts.get(storeHandle);
    if (cart != null) {
      cart.adjustmentTypeId = adjustmentType.id;
    }
  }

  async setDiscount(storeHandle: string, discountCode: string | undefined) {
    const cart = await this.getOrCreateLocalCart(storeHandle);
    const totalSum = cart.sum;
    if (discountCode != null) {
      cart.discountCodes[0] = discountCode;
    }
    await this.updateCart(storeHandle, cart);
    if (totalSum > cart.sum) {
      const message = await this.translateService.get('DISCOUNT.discountAdded').toPromise();
      this.toastr.success(message, 'Success', {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'});
      return;
    }
    const message = await this.translateService.get('DISCOUNT.discountFailed').toPromise();
    this.toastr.warning(message, undefined, {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'});
  }

  async getItemCount(storeHandle: string, product: Product, dimension: Dimension) {
    const cart = await this.getOrCreateLocalCart(storeHandle);
    const orderLine = await this.getProductOrderLine(cart, dimension.id);
    if (orderLine !== undefined) {
      return orderLine!.quantity;
    }
    return 0;
  }

  async addOfflineBatch(storeHandle: string, products: Product[], offlineProductOrderLines: Array<OfflineProductOrderLine>) {
    const store = await this.storeService.getStore(storeHandle);
    const cart = await this.getOrCreateLocalCart(storeHandle);

    for (const product of products) {
      const offlineOrderLine = offlineProductOrderLines.find(p => p.product.id == product.id);

      let orderLine = await this.getProductOrderLine(cart, offlineOrderLine!.dimension.id);

      if (orderLine != null && !product.isWeight) {
        this.updateOrderLine(orderLine, offlineOrderLine!.quantity, null, product);
      } else {
        const dimension = product.dimensions.find(d => d.id == offlineOrderLine!.dimension.id);
        orderLine = CartService.createNewOrderLine(product, store, offlineOrderLine!.quantity, orderLine, dimension!, undefined);
        cart.orderLines.push(orderLine);
      }
    }

    await this.updateCart(storeHandle, cart, false);
  }

  async refreshCart(storeHandle: string) {
    const cart = await this.getOrCreateLocalCart(storeHandle);
    await this.updateCart(storeHandle, cart, false);
  }

  async setAgeCheckPerformed(handle: string) {
    const cart = await this.getOrCreateLocalCart(handle);
    this.ageLimitService.setCartChecked(cart.orderId!);
  }

  async setCart(storeHandle: string, order: Order) {
    const cart = new Cart(order.storeId);
    cart.orderLines = order.orderLines;
    cart.orderId = order.id;
    await this.updateCart(storeHandle, cart, false);
  }

  setMissingData(shippingAddress: ShippingAddress) {
    if (shippingAddress.addressLine1 == '') {
      shippingAddress.addressLine1 = '-';
    }
    if (shippingAddress.addressLine2 == '') {
      shippingAddress.addressLine2 = '-';
    }

    if (shippingAddress.city == '') {
      shippingAddress.city = '-';
    }

    if (shippingAddress.countryName == '') {
      shippingAddress.countryName = '-';
    }

    if (shippingAddress.postalCode == '') {
      shippingAddress.postalCode = '-';
    }

  }

}

class ValidateCartException extends Error {
  constructor(error: any) {
    super(error.message);
    this.name = 'ValidateCartException';

    // captureStackTrace() is not available in other browsers than Chrome
    if ('captureStackTrace' in Error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Error.captureStackTrace(this, this.constructor);
    } else if ('stack' in error && error.stack) {
      this.stack = error.stack;
    }
  }
}
