import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CustomerService} from '../../domain/customer.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {OrderService} from '../../domain/order.service';
import {StoreService} from '../../domain/store.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.sass'],
})
export class MenuComponent implements OnInit {
  @Output() signOut = new EventEmitter();
  showMyOrdersBadge = false;

  constructor(public customerService: CustomerService,
              private translateService: TranslateService,
              private toastr: ToastrService,
              private orderService: OrderService,
              private storeService: StoreService,
              private router: Router,
  ) {
  }

  ngOnInit() {
    this.storeService.contextHistoryPath = this.router.url.split('?')[0];
    this.customerService.isSignedIn$.subscribe(() => this.showMyOrdersBadge = this.orderService.hasRecentPurchase());
  }

  async logout($event: MouseEvent) {
    $event.preventDefault();
    this.customerService.logout();

    const message = await this.translateService.get('LOGIN.loggedOut').toPromise();
    this.toastr.success(
      message,
      undefined,
      {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'},
    );

    this.signOut.emit();
  }
}
