import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {StoreProfile} from '../../domain/models/store/store-profile';
import {StoreService} from '../../domain/store.service';
import {OrderService} from '../../domain/order.service';
import {CustomerService} from '../../domain/customer.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.sass'],
})
export class ToolbarComponent implements OnInit {
  @Input() profile?: StoreProfile;
  @Input() title: string | undefined;
  @Input() image: string | undefined;
  @Input() subtitle: string | undefined;
  @Input() hasMenu: boolean = true;
  @Input() action?: TemplateRef<any>;

  /** @deprecated This has unfortunately been rendered useless by the onBackCLick() rewrite */
  @Output() onBackClicked = new EventEmitter();

  isMenuOpen = false;
  hasBackButton = false;
  pageUrl?: string;
  showBadge = false;

  constructor(private location: Location,
              private router: Router,
              private route: ActivatedRoute,
              private storeService: StoreService,
              private orderService: OrderService,
              private customerService: CustomerService,
  ) {
  }

  ngOnInit(): void {
    this.isMenuOpen = this.route.snapshot.queryParams['menu'];
    this.hasBackButton = this.onBackClicked.observers.length > 0;
    this.pageUrl = this.router.url.slice(1, 6);
    this.customerService.isSignedIn$.subscribe(() => this.showBadge = this.orderService.hasRecentPurchase());
  }

  async onBackClick() {
    if (this.router.url.startsWith('/orders/')) {
      await this.router.navigate(['..'], {relativeTo: this.route, queryParamsHandling: 'preserve'});
      return;
    }

    const backCtx = this.route.snapshot.queryParams['ctx'];

    if (backCtx && backCtx.length > 0) {
      await this.router.navigate([backCtx]);
      return;
    }

    await this.router.navigate(['/']);
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    this.storeService.contextHistoryPath = this.router.url.split('?')[0];
    const url = this.router.createUrlTree([], {
      relativeTo: this.route,
      queryParams: {menu: this.isMenuOpen ? 'visible' : undefined},
      queryParamsHandling: 'merge',
    }).toString();
    this.location.go(url);
  }
}
