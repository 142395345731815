import {Inject, Injectable, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Cache} from '../utils/cache.utils';

@Injectable({
  providedIn: 'root',
})
export class LoadJsFilesService {
  private scriptPromiseCache = new Cache<Promise<HTMLScriptElement>>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  public loadJsScript(renderer: Renderer2, src: string): Promise<HTMLScriptElement> {
    const cachedPromise = this.scriptPromiseCache.get(src);
    if (cachedPromise) {
      return cachedPromise;
    }

    const promise = new Promise<HTMLScriptElement>((resolve, reject) => {
      const script = renderer.createElement('script') as HTMLScriptElement;
      script.type = 'text/javascript';
      script.src = src;
      script.async = true;

      script.addEventListener('load', () => {
        resolve(script);
      }, {once: true});

      script.addEventListener('error', () => {
        this.scriptPromiseCache.remove(src);
        script.remove();
        reject(new Error(`Unable to load script ${src}`));
      }, {once: true});

      renderer.appendChild(this.document.body, script);
    });

    this.scriptPromiseCache.set(src, promise);
    return promise;
  }

  public removeJsScript(src: string) {
    const cachedPromise = this.scriptPromiseCache.get(src);
    cachedPromise?.then(script => script.remove());
    this.scriptPromiseCache.remove(src);
  }
}
