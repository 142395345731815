import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-image-fading',
  templateUrl: './image-fading.component.html',
  styleUrls: ['./image-fading.component.sass']
})
export class ImageFadingComponent implements OnInit {

  isImageLoaded: boolean = false;
  unacceptableAspectRatio: boolean = false;

  @Input() animateLoading: boolean = true;
  @Input() isEager: boolean = false;

  @Input() source: string | undefined

  constructor() { }

  ngOnInit(): void {
  }

  onLoad(event: Event) {
    this.isImageLoaded = true
    const image = event.target as HTMLImageElement;
    const ratio = (image.naturalWidth / image.naturalHeight);
    this.unacceptableAspectRatio = 0.76 > ratio || ratio > 1.5;
  }
}
