import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {delay, filter, finalize, share} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScannerService {
  public scanner?: ScannerProvider;
  private subscribers = 0;
  private stopTimeout?: Subscription;

  registerScanner(scanner: ScannerProvider) {
    this.scanner = scanner;
  }

  onScanResult(types?: CodeType[], tutorialText?: string): Observable<ScanResult> {
    if (!this.scanner) {
      throw new Error('Scanner not registered');
    }

    if (!this.scanner.decoderInitialized.value) {
      throw new Error('Unable to start scan; decoder not initialized');
    }

    if (this.subscribers <= 0) {
      this.scanner.startScan(tutorialText);
      this.scanner.resumeScan();
      this.stopTimeout?.unsubscribe();
      this.subscribers = 0;
    }

    this.subscribers += 1;
    return this.scanner.scanResults
      .pipe(
        filter(value => types == null || types.find(type => type === value.symbologyName) != undefined),
        finalize(() => {
          this.subscribers -= 1;
          if (this.subscribers <= 0) {
            this.scanner?.pauseScan();
            this.stopTimeout?.unsubscribe();
            let timeout = new Subject();
            this.stopTimeout = timeout
              .pipe(delay(20000)).subscribe(_ => {
                if (this.subscribers <= 0) {
                  this.scanner?.stopScan();
                }
              });
            timeout.next();
          }
        }),
        share(),
      );
  }
}

export interface ScannerProvider {
  stopScan(): void;

  startScan(tutorialText?: string): void;

  pauseScan(): void;

  resumeScan(): void;

  scanResults: Subject<ScanResult>;

  decoderInitialized: BehaviorSubject<boolean>;
}

export interface ScanResult {
  barcodeData: string;
  symbologyName: CodeType;
  barcodeCoordinates: Rectangle;
}

export interface Rectangle {
  TopRight: Point;
  TopLeft: Point;
  BottomLeft: Point;
  BottomRight: Point;
}

export interface Point {
  X: number;
  Y: number;
}

export enum CodeType {
  QR = 'QR Code',
  Code128 = 'Code128',
  EAN8 = 'EAN8',
  EAN13 = 'EAN13',
  UPCA = 'UPCA',
  DATA_MATRIX = 'DataMatrix',
}
