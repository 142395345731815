export interface FulfillmentOption {
  id: string;
  name: string;
  type: FulfillmentOptionType;
  default: boolean;
}

export enum FulfillmentOptionType {
  Local = "locally",
  Shipment = "shipment"
}
