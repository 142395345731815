import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  public active: EventEmitter<boolean> = new EventEmitter(false);
  public numInQueue: EventEmitter<number> = new EventEmitter<number>();
  private counter = 0;

  incrementQueue() {
    this.counter += 1;
    this.numInQueue.emit(this.counter);
    return this.counter;
  }

  decrementQueue() {
    this.counter -= 1;
    if (this.counter <= 0) {
      this.counter = 0;
    }
    this.numInQueue.emit(this.counter);
    return this.counter;
  }

  start() {
    this.active.emit(true);
  }

  stop() {
    this.counter = 0;
    this.active.emit(false);
    this.numInQueue.emit(0);
  }
}
