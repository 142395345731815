<div class="root">
  <div class="cart-payment">
    <div class="cart-shopping" [ngStyle]="{stroke: profile?.primaryTextColor, backgroundColor: profile?.primaryColor}" (click)="openCart()">
      <svg viewBox="0 0 100 100" class="cart-shopping-icon">
        <path class="st0" d="M33.7,34.5c0,0,6.6-2.6,6.8,6.4c0,0,0.8,15.6,5.7,15.8h16.2"/>
        <path class="st0" d="M40.5,40.8h24.9c0,0-1.5,10.1-6.1,10.3H42.2"/>
        <circle class="st0" cx="44.9" cy="62" r="2.8"/>
        <circle class="st0" cx="58.6" cy="62" r="2.8"/>
      </svg>
      <div class="product-quantity">
        <div class="product-quantity-number">{{cartCount}}</div>
      </div>
    </div>
    <div class="cart-payment-price-total">{{ 'CART.sum' | translate }} ({{cartCount}}&nbsp;{{ 'CART.itemCount' | translate }}):
      {{ cart?.sum! / 100 | currency:currencyCode:'symbol-narrow': '':cultureName}}
    </div>
  </div>

  <ng-container *ngIf="error">
    <ng-container *ngTemplateOutlet="errorTemplate"></ng-container>
  </ng-container>

  <div *ngIf="showExternalPaymentFlow" class="dropin-payment">
    <div id="dropin-container"></div>
    <iframe *ngIf="extPaymentIframeSrc"
            title="Ext payment"
            class="cart-payment-iframe"
            [height]="getClientHeight()"
            [src]="extPaymentIframeSrc | safeResource"
    ></iframe>
    <button class="cancel-button" (click)="resetForm()">
      {{ 'DIALOG.cancel' | translate }}
    </button>
  </div>

  <ng-container *ngIf="ageLimitProducts">
    <div class="age-limit">
      <button class="age-limit-button"
              [ngStyle]="{backgroundColor: profile?.primaryColor, color: profile?.primaryTextColor}"
              (click)="ageCheck()">
        {{ 'CART.age' | translate }}
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="requiresShipmentAddress && !isPaymentBusy">
    <button *ngIf="!cart?.shippingAddress"
            class="cart-details-shipping-button"
            [ngStyle]="{backgroundColor: profile?.primaryColor, color: profile?.primaryTextColor}"
            (click)="openShipmentSheet()">
      {{ 'SHIPPING.addAddress' | translate }}
    </button>
    <div *ngIf="cart?.shippingAddress" class="cart-details-address-group" (click)="openShipmentSheet()">
      <div class="cart-details-address-email">{{ cart?.shippingAddress?.emailAddress }}</div>
      <div class="cart-details-address-name">{{ cart?.shippingAddress?.fullName }}</div>
      <div>
        <span class="cart-details-address-addressOne">{{ cart?.shippingAddress?.addressLine1 }}, </span>
        <span class="cart-details-address-AddressTwo">{{ cart?.shippingAddress?.addressLine2 }}</span>
      </div>
      <div class="cart-details-pcc-group">
        <span class="cart-details-address-postalCode">{{ cart?.shippingAddress?.postalCode }} </span>
        <span class="cart-details-address-city">{{ cart?.shippingAddress?.city }}</span>
      </div>
      <div class="cart-details-address-country">{{ cart?.shippingAddress?.countryName }}</div>
      <div class="cart-details-address-change">
        <span>{{ 'SHIPPING.edit' | translate }}</span>
        <img src="/assets/images/edit.svg" alt="avatar image" height="20" width="20">
      </div>
    </div>
  </ng-container>

  <div class="cart-details_payment-methods"
       *ngIf="store != null && cart != null && offlineCart == null && ageLimitProducts==false && !showExternalPaymentFlow"
       [ngClass]="{'disabled': !isCartValid(cart)}">
    <ng-container *ngIf="!isPaymentBusy && cart.orderId && paymentMethods else loader">
      <div *ngIf="applePay != undefined && applePayLoaded"
           class="native-pay-container"
           [ngClass]="{'disabled': !isCartValid(cart)}">
        <app-apple-pay [storeHandle]="store.handle"
                       [totalSum]="cart.sum"
                       [orderId]="cart.orderId"
                       [cart]="cart"
                       [paymentMethod]="applePay"
                       (isReadyToPay)="applePayLoaded = $event"
        ></app-apple-pay>
      </div>
      <div *ngIf="googlePay != undefined && googlePayLoaded"
           class="native-pay-container"
           [ngClass]="{'disabled': !isCartValid(cart)}">
        <app-google-pay [storeHandle]="store.handle"
                        [totalSum]="cart.sum"
                        [orderId]="cart.orderId"
                        [cart]="cart"
                        [paymentMethod]="googlePay"
                        (isReadyToPay)="googlePayLoaded = $event"
        ></app-google-pay>
      </div>

      <h3 *ngIf="(applePay != undefined && applePayLoaded) || (applePay != undefined && googlePayLoaded)"
          class="payment-methods-spacer-text">
        {{ 'CART.spacerOr' | translate }}
      </h3>

      <div *ngIf="vrPay?.length"
           class="native-pay-container"
           [ngStyle]="!vrPayLoaded ? {opacity: 0.2} : null"
           [ngClass]="{'disabled': !isCartValid(cart) && !vrPayLoaded}">
        <app-vr-pay [storeHandle]="store.handle"
                    [cart]="cart"
                    [vrPaymentMethods]="vrPay!"
                    (isReadyToPay)="vrPayLoaded = $event"
        ></app-vr-pay>
        <div *ngIf="vrPayLoaded"><!--Hack in order to resize overlay card when loaded--></div>
      </div>

      <ng-container *ngFor="let method of paymentMethods">
        <div class="payment-methods"
             *ngIf="method.identifier !== applePay?.identifier && method.identifier !== googlePay?.identifier && !method.identifier.startsWith('vr-')"
             (click)="isCartValid(cart) && initializePayment(method)">
          <app-image-fading [source]="method.logoUrl"
                            [animateLoading]="false"
                            class="cart-details_payment-methods-image">
          </app-image-fading>
          <span class="cart-details_payment-image-text">{{method.name}}</span>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>


<ng-template #loader>
  <ng-lottie
    class="cart-details_payment-loading"
    height="50px"
    width="50px"
    [options]="{
        path: './assets/animations/fygiloader3-blue.json',
        renderer: 'svg',
        autoplay: true,
        loop: true
    }">
  </ng-lottie>
  <h4 class="payment-cancel-button" (click)="cancelPayment()">
    {{ 'DIALOG.cancel' | translate }}
  </h4>
</ng-template>

<ng-template #errorTemplate>
  <!-- TODO Improve / replace this once SCAN-1269 is implemented -->
  <div class="cart-details_error">
    <p>
      {{ error }}
    </p>
  </div>
</ng-template>
