import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Order} from '../../../domain/models/order/order';
import {OrderService} from '../../../domain/order.service';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {Store} from '../../../domain/models/store/store';
import {StoreService} from '../../../domain/store.service';
import {CustomerService} from '../../../domain/customer.service';
import {OrderUtils} from '../../../utils/order.utils';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.sass']
})
export class OrderDetailsComponent implements OnInit {

  order?: Order;
  store?: Store;
  isChrome = /chrome/i.test(navigator.userAgent);
  showControl = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private orderService: OrderService,
              private storeService: StoreService,
              private translateService: TranslateService,
              private customerService: CustomerService,
              private titleService: Title) {
  }

  async ngOnInit() {
    await this.storeService.preserveContextInUrl();

    const orderId = this.route.snapshot.paramMap.get('id')!;
    const customerId = await this.customerService.getCustomerId();
    this.order = await this.orderService.getOrder(customerId, orderId);
    this.store = await this.storeService.getStore(this.order.storeId);

    this.translateService.get('ORDERS.purchase').toPromise()
      .then(title => this.titleService.setTitle(`${title} | FYGI`));

    this.checkControl();
  }

  /** @deprecated See ToolbarComponent */
  async onBackClicked() {
    await this.router.navigate(['..'], {relativeTo: this.route});
  }

  checkControl() {
    const maxAge = 60 * 60 * 1000 * 6; // 6 hours

    if (!this.order || !this.store) {
      this.showControl = false;
      return;
    }

    const paidAtDate = OrderUtils.getPaidAtDate(this.order);
    const timeExpired = (paidAtDate?.getTime() ?? 0) + maxAge;

    if (paidAtDate && (timeExpired > Date.now() || OrderUtils.isPendingDelivery(this.order))) {
      this.showControl = true;
    }
  }

  async goToReceiptPage() {
    await this.router.navigate([`/store/${this.store?.handle}/receipt/${this.order?.id}`], {queryParamsHandling: 'preserve'});
  }
}
