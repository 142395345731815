import {TOrderLineRequest} from './t-order-line.request';
import {TCouponCodeRequest} from './t-coupon-code.request';
import {ShippingAddress} from '../../../domain/models/order/shipping-address';

export class CartRequest {
  constructor(
    readonly orderId: string | undefined,
    readonly storeId: string,
    readonly memberId: string | undefined,
    readonly externalId: string | undefined,
    readonly orderReference: string | undefined,
    readonly adjustmentTypeId: string | undefined,
    readonly orderLines: TOrderLineRequest[],
    readonly couponCodes: TCouponCodeRequest[],
    readonly discountCodes: Array<string>=[],
    readonly shippingAddress: ShippingAddress | undefined,
  ) {
  }
}

